import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
   Box,
   styled,
   Button,
   InputLabel,
   TextField,
   FormControl,
   MenuItem,
   FormHelperText,
   Select,
   Typography,
   InputAdornment,
   IconButton,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: #F7F7F8;
       color: #49454F;
     `
);

const UserNameAndSecurity = () => {
   const [securityQuestion, setSecurityQuestion] = useState('');

   const handleSelect = (event) => {
      setSecurityQuestion(event.target.value);
   };

   return (
      <div>
         <Formik
            initialValues={{
               username: '',
               answer: '',
            }}
            validationSchema={Yup.object().shape({
               Password: Yup.string()
                  .max(255)
                  .required('The password field is required'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               // dispatch function
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <Box mt={8}>
                     <InputLabel align="left" htmlFor="username" margin="dense">
                        Username (optional)
                     </InputLabel>
                     <TextInput
                        fullWidth
                        margin="dense"
                        id="username"
                        name="username"
                        placeholder="Enter username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.password}
                        variant="outlined"
                        sx={{
                           mb: '10px',
                        }}
                     />
                     <FormControl
                        align="left"
                        style={{ width: '100%', marginBottom: '10px' }}
                     >
                        <Typography mb={1} color="rgba(34, 51, 84, 0.7)">
                           Security question
                        </Typography>
                        <Select
                           value={securityQuestion}
                           onChange={handleSelect}
                           displayEmpty
                        >
                           <MenuItem value="">
                              <Typography>Select security question</Typography>
                           </MenuItem>
                           <MenuItem value="Question 1">Question 1</MenuItem>
                           <MenuItem value="Question 2">Question 2</MenuItem>
                           <MenuItem value="Question 3">Question 3</MenuItem>
                        </Select>
                     </FormControl>
                     <InputLabel align="left" htmlFor="answer" margin="dense">
                        Answer
                     </InputLabel>
                     <TextInput
                        fullWidth
                        margin="dense"
                        id="answer"
                        name="answer"
                        placeholder="Answer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.answer}
                        variant="outlined"
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton edge="end">
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Box>

                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '3px',
                        px: '35px',
                        fontSize: '15px',
                        width: '45%',
                        mx: 'auto',
                        mt: '70px',
                     }}
                     // onClick={handleCreateUserOpen}
                     variant="contained"
                     size="small"
                  >
                     Confirm
                  </Button>
                  <br />
               </form>
            )}
         </Formik>
      </div>
   );
};

export default UserNameAndSecurity;
