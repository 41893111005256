import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import contactIcon from '../../../assets/contactImg.svg';
import cost from '../../../assets/cost.svg';
import msgIcon from '../../../assets/message2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../utils/selectors/selectors';

const MessageSummary = ({ resetMsgSummary }) => {
   const selector = useSelector(messageSelector);
   // const dispatch = useDispatch();

   return (
      <Card sx={{ padding: '25px', minWidth: '400px' }}>
         <Typography
            sx={{
               textAlign: 'center',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               fontSize: '1.1rem',
               fontWeight: 600,
               color: '#0F0E0E',
            }}
         >
            Message Summary
         </Typography>
         <Box
            sx={{
               margin: '2rem auto',
               padding: '10px',
               borderBottom: '1px solid #A1A1A1',
               width: 'max-content',
            }}
         >
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: '600',
                  display: 'flex',
               }}
            >
               <img
                  src={contactIcon}
                  alt="contact"
                  style={{ marginRight: '15px' }}
               />
               {`DND numbers: ${
                  selector.msgCost ? selector.msgCost.dNDCount : 0
               } contacts`}
            </Typography>
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: '600',
                  display: 'flex',
                  marginTop: '10px',
               }}
            >
               <img
                  src={contactIcon}
                  alt="contact"
                  style={{ marginRight: '15px' }}
               />
               {`Non-DND numbers: ${
                  selector.msgCost ? selector.msgCost.nonDNDCount : 0
               } contacts`}
            </Typography>
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: '600',
                  display: 'flex',
                  marginTop: '10px',
               }}
            >
               <img
                  src={msgIcon}
                  alt="contact"
                  style={{ marginRight: '15px' }}
               />
               {`Message Length: ${
                  selector.msgCost ? selector.msgCost.numberOfPages : 1
               } pages`}
            </Typography>
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: '600',
                  display: 'flex',
                  marginTop: '10px',
               }}
            >
               <img src={cost} alt="contact" style={{ marginRight: '15px' }} />
               {`Cost per page: ${
                  selector.msgCost
                     ? Math.ceil(selector.msgCost.totalCost) /
                       (selector.msgCost.numberOfPages > 0
                          ? selector.msgCost.numberOfPages
                          : 1)
                     : 0
               } SMS Unit`}
            </Typography>
         </Box>
         <Box>
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  textAlign: 'center',
               }}
            >
               You will be charged
            </Typography>
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: '600',
                  fontSize: '1.1rem',
                  textAlign: 'center',
               }}
            >
               {`${selector.msgCost ? selector.msgCost.totalCost : 0} SMS Unit`}
            </Typography>
            <Typography
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  textAlign: 'center',
               }}
            >
               to send this message
            </Typography>
         </Box>
         <Box sx={{ margin: '1.5rem auto', width: '80%' }}>
            <Button
               variant="contained"
               sx={{
                  fontSize: '0.9rem',
                  width: '100%',
                  paddingTop: '5px',
                  paddingBottom: '5px',
               }}
               onClick={() => resetMsgSummary()}
            >
               Confirm
            </Button>
         </Box>
      </Card>
   );
};

export default MessageSummary;
