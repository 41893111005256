import { Box } from '@mui/material';
import React from 'react';
import grpImg from '../../../assets/group.svg';

import { FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { removeSelectedGroups } from '../../../components/app/features/message/messageSlice';

const GroupContent = ({ item }) => {
	const dispatch = useDispatch();
	// const selector = useSelector(messageSelector)

	const handleTimesClick = () => {
		dispatch(removeSelectedGroups(item.id));
	};
	return (
		<Box sx={{ position: 'relative', marginRight: '10px', margin:"5px"  }}>
			<FaTimes
				color='#374DA0'
				style={{
					position: 'absolute',
					right: '5px',
					zIndex: 7,
					bottom: '50%',
					transform: 'translateY(50%)',
					cursor: 'pointer',
				}}
				onClick={() => handleTimesClick()}
			/>
			<Box
				sx={{
					border: '2px solid #374DA0',
					padding: '5px 10px',
					paddingRight: '30px',
					borderRadius: '3px',
					fontFamily: "'Raleway', 'Montserrat', sans-serif",
					display: 'flex',
					alignItems: 'center',
					fontWeight: 600,
					cursor: 'pointer',
					width: 'max-content',
				}}>
				<img
					src={grpImg}
					style={{ marginRight: '10px', width: '20px' }}
					alt='grpIcon'
				/>
				{item.title}
			</Box>
		</Box>
	);
};

export default GroupContent;
