import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import arrowImg from '../../assets/angleRight.svg';
import arrowdownImg from '../../assets/angleDown2.svg';
// import Tag from '../../components/icon/tag';

function Accordion({ num, rules, required }) {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<Box>
			<Typography
				sx={{
					color: '#374DA0',
					fontWeight: 700,
					fontFamily: "'Raleway', 'Montserrat', sans-serif",
					fontSize: '1rem',
					marginBottom: '10px',
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				{`Question ${num}`}
				{!isOpen && (
					<img
						src={arrowImg}
						style={{
							width: '10px',
							cursor: 'pointer',
						}}
						alt='icon'
						onClick={() => handleToggle()}
					/>
				)}
				{isOpen && (
					<img
						src={arrowdownImg}
						style={{
							width: '20px',
							cursor: 'pointer',
						}}
						alt='icon'
						onClick={() => handleToggle()}
					/>
				)}
			</Typography>
			{/* <Typography
				sx={{
					fontWeight: 600,
					fontFamily: "'Raleway', 'Montserrat', sans-serif",
					fontSize: '0.9rem',
					display: 'flex',
					alignItems: 'center',
					color:
						required === 'required'
							? '#0FA958'
							: required === 'general'
							? '#34B7E5'
							: '#FFB017',
				}}>
				<div
					style={{
						width: '16px',
						height: '16px',
						marginRight: '10px',
					}}>
					<Tag
						fillColor={
							required === 'required'
								? '#0FA958'
								: required === 'general'
								? '#34B7E5'
								: '#FFB017'
						}
					/>
				</div>
				{required}
			</Typography> */}
			{isOpen && (
				<Typography
					sx={{
						borderTop: '1px solid #ECECEC',
						marginTop: '15px',
						padding: '15px 0',
						fontFamily: "'Raleway', 'Montserrat', sans-serif",
						fontSize: '1rem',
					}}>
					{rules}
				</Typography>
			)}
		</Box>
	);
}

export default Accordion;
