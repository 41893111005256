import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';

export const Container = styled(Box)(
   ({ theme }) => `
 min-height: calc(100vh - ${theme.header.height}); 
 padding-top: ${theme.header.height};
 padding-bottom: 1rem;
	width: 100%; 
 `
);

export const TextInput = styled(TextField)(
   ({ theme }) => `
 background: #inherit;
	border-radius: 25px;
 `
);
