import React from 'react';
import { Box, Typography } from '@mui/material';
import LoginTab from '../../components/loginTab/LoginTab';
import { useDispatch } from 'react-redux';
import {
   // logOut,
   resetLogout,
} from '../../components/app/features/auth/AuthSlice';

const Login = () => {
   const dispatch = useDispatch();

   React.useEffect(() => {
      dispatch(resetLogout());
   }, []);

   return (
      <div>
         <Box fontWeight={600} className="pageHeader">
            Hi, Welcome Back
         </Box>
         <Typography fontWeight={600} color="#02222d">
            Please log in into your account
         </Typography>
         <LoginTab />
      </div>
   );
};

export default Login;
