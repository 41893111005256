import React, { useEffect } from 'react';
import { Box, Card, Dialog, Typography, styled, Button } from '@mui/material';
import Header from '../../components/layouts/headers/Headers';
import Sidebar from '../../components/layouts/sidebar/Sidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import HamSidebar from '../../components/layouts/sidebar/HamSidebar';
import { logOut } from '../../components/app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../components/utils/selectors/selectors';

const DashboardPageWrapper = styled(Box)(
   ({ theme }) => `
       min-height: 100vh;
       position: relative;
       @media (max-width: 1120px) {
            margin-left: 0;
            width: 100%;
        }
     
`
);
const PaymentModal = styled(Box)(
   ({ theme }) => `
     min-height: 200px;
     width: 400px;
     position: fixed;
     bottom: 20px;
     left: 20px;
     background-color: white;
     border-radius: 8px;
     z-index: 8;
     box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3);
     -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3);
     -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3);
 
     @media (max-width: 768px) {
       width: 320px;
       left: 10px;
       bottom: 10px;
     }
 
     @media (max-width: 500px) {
       width: 280px;
       left: 5px;
       bottom: 5px;
     }
 
     @media (max-width: 300px) {
       width: 240px;
       left: 5px;
       bottom: 5px;
     }
   `
 );
 

const Dashboard = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);
   const [isOpen, setIsOpen] = React.useState(true);
   const [mobileOpen, setMobileOpen] = React.useState(false);
   const [logOff, setLogOff] = React.useState(false);
   const [popUp, setPopUp] = React.useState(false);
   // const [messageCount, setMessageCount] = React.useState(0);

   const getHamState = (state) => {
      setIsOpen(state);
   };

   const getMobileHamState = (state) => {
      setMobileOpen(state);
   };

   React.useEffect(() => {
      if (selector.logOut === true) {
         // If the user logs out, redirect to login page and clear storage
         navigate('/login');
         sessionStorage.clear();
         localStorage.clear();
      } else {
         // Check if the user has just logged in and not just refreshing the page
         const hasLoggedIn = sessionStorage.getItem('hasLoggedIn');
   
         // If the user hasn't logged in this session, show the pop-up
         if (!hasLoggedIn) {
            setPopUp(true); // Show the pop-up
   
            // Mark that the user has logged in during this session
            sessionStorage.setItem('hasLoggedIn', 'true');
         }
      }
   }, [selector.logOut === true]);
   
   
   
   

   const getLogOutState = (state) => setLogOff(state);

   // React.useEffect(() => {
   //    const timer = setTimeout(() => {
   //       setPopUp(true);
   //    }, 30000);

   //    return () => clearTimeout(timer);
   // }, []);

   // useEffect(() => {
   //    const discount = localStorage.getItem('discount');
   //    if (discount !== 'true') {
   //       setPopUp(true); // Trigger the modal if discount is not set
   //    }
   // }, []);

   // Reset discount when tab is closed
   useEffect(() => {
      const handleBeforeUnload = () => {
         localStorage.setItem('discount', 'false'); // Reset discount when tab is closed
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      // Cleanup the event listener when component unmounts
      return () => {
         window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, []);

   return (
      <Box>
         <Header
            isOpen={isOpen}
            mobileOpen={mobileOpen}
            getHamState={getHamState}
            getMobileHamState={getMobileHamState}
            getLogOutState={getLogOutState}
         />
         <Sidebar isOpen={isOpen} getLogOutState={getLogOutState} />
         <HamSidebar
            openMenu={mobileOpen}
            getHamState={getMobileHamState}
            getLogOutState={getLogOutState}
         />
         <DashboardPageWrapper
            sx={{
               marginLeft: isOpen ? '250px' : '100px',
            }}
         >
            <Outlet />
         </DashboardPageWrapper>
         {popUp && (
            <PaymentModal>
               <div style={{ position: 'relative', padding: '30px' }}>
                  <span
                     style={{
                        position: 'absolute',
                        right: '20px',
                        top: '20px',
                        fontWeight: 900,
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        localStorage.setItem('discount', 'true');
                        setPopUp(false);
                     }}
                  >
                     X
                  </span>
                  <p
                     style={{
                        fontWeight: 700,
                        textAlign: 'center',
                        marginBottom: '16px',
                        fontSize: '18px',
                     }}
                  >
                     Discount on First Topup!
                  </p>
                  <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
                     Get 30% discount on your first topup to reach more
                     customers Use promo code{' '}
                     <span
                        style={{
                           fontWeight: 900,
                           textDecoration: 'underline',
                           fontSize: '20px',
                        }}
                     >
                        PAR24
                     </span>{' '}
                     to gain access
                  </p>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        display: 'block',
                        width: '100%',
                        marginTop: '1rem',
                        fontSize: '1rem',
                        paddingY: '5px',
                     }}
                     onClick={() => {
                        localStorage.setItem('discount', 'true');
                        navigate('/top-up');
                     }}
                  >
                     Buy now
                  </Button>
               </div>
            </PaymentModal>
         )}
         <Dialog
            onClose={() => {
               setLogOff(false);
            }}
            open={logOff}
         >
            <Card sx={{ padding: '30px 50px ' }}>
               <Typography
                  sx={{
                     fontWeight: '600',
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     textAlign: 'center',
                     marginBottom: '2rem',
                  }}
               >
                  Are you sure you want to log out?
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                  }}
               >
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        cursor: 'pointer',
                        marginRight: ' 1rem',
                     }}
                     onClick={() => {
                        setLogOff(false);
                     }}
                  >
                     Cancel
                  </Typography>
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: 'red',
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        dispatch(logOut());
                     }}
                  >
                     Yes
                  </Typography>
               </Box>
            </Card>
         </Dialog>
      </Box>
   );
};

export default Dashboard;
