import React from 'react';
import { Container } from './style';
import { Box, Card, Typography } from '@mui/material';
import backImg from '../../../assets/back.svg';
import Accordion from './Accordion';

const MessageRules = () => {
   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '1rem',
                  }}
               >
                  Message Guidelines
               </Typography>
               <Typography
                  sx={{
                     width: 'max-content',
                     fontWeight: 700,
                     fontSize: '0.9rem',
                     color: '#374DA0',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     margin: '1rem auto',
                  }}
               >
                  Follow these rules to ensure delivery of your messages
               </Typography>
               <Box
                  sx={{
                     background: '#fff',
                     borderTopRightRadius: '10px',
                     borderTopLeftRadius: '10px',
                     padding: '1rem',
                     marginBottom: '1rem',
                  }}
               >
                  <Accordion
                     num={1}
                     rules={
                        'Please do note that promotional messages are STRICTLY not allowed on our DND route on any of our default sender IDs.'
                     }
                     required={'General'}
                  />
               </Box>
               {/* <Box
                  sx={{
                     background: '#fff',
                     marginY: '15px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={2}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '15px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={3}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'optional'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '15px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={4}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'general'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '15px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={5}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '15px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={6}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'general'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '15px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={7}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box> */}
               <Box
                  sx={{
                     background: '#fff',
                     borderBottomRightRadius: '10px',
                     borderBottomLeftRadius: '10px',
                     padding: '1rem',
                     marginY: '15px',
                  }}
               >
                  <Accordion
                     num={2}
                     rules={
                        'The Promotional/Open Bind Route is the generic route for promotional/ marketing messages (Bulk SMS). Messages sent via this channel only delivers to numbers not on DND.'
                     }
                     required={'General'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     borderBottomRightRadius: '10px',
                     borderBottomLeftRadius: '10px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={3}
                     rules={
                        'To avoid message truncation, please do not use the "&" character in your message content. Instead, use the word "AND" to ensure your message is delivered in full'
                     }
                     required={'General'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     borderBottomRightRadius: '10px',
                     borderBottomLeftRadius: '10px',
                     padding: '1rem',
                     marginTop:'1rem'
                  }}
               >
                  <Accordion
                     num={4}
                     rules={
                        'Please note: In compliance with NCC regulations, all promotional messages sent after 8 PM will be queued by the Telco operators for delivery from 8:00 AM the next day.'
                     }
                     required={'General'}
                  />
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default MessageRules;
