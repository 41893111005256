import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #E8E8E8;
 `
);
export const TextInput = styled(TextField)(
   ({ theme }) => ` 
 background: inherit;
 `
);
