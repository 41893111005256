import React, {useState} from 'react'
import { Box, Typography, Card, styled, CardMedia, Button, Tabs, Tab } from "@mui/material"
import TabPanel from '../../components/layouts/tabPanel/TabPanel';
import EmailForgotPassword from '../../components/layouts/forms/forgotPassword/EmailForgotPassword';
import PhoneForgotPassword from '../../components/layouts/forms/forgotPassword/PhoneNumber';


const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
    // border: 2px solid black;
    width: 100%;


        @media (max-width: ${theme.breakpoints.values.md}px) {
          .MuiTabs-scrollableX {
            overflow-x: auto !important;
          }
    
          .MuiTabs-indicator {
              box-shadow: none;
          }
        }
        `
  );

const ForgotPasswordTab = () => {
    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const [value, setValue] = useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };




  return (
    <div>
        <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        pb={1}
        m={3}
        mt={5}
        
      >
        <TabsWrapper
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Email Address" {...a11yProps(0)} />
          <Tab label="Phone Number" {...a11yProps(1)} />
       
        </TabsWrapper>  
      </Box>
      <TabPanel value={value} index={0}>
          <EmailForgotPassword />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PhoneForgotPassword />
        </TabPanel>
    </div>
  )
}

export default ForgotPasswordTab