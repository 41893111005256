import { Box, Typography } from '@mui/material';
import React from 'react';
import sixthImg from '../../../assets/sixthSlide.svg';

const SixthSlide = () => {
	return (
		<Box>
			<Box sx={{ width: '100%', height: '180px' }}>
				<img
					src={sixthImg}
					style={{ width: '100%', height: '100%' }}
					alt='icon'
				/>
			</Box>
			<Typography
				sx={{
					my: '1rem',
					textAlign: 'center',
					fontSize: '1rem',
					fontWeight: 600,
				}}>
				Example
			</Typography>
			<Typography
				sx={{
					// textAlign: 'center',
					fontFamily: "'Raleway', 'Montserrat', sans-serif",
					color: '#0F0E0E',
					fontSize: '1rem',
					mt: '1rem',
				}}>
				Meet Kunle, a business man. Kunle wants to send a page of SMS to 120 of
				his contacts. 75 of his contacts are DND numbers, while the other 45 are
				Non-DND numbers.
			</Typography>
			<Typography
				sx={{
					// textAlign: 'center',
					color: '#0F0E0E',
					fontSize: '1rem',
					fontFamily: "'Raleway', 'Montserrat', sans-serif",
				}}>
				See breakdown of how Kunle will be charged in the next card.
			</Typography>
		</Box>
	);
};

export default SixthSlide;
