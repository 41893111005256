import { styled, Box, Button } from '@mui/material';

export const MessageWrapper = styled(Box)(
	({ theme }) => `
background: #EFEEF4;  
min-height: 100vh;  
padding-top:${theme.header.height}; 
// border: 1px solid red;
`
);
