import { Box } from '@mui/material';
import React from 'react';
import left from '../../../assets/playLeft.svg';
import Right from '../../../assets/playRight.svg';

const Control = ({ nextSlide, prevSlide, currentSlide }) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<img
				src={left}
				alt='icon'
				style={{
					marginRight: '10px',
					width: '25px',
					cursor: 'pointer',
				}}
				onClick={() => prevSlide()}
			/>
			<Box sx={{ display: 'flex', columnGap: '2px' }}>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 0 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 1 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 2 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 3 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 4 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 5 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 6 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
				<Box
					sx={{
						height: '5px',
						background: currentSlide === 7 ? '#374DA0' : '#A1A1A1',
						width: '15px',
						borderRadius: '2px',
					}}></Box>
			</Box>
			<img
				src={Right}
				alt='icon'
				style={{
					marginLeft: '10px',
					width: '25px',
					cursor: 'pointer',
				}}
				onClick={() => nextSlide()}
			/>
		</Box>
	);
};

export default Control;
