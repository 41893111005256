import styled from '@emotion/styled';
import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import backImg from '../../assets/back.svg';
import cashPayment from '../../assets/cashpayment.svg';
import Index from './pricingComps/Index';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #E8E8E8;
 `
);

const PrincingInfo = () => {
   const [tab, setTab] = React.useState(false);
   const [state, setState] = React.useState(false);
   React.useEffect(() => {
      if (state === true) {
         setTab(true);
      }
      // eslint-disable-next-line
   }, [state === true]);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               padding: '20px ',
               background: '#fff',
               borderRadius: '20px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <img
               src={backImg}
               alt="back"
               style={{
                  position: 'absolute',
                  width: '40px',
                  cursor: 'pointer',
               }}
               onClick={() => window.history.back()}
            />
            <Typography
               sx={{
                  textAlign: 'center',
                  mt: 2,
                  fontSize: '1.15rem',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  color: '#0F0E0E',
                  fontWeight: 600,
               }}
            >
               Pricing Information
            </Typography>
            {!tab && (
               <>
                  <Box
                     sx={{
                        width: '80%',
                        height: '210px',
                        margin: '3rem auto 0 auto',
                        // border: '1px solid red',
                     }}
                  >
                     <img
                        src={cashPayment}
                        style={{
                           width: '100%',
                           height: '100%',
                        }}
                        alt="icon"
                     />
                  </Box>
                  <Typography
                     sx={{
                        textAlign: 'center',
                        width: '90%',
                        margin: '1rem auto',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: '1rem',
                     }}
                  >
                     Parrotica adopts a simple pricing system for Bulk SMS sent.
                     Click below to Learn more.
                  </Typography>
                  <Box
                     display={'flex'}
                     justifyContent={'center'}
                     mt={10}
                     mb={2}
                  >
                     <Button
                        variant="contained"
                        sx={{ width: '50%', fontSize: '1rem' }}
                        onClick={() => setState(true)}
                     >
                        Learn more
                     </Button>
                  </Box>
               </>
            )}
            {tab && <Index />}
         </Card>
      </Container>
   );
};

export default PrincingInfo;
