import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export default function ProfileMenu({ children, getLogOutState }) {
   const navigate = useNavigate();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [action, setAction] = React.useState('');
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = (event) => {
      setAction(event.target.innerText);
      setAnchorEl(null);
   };

   React.useEffect(() => {
      if (action === 'Profile') {
         navigate('/settings/profile');
      } else if (action === 'Settings') {
         navigate('/settings');
      } else if (action === 'Log out') {
         getLogOutState(true);
      }
   }, [action]);

   return (
      <div>
         <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
         >
            {children}
         </Button>
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            value={action}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               'aria-labelledby': 'basic-button',
            }}
            sx={{
               '& *': {
                  fontWeight: 600,
               },
            }}
         >
            <MenuItem
               value="Profile"
               onClick={(e) => {
                  handleClose(e);
                  navigate('/settings/profile');
               }}
            >
               Profile
            </MenuItem>
            <MenuItem
               onClick={(e) => {
                  handleClose(e);
                  navigate('/settings');
               }}
            >
               Settings
            </MenuItem>
            <MenuItem
               onClick={(e) => {
                  handleClose(e);
                  getLogOutState(true);
               }}
            >
               Log out
            </MenuItem>
         </Menu>
      </div>
   );
}
