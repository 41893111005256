import React, { useState } from 'react';
import {
   Typography,
   Box,
   Button,
   FormControl,
   RadioGroup,
   Dialog,
   styled,
   TextField,
} from '@mui/material';
import InternetBankingLink from '../../internetBankingLink/InternetBankingLink';
import PaystackLogo from '../../../assets/Paystack.svg';
import FlutterWaveLogo from '../../../assets/Flutterwave.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../utils/selectors/selectors';
import {
   topUpFlutter,
   topUpPayStack,
   resetPreview,
} from '../../app/features/transactions/TransactionSlice';
import { FaTimes } from 'react-icons/fa';

const DialogWrapper = styled(Dialog)(
   () => `
        .MuiDialog-paper {
          overflow: visible;
        }
  `
);
const TextInput = styled(TextField)(
   ({ theme }) => `
       background: white;
       margin-top: 20px;
     `
);

const InternetBanking = () => {
   const dispatch = useDispatch();
   const selector = useSelector(getTransactions);
   const [checked, setChecked] = React.useState('');
   const [open, setOpen] = useState(false);
   const [promoCode, setPromoCode] = useState('');
   const getChecked = (value) => setChecked(value);

   const handleClick = () => {
      if (checked === 'paystack') {
         dispatch(
            topUpPayStack({
               amount: Number(selector.amount),
               isPreview: false,
               promoCode,
            })
         );
      } else if (checked === 'flutterwave') {
         dispatch(
            topUpFlutter({
               amount: Number(selector.amount),
               isPreview: false,
               promoCode,
            })
         );
      } else if (checked === 'opay') {
         window.location.href = selector.opay;
      }
   };

   const handleReceiptPreview = async () => {
      if (checked === 'paystack') {
         dispatch(
            topUpPayStack({
               amount: Number(selector.amount),
               isPreview: true,
               promoCode,
            })
         );
      } else if (checked === 'flutterwave') {
         dispatch(
            topUpFlutter({
               amount: Number(selector.amount),
               isPreview: true,
               promoCode,
            })
         );
      } else if (checked === 'opay' && selector.opay != null) {
         window.location.href = selector.opay;
      }
   };

   React.useEffect(() => {
      if (selector.isPreview) {
         setOpen(true);
      } else {
         if (checked === 'paystack' && selector.paystack != null) {
            window.location.href = selector.paystack;
         } else if (checked === 'flutterwave' && selector.flutterwave != null) {
            window.location.href = selector.flutterwave;
         } else if (checked === 'opay' && selector.opay != null) {
            window.location.href = selector.opay;
         }
      }
   }, [selector.paystack, selector.flutterwave, selector.opay, checked]);

   const handleRadioChange = (event) => {
      setChecked(event.target.value);
   };
   const handleClose = () => {
      dispatch(resetPreview({}));
      setOpen(false);
   };
   return (
      <div>
         <Typography
            fontWeight={500}
            color="#0F0E0E"
            fontSize={14}
            width="80%"
            margin="auto"
            align="center"
            mb={2}
         >
            Use one of the payment methods below to complete your transaction
         </Typography>
         <Box>
            <Typography>Recommended</Typography>
            <FormControl sx={{ width: '100%' }}>
               <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name={'internet_banking'}
                  value={checked}
                  onChange={handleRadioChange}
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: 2,
                  }}
               >
                  {/* <InternetBankingLink
                     bankingLogo={opayLogo}
                     bankingName=""
                     radioValue="opay"
                     getChecked={getChecked}
                  /> */}
                  <InternetBankingLink
                     bankingLogo={FlutterWaveLogo}
                     bankingName="Flutterwave"
                     radioValue="flutterwave"
                     getChecked={getChecked}
                  />

                  <InternetBankingLink
                     bankingLogo={PaystackLogo}
                     bankingName="Paystack"
                     value="paystack"
                     radioValue="paystack"
                     getChecked={getChecked}
                  />

                  {/* <InternetBankingLink
							bankingLogo={QuicktellerLogo}
							bankingName='Quickteller'
							radioValue='Quickteller'
							value='Quickteller'
							// onClick={handleClick}
							getChecked={getChecked}
							// id={3}
						/>
						<InternetBankingLink
							bankingLogo={VerveLogo}
							radioValue='Verve'
							value='verve'
							// onClick={handleClick}
							getChecked={getChecked}
							// id={4}
						/> */}
               </RadioGroup>
               <TextInput
                  fullWidth
                  margin="normal"
                  placeholder="Enter promo code if you have one"
                  onChange={(e) => {
                     setPromoCode(e.target.value);
                  }}
                  type="text"
                  variant="outlined"
                  value={promoCode}
               />
            </FormControl>
         </Box>

         <Button
            sx={{
               borderRadius: '11px',
               py: '10px',
               fontSize: '15px',
               width: '50%',
               m: 'auto',
               mt: '40px',
               display: 'flex',
               mb: '20px',
            }}
            variant="contained"
            size="small"
            type="submit"
            onClick={handleReceiptPreview}
         >
            Preview Receipt
         </Button>

         <DialogWrapper
            open={open}
            maxWidth="sm"
            fullWidth
            onClose={handleClose}
         >
            <Box
               sx={{
                  mt: 2,
                  position: 'relative',
                  mx: '18px',
               }}
            >
               <Box
                  sx={{
                     height: '5px',
                     width: '100px',
                     borderRadius: '5px',
                     mx: 'auto',
                     bgcolor: '#374da0',
                  }}
               ></Box>
               <FaTimes
                  size={20}
                  style={{
                     position: 'absolute',
                     right: 0,
                     top: 0,
                     cursor: 'pointer',
                  }}
                  onClick={handleClose}
               />
            </Box>
            <Typography
               color="#0F0E0E"
               fontSize={18}
               fontWeight={600}
               align="center"
               mt={3}
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif ",
               }}
            >
               Confirmation Receipt
            </Typography>

            <Typography
               align="center"
               fontSize={16}
               color="#878787"
               mb={4}
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif ",
               }}
            >
               Please review your payment details before proceeding
            </Typography>
            <Box alignItems="center" marginLeft={8}>
               <Typography
                  color="#0F0E0E"
                  fontSize={18}
                  fontWeight={600}
                  ml={3}
                  sx={{
                     fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                  }}
               >
                  Subtotal: ₦ {selector.receipt.subtotal.toFixed(2)}
               </Typography>
               {selector.receipt.discount > 0 ? (
                  <Typography
                     color="#0F0E0E"
                     fontSize={18}
                     fontWeight={600}
                     ml={3}
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                     }}
                  >
                     Discount: - ₦ {selector.receipt.discount.toFixed(2)}
                  </Typography>
               ) : (
                  ''
               )}
               {selector.receipt.vat > 0 ? (
                  <Typography
                     color="#0F0E0E"
                     fontSize={18}
                     fontWeight={600}
                     ml={3}
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                     }}
                  >
                     VAT (7.5%): ₦ {selector.receipt.vat.toFixed(2)}
                  </Typography>
               ) : (
                  ''
               )}
               <Typography
                  color="#0F0E0E"
                  fontSize={18}
                  fontWeight={600}
                  ml={3}
                  sx={{
                     fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                  }}
               >
                  Processing Fee: ₦ {selector.receipt.transactionFee.toFixed(2)}
               </Typography>
               <Typography
                  color="#0F0E0E"
                  fontSize={18}
                  fontWeight={600}
                  ml={3}
                  sx={{
                     fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                  }}
               >
                  Total: ₦ {selector.receipt.total.toFixed(2)}
               </Typography>
            </Box>
            <Button
               sx={{
                  borderRadius: '11px',
                  py: '10px',
                  // px: '35px',
                  fontSize: '15px',
                  width: '50%',
                  mx: 'auto',
                  mt: '40px',

                  fontFamily: "'Raleway', 'Montserrat', sans-serif ",
               }}
               variant="contained"
               size="small"
               type="submit"
               onClick={handleClick}
            >
               Confirm
            </Button>
            <br />
         </DialogWrapper>
      </div>
   );
};

export default InternetBanking;
