import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Dialog,
   Checkbox,
} from '@mui/material';
import { Container, TextInput } from './style';
import backImg from '../../../assets/back.svg';
import { FaCheck, FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import {
   addMultipleUsersToGroup,
   fetchContacts,
   resetCreated,
} from '../../../components/app/features/contacts/contactSlice';
import { useParams } from 'react-router-dom';
import Success from './Success';
import InfiniteScroll from 'react-infinite-scroll-component';

const AddContactsGroup = () => {
   const params = useParams();
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [all, setAll] = React.useState([]);
   const [successful, setIsSuccessful] = React.useState(false);
   const [isAdd, setIsAdd] = React.useState(false);
   const [added, setAdded] = React.useState(false);
   const [data, setData] = React.useState([]);
   const [search, setSearch] = React.useState('');
   const [count, setCount] = React.useState(0);
   const [page, setPage] = React.useState(1);

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];
      const idArr = [];
      if (checked) {
         data.map((item) => {
            collection.push({
               fullName: item.fullName,
               number: item.number,
               id: item.id,
            });
         });
         data.map((item, id) => {
            idArr.push(item.id);
         });
      }
      setCheckedList(idArr);
      setAll(collection);
      setItemsChecked(checked);
   };

   const close = () => {
      setIsSuccessful(false);
      window.history.back();
   };

   // add multiple users
   const addUsers = () => {
      if (checkedList.length > 0) {
         dispatch(
            addMultipleUsersToGroup({
               userId: selector?.admin?.id,
               contacts: all,
               groupId: params.id,
            })
         );
         setCount(all.length);
         setTimeout(() => {
            setAdded(true);
            setIsAdd(false);
         }, 500);
      } else {
         setIsAdd(false);
      }
   };

   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
         setPage(page + 1);
      }
   };

   // get contacts
   React.useEffect(() => {
      dispatch(
         fetchContacts({
            page,
         })
      );
   }, [page]);

   React.useEffect(() => {
      if (data.length === 0 || selector.contacts.length > 0) {
         setData(selector.contacts);
      }
      if (search != '') {
         setData(
            data.filter((item) => {
               if (
                  item.fullName.toLowerCase().includes(search.toLowerCase()) ||
                  item.number.includes(search)
               ) {
                  return [item];
               }
            })
         );
      }
   }, [selector.contacts, search]);

   React.useEffect(() => {
      if (selector.created === true) {
         setIsSuccessful(true);
      } else {
         setIsSuccessful(false);
      }
   }, [selector.created]);

   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   const handleCheckboxClick = (e, item, id) => {
      const { value, checked } = e.target;
      if (checked) {
         setCheckedList([...checkedList, value * 1]);
         setAll([
            ...all,
            {
               fullName: item.fullName,
               number: item.number,
               id,
            },
         ]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
         setAll(all.filter((item) => item.id != value));
      }
   };

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <Dialog
                  onClose={() => {
                     setIsAdd(false);
                  }}
                  open={isAdd}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Add selected contacts to group?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsAdd(false);
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => {
                              addUsers();
                           }}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',

                     marginTop: '1rem',
                  }}
               >
                  Contacts
               </Typography>
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: '1.1rem',
                     color: '#0F0E0E',
                  }}
               >
                  {`${selector.totalContacts} contacts`}
               </Typography>

               <Typography
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: '(1rem, 1.5vw, 1.1rem)',

                     fontWeight: 600,
                     position: 'relative',
                     // border: '1px solid red',
                  }}
               >
                  <Checkbox
                     checked={itemsChecked}
                     onClick={selectItem}
                     {...label}
                  />{' '}
                  Select All
                  {checkedList.length > 0 && (
                     <FaCheck
                        style={{
                           position: 'absolute',
                           right: '10px',
                           cursor: 'pointer',
                        }}
                        onClick={() => setIsAdd(true)}
                     />
                  )}
               </Typography>

               <FormControl
                  sx={{
                     width: '100%',
                     position: 'relative',
                     marginTop: '1rem',
                  }}
               >
                  <FaSearch
                     color="#374DA0"
                     style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '20px',
                        transform: 'translateY(-50%)',
                        fontSize: '0.9rem',
                     }}
                  />
                  <TextInput
                     fullWidth
                     placeholder="search contacts"
                     InputProps={{
                        style: {
                           color: '#374DA0',
                           borderRadius: '25px',
                           paddingLeft: '35px',

                           fontSize: '1rem',
                        },
                     }}
                     onChange={(event) => handleSearch(event)}
                  />
               </FormControl>

               <Box
                  sx={{
                     marginTop: '2rem',
                     height: '400px',
                     overflowY: 'auto',
                  }}
                  id="scrollableDiv"
               >
                  <InfiniteScroll
                     dataLength={selector.contacts.length}
                     next={loadmore}
                     hasMore={selector.hasMore}
                     loader={
                        <h4 style={{ textAlign: 'center' }}>Loading...</h4>
                     }
                     scrollableTarget="scrollableDiv"
                     endMessage={
                        <p style={{ textAlign: 'center' }}>
                           Yay! You have seen it all
                        </p>
                     }
                  >
                     {data.length > 0 ? (
                        [...data]
                           .sort((a, b) => a.fullName.localeCompare(b.fullName))
                           .map((item, index) => (
                              <Card
                                 sx={{
                                    display: 'flex',
                                    paddingY: '10px',
                                    paddingLeft: '15px',
                                    marginBottom: '10px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                       boxShadow:
                                          '0px 0px 1px 0px rgba(0,0,0,0.75)',
                                    },
                                 }}
                                 key={index}
                              >
                                 <Checkbox
                                    value={item.id}
                                    checked={checkedList.includes(item.id)}
                                    onClick={(e) =>
                                       handleCheckboxClick(e, item, item.id)
                                    }
                                    {...label}
                                 />
                                 <Box sx={{ paddingLeft: '10px' }}>
                                    <Typography
                                       sx={{
                                          fontSize: '1rem',
                                          fontWeight: 600,
                                       }}
                                    >
                                       {item.fullName ? item.fullName : ''}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontSize: '1rem',
                                          fontWeight: 600,
                                       }}
                                    >
                                       {item.number ? item.number : ''}
                                    </Typography>
                                 </Box>
                              </Card>
                           ))
                     ) : (
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontSize: '1.2rem',
                              fontWeight: '700',

                              marginTop: '2rem',
                           }}
                        >
                           No Contacts
                        </Typography>
                     )}
                  </InfiniteScroll>
               </Box>
            </Box>
            <Dialog
               onClose={() => {
                  setIsSuccessful(false);
                  dispatch(resetCreated());
               }}
               open={successful}
            >
               <Success
                  close={close}
                  text="Contacts has been successfully added"
                  count={count}
               />
            </Dialog>
         </Card>
      </Container>
   );
};

export default AddContactsGroup;
