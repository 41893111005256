import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import contactIcon from '../../../assets/contactImg.svg';
import cost from '../../../assets/cost.svg';
import angle from '../../../assets/angleDown.svg';
// import info from '../../../assets/info.svg';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { contactPricingInfo } from '../../../components/app/features/contacts/contactSlice';

const ContactPricingInfo = () => {
   const [open, setOpen] = React.useState(false);
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   React.useEffect(() => {
      dispatch(contactPricingInfo({ userId: selector?.admin?.id }));
   }, []);

   return (
      <Card sx={{ width: '500px', overflow: 'auto', padding: '20px 30px' }}>
         <Typography
            sx={{
               textAlign: 'center',
               fontWeight: '600',
               fontSize: '1.1rem',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               color: '#374DA0',
            }}
         >
            Contact Info
         </Typography>
         <Box
            sx={{
               margin: '0 auto 1rem auto',
               // border: '1px solid red',
               width: '350px',
            }}
         >
            <Box
               sx={{
                  paddingY: '1rem',
                  borderBottom: '1px solid #A1A1A1',
               }}
            >
               <Box sx={{ display: 'flex' }}>
                  <img
                     src={contactIcon}
                     alt="contact"
                     style={{ marginRight: '15px' }}
                  />
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '600',
                     }}
                  >
                     {`DND numbers: ${
                        selector?.pricing ? selector?.pricing.dNDCount : 0
                     } contacts`}
                  </Typography>
               </Box>
               <Box sx={{ display: 'flex', marginTop: '15px' }}>
                  <img
                     src={cost}
                     alt="contact"
                     style={{ marginRight: '15px' }}
                  />
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '600',
                     }}
                  >
                     {`Cost of SMS to DND number: ${
                        selector?.pricing ? selector?.pricing.costForDND : 0
                     } SMS Unit/page`}
                  </Typography>
               </Box>
            </Box>
            <Box sx={{ paddingY: '1rem' }}>
               <Box sx={{ display: 'flex' }}>
                  <img
                     src={contactIcon}
                     alt="contact"
                     style={{ marginRight: '15px' }}
                  />
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '600',
                     }}
                  >
                     {`	Non-DND numbers: ${
                        selector?.pricing ? selector?.pricing.nonDNDCount : 0
                     } contacts`}
                  </Typography>
               </Box>
               <Box sx={{ display: 'flex', marginTop: '15px' }}>
                  <img
                     src={cost}
                     alt="contact"
                     style={{ marginRight: '15px' }}
                  />
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '600',
                     }}
                  >
                     {`	Cost of SMS to None-DND number: ${
                        selector?.pricing ? selector?.pricing.costForNonDND : 0
                     } SMS Unit/page`}
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Typography
            sx={{
               textAlign: 'center',
               fontWeight: '700',
               fontSize: '1rem',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               color: '#374DA0',
               cursor: 'pointer',
            }}
            onClick={() => setOpen(!open)}
         >
            {`Cost of SMS to ${
               selector?.pricing.dNDCount + selector?.pricing.nonDNDCount
            } contacts: ${selector?.pricing.totalCost} units/page`}
            <img
               src={angle}
               alt="angle"
               style={{
                  marginLeft: '10px',
                  transform: !open ? 'rotate(-90deg)' : 'rotate(0)',
               }}
            />
         </Typography>
         {open && (
            <Box
               sx={{
                  width: '100%',
                  padding: '15px ',
                  background: '#F7F7F8',
                  marginTop: '1rem',
                  borderRadius: '15px',
               }}
            >
               <Typography
                  sx={{
                     textAlign: 'center',
                     color: '#0F0E0E',
                     fontSize: '1.1rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     marginTop: '10px',
                     fontWeight: 700,
                  }}
               >
                  Pricing Info
               </Typography>
               <Box
                  sx={{
                     width: '400px',
                     margin: '0.5rem auto 0 auto',
                     overflow: 'auto',
                  }}
               >
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: '1rem',
                        fontWeight: '600',
                     }}
                  >
                     Sample cost for 1 page of SMS:
                  </Typography>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginY: '1rem',
                     }}
                  >
                     <Box sx={{ width: '25%' }}>
                        <Typography
                           sx={{
                              color: '#374DA0',
                              fontSize: '1rem',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              // marginTop: '10px',
                              fontWeight: 700,
                           }}
                        >
                           Contacts
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginY: '5px',
                           }}
                        >
                           DND
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                           }}
                        >
                           Non-DND
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginTop: '10px',
                           }}
                        >
                           Total:
                        </Typography>
                     </Box>
                     <Box sx={{ width: '25%', textAlign: 'center' }}>
                        <Typography
                           sx={{
                              color: '#374DA0',
                              fontSize: '1rem',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              // marginTop: '10px',
                              fontWeight: 700,
                           }}
                        >
                           Count
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginY: '5px',
                           }}
                        >
                           {selector?.pricing ? selector?.pricing.dNDCount : 0}
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                           }}
                        >
                           {selector?.pricing
                              ? selector?.pricing.nonDNDCount
                              : 0}
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginTop: '10px',
                           }}
                        >
                           {selector?.pricing.dNDCount +
                              selector?.pricing.nonDNDCount}
                        </Typography>
                     </Box>
                     <Box sx={{ width: '25%', textAlign: 'center' }}>
                        <Typography
                           sx={{
                              color: '#374DA0',
                              fontSize: '1rem',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              // marginTop: '10px',
                              fontWeight: 700,
                           }}
                        >
                           Price
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginY: '5px',
                           }}
                        >
                           {selector?.pricing.dNDCount
                              ? `${
                                   selector?.pricing.costForDND /
                                   selector?.pricing.dNDCount
                                } SMS Unit`
                              : '0 SMS Unit'}
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                           }}
                        >
                           {`${
                              selector?.pricing.costForNonDND /
                              selector?.pricing.nonDNDCount
                           } SMS Unit`}
                        </Typography>
                     </Box>
                     <Box sx={{ width: '25%', textAlign: 'right' }}>
                        <Typography
                           sx={{
                              color: '#374DA0',
                              fontSize: '1rem',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              // marginTop: '10px',
                              fontWeight: 700,
                           }}
                        >
                           Charge
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginY: '5px',
                           }}
                        >
                           {`${selector?.pricing.costForDND} SMS Unit`}
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                           }}
                        >
                           {`${selector?.pricing.costForNonDND} SMS Unit`}
                        </Typography>
                        <Typography
                           sx={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.85rem',
                              fontWeight: '600',
                              marginTop: '10px',
                           }}
                        >
                           {`${
                              selector?.pricing.costForNonDND +
                              selector?.pricing.costForDND
                           } SMS Unit`}
                        </Typography>
                     </Box>
                  </Box>
               </Box>
            </Box>
         )}
         {/* <Typography
            sx={{
               display: 'flex',
               alignItems: 'center',
               marginY: '1rem',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               fontSize: '0.9rem',
               fontWeight: '600',
            }}
         >
            <img src={info} alt="info" style={{ marginRight: '15px' }} />
            SMS Unit - Standard Bulk Sms Unit
         </Typography> */}
      </Card>
   );
};

export default ContactPricingInfo;
