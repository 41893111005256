import React from 'react';
import {
   styled,
   Box,
   Card,
   Typography,
   TextField,
   InputAdornment,
   IconButton,
   Button,
   Dialog,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import backImg from '../../assets/back.svg';
import contactImg from '../../assets/contactImg.svg';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../components/utils/selectors/selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lock from '../../assets/lock.svg';
import {
   editUserProfile,
   UploadAvatar,
   resetCreated,
} from '../../components/app/features/auth/AuthSlice';
import { userLSAuth } from '../../components/app/features/env';
import Success from './Success';
import { FaCamera } from 'react-icons/fa';
import { toast } from 'react-toastify';

export const Wrapper = styled(Box)(
   ({ theme }) => `
background: #EFEEF4;  
min-height: 100vh;  
padding-top:${theme.header.height}; 
`
);
export const Container = styled(Box)(
   ({ theme }) => `
 padding:  2rem 0; 
 min-height: 80vh; 
 background: #E8E8E8;
 `
);

const TextInput = styled(TextField)(
   ({ theme }) => `
      //  background: white;
       color: #49454F;
     `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 60%;
text-align: center;
font-size: 0.9rem;
border-radius:10px;
margin: 1rem auto;

&:hover {background: #374DA0;}
`
);

const ProfileEdit = () => {
   const [showPassword, setShowPassword] = React.useState(false);
   const [open, setOpen] = React.useState(false);
   const [img, setImg] = React.useState('');
   const [editName, setEditName] = React.useState(false);
   const [editPassword, setEditPassword] = React.useState(false);
   const selector = useSelector(getAuthSelector);
   const dispatch = useDispatch();

   const handleClickShowPassword = () => setShowPassword((show) => !show);
   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const handleImageChange = (event) => {
      const formData = new FormData();
      const selectedFile = event.target.files[0];
      formData.append('photo', selectedFile);
      if (selectedFile) {
         if (selectedFile.size >= 4 * 1024 * 1024) {
            toast.error(
               'File size exceeds the 5MB limit. Please choose a smaller file.'
            );
            // Optionally, reset the file input:
            event.target.value = null;
         } else {
            dispatch(UploadAvatar({ formData }));
         }
      }
   };

   React.useEffect(() => {
      setImg(userLSAuth()?.displayPicture);
   }, [
      selector.avatarUploadeded === 'success' ||
         selector.avatarUploadeded === 'failed',
   ]);

   React.useEffect(() => {
      if (selector.created === true) {
         setOpen(true);
      } else {
         setOpen(false);
      }
   }, [selector.created === true]);

   const username = userLSAuth()?.fullName.split(' ');

   return (
      <Wrapper>
         <Container>
            <Card
               sx={{
                  // border: '1px solid red',
                  width: '90%',
                  maxWidth: '615px',
                  minHeight: '80vh',
                  margin: '0 auto',
                  position: 'relative',
                  boxShadow:
                     '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
                  padding: '25px ',
                  background: '#E8E8E8',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     textAlign: 'center',
                     fontWeight: 600,
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontSize: '1.2rem',
                     mb: 2,
                  }}
               >
                  Edit Profile
               </Typography>
               <Box
                  sx={{
                     width: '80px',
                     height: '80px',
                     borderRadius: '50%',
                     background: '#fff',
                     margin: '0 auto',
                     position: 'relative',
                  }}
               >
                  <img
                     style={{
                        width: '100%',
                        height: '100%',
                        display: 'block',
                        borderRadius: '50%',
                     }}
                     src={img}
                     alt="icon"
                  />
                  <label htmlFor="fileInput">
                     <input
                        type="file"
                        id="fileInput"
                        onChange={handleImageChange}
                        // onClick={chanagePageLayout}
                        accept=".jpg, .jpeg, .png"
                        style={{ display: 'none' }}
                     />
                     <FaCamera
                        style={{
                           position: 'absolute',
                           fontSize: '1.7rem',
                           right: '0',
                           bottom: '0',
                           cursor: 'pointer',
                           color: '#374da0',
                        }}
                     />
                  </label>
               </Box>
               <Typography sx={{ textAlign: 'center' }}>
                  Choose image to upload (PNG, JPG, JPEG), must be below 5mb
               </Typography>
               <Box>
                  <Formik
                     enableReinitialize
                     initialValues={{
                        firstName: username ? username[0] : '',
                        lastName: username ? username[1] : '',
                        password: '',
                        confirmPassword: '',
                     }}
                     validationSchema={Yup.object().shape({
                        firstName: Yup.string(),
                        lastName: Yup.string(),
                        password: Yup.string()
                           // .required('Please enter your password')
                           .min(6, 'Password must be at least 6 characters'),
                        confirmPassword: Yup.string()
                           .label('confirm password')
                           // .required('Please confirm your password')
                           .oneOf(
                              [Yup.ref('password'), null],
                              'Passwords must match'
                           ),
                     })}
                     onSubmit={async (values, { setSubmitting }) => {
                        dispatch(editUserProfile(values));
                        // setTimeout(() => {
                        // 	setSubmitting(false);
                        // }, 400);
                     }}
                  >
                     {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                     }) => (
                        <form
                           noValidate
                           onSubmit={handleSubmit}
                           style={{
                              marginTop: '2rem',
                           }}
                        >
                           <Box
                              sx={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                                 marginBottom: '1rem',
                              }}
                           >
                              <Typography
                                 sx={{
                                    // border: '1px solid red',
                                    padding: '10px',
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '15px',
                                    background: 'rgba(122, 137, 194, 0.5)',
                                    color: '#374da0',
                                    cursor: 'pointer',
                                 }}
                                 onClick={() => setEditName(true)}
                              >
                                 <EditIcon
                                    sx={{
                                       marginRight: '5px',
                                       fontSize: '16px',
                                    }}
                                 />{' '}
                                 Edit Name
                              </Typography>
                           </Box>
                           <Box
                              sx={{
                                 marginBottom: '2rem',
                                 display: 'flex',
                                 columnGap: '2%',
                                 '@media(max-width: 520px)': {
                                    flexDirection: 'column',
                                    rowGap: '15px',
                                 },
                              }}
                           >
                              <Box
                                 sx={{
                                    // border: '1px solid red',
                                    width: '49%',
                                    '@media(max-width: 520px)': {
                                       width: '100%',
                                    },
                                 }}
                              >
                                 <Typography
                                    sx={{
                                       fontWeight: 600,
                                       fontSize: '0.9rem',
                                       // marginBottom: '5px',
                                    }}
                                 >
                                    First name
                                 </Typography>
                                 <TextInput
                                    // error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    margin="normal"
                                    name="firstName"
                                    placeholder="Enter your name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.firstName}
                                    variant="outlined"
                                    disabled={!editName}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <img
                                                src={contactImg}
                                                alt="contact"
                                             />
                                          </InputAdornment>
                                       ),
                                       endAdornment: (
                                          <InputAdornment position="end">
                                             <IconButton
                                                edge="end"
                                                onClick={() => {
                                                   setFieldValue(
                                                      'firstName',
                                                      ''
                                                   );
                                                }}
                                             >
                                                <HighlightOffIcon />
                                             </IconButton>
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                                 <span
                                    style={{
                                       color: 'red',
                                    }}
                                 >
                                    {errors.firstName && touched.firstName}
                                 </span>
                              </Box>
                              <Box
                                 sx={{
                                    width: '49%',
                                    '@media(max-width: 520px)': {
                                       width: '100%',
                                    },
                                 }}
                              >
                                 <Typography
                                    sx={{
                                       lineHeight: 1,
                                       fontWeight: 600,
                                       marginBottom: '5px',
                                       fontSize: '0.9rem',
                                    }}
                                 >
                                    Last name
                                 </Typography>
                                 <TextInput
                                    // error={Boolean(touched.name && errors.name)}
                                    disabled={!editName}
                                    fullWidth
                                    margin="normal"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.lastName}
                                    variant="outlined"
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <img
                                                src={contactImg}
                                                alt="contact"
                                             />
                                          </InputAdornment>
                                       ),
                                       endAdornment: (
                                          <InputAdornment position="end">
                                             <IconButton
                                                edge="end"
                                                onClick={() => {
                                                   setFieldValue(
                                                      'lastName',
                                                      ''
                                                   );
                                                }}
                                             >
                                                <HighlightOffIcon />
                                             </IconButton>
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                                 <span
                                    style={{
                                       color: 'red',
                                    }}
                                 >
                                    {errors.lastName && touched.lastName}
                                 </span>
                              </Box>
                           </Box>
                           <Box
                              sx={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                                 margin: '1rem 0',
                              }}
                           >
                              <Typography
                                 sx={{
                                    // border: '1px solid red',
                                    padding: '10px',
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '15px',
                                    background: 'rgba(122, 137, 194, 0.5)',
                                    color: '#374da0',
                                    cursor: 'pointer',
                                 }}
                                 onClick={() => setEditPassword(true)}
                              >
                                 <EditIcon
                                    sx={{
                                       marginRight: '5px',
                                       fontSize: '16px',
                                    }}
                                 />{' '}
                                 Change Password
                              </Typography>
                           </Box>
                           <Typography
                              sx={{
                                 lineHeight: 1,
                                 fontWeight: 600,
                                 marginBottom: '5px',
                                 fontSize: '0.9rem',
                                 mt: 2,
                              }}
                           >
                              Password
                           </Typography>
                           <TextInput
                              disabled={!editPassword}
                              fullWidth
                              margin="normal"
                              name="password"
                              placeholder="Enter your password"
                              error={Boolean(
                                 touched.password && errors.password
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type={showPassword ? 'text' : 'password'}
                              value={values.password}
                              variant="outlined"
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <img src={lock} alt="password" />
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                       >
                                          {showPassword ? (
                                             <VisibilityOff />
                                          ) : (
                                             <Visibility />
                                          )}
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                              }}
                           />
                           <span
                              style={{
                                 color: 'red',
                              }}
                           >
                              {errors.password &&
                                 touched.password &&
                                 errors.password}
                           </span>
                           <Typography
                              sx={{
                                 lineHeight: 1,
                                 fontWeight: 600,
                                 marginBottom: '5px',
                                 fontSize: '0.9rem',
                                 mt: 2,
                              }}
                           >
                              Confirm Password
                           </Typography>
                           <TextInput
                              disabled={!editPassword}
                              fullWidth
                              margin="normal"
                              name="confirmPassword"
                              placeholder="Enter your password"
                              error={Boolean(
                                 touched.confirmPassword &&
                                    errors.confirmPassword
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type={showPassword ? 'text' : 'password'}
                              value={values.confirmPassword}
                              variant="outlined"
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <img src={lock} alt="password" />
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                       >
                                          {showPassword ? (
                                             <VisibilityOff />
                                          ) : (
                                             <Visibility />
                                          )}
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                              }}
                           />
                           <span
                              style={{
                                 color: 'red',
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                              }}
                           >
                              {errors.confirmPassword &&
                                 touched.confirmPassword &&
                                 errors.confirmPassword}
                           </span>
                           <Box display={'flex'} sx={{ marginTop: '5rem' }}>
                              <FlatButton
                                 // disabled={
                                 //    !values.firstName ||
                                 //    !values.password ||
                                 //    !values.confirmPassword ||
                                 //    !values.lastName ||
                                 //    Boolean(
                                 //       errors.password &&
                                 //          touched.password &&
                                 //          errors.password
                                 //    ) ||
                                 //    Boolean(
                                 //       errors.firstName && touched.firstName
                                 //    )
                                 // }
                                 type="submit"
                              >
                                 Save
                              </FlatButton>
                           </Box>
                        </form>
                     )}
                  </Formik>
               </Box>
               <Dialog
                  onClose={() => {
                     dispatch(resetCreated());
                     setOpen(false);
                     window.history.back();
                  }}
                  open={open}
               >
                  <Success />
               </Dialog>
            </Card>
         </Container>
      </Wrapper>
   );
};

export default ProfileEdit;
