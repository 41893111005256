import React from 'react';
import {
   Box,
   Typography,
   Card,
   Button,
   TextField,
   InputAdornment,
   IconButton,
   Dialog,
   TextareaAutosize,
} from '@mui/material';
import * as Yup from 'yup';
import backImg from '../../../assets/back.svg';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Success from './Success';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { createGroup } from '../../../components/app/features/contacts/contactSlice';

const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background:  #EFEEF4;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 50%;
font-size: clamp(0.9rem, 1.5vw, 1rem);
border-radius:5px;
margin: 1rem auto;
padding:'7px 0';
&:hover {
   background-color: #374DA0;
}
@media  (max-width:450px){
 width: 80%;
}
`
);

const TextInput = styled(TextField)(
   ({ theme }) => `
       margin-top: 5px;       
       background: white;
       color: #49454F;
		font-size:clamp(0.9rem, 1.5vw, 1rem);
     `
);
const Textarea = styled(TextareaAutosize)(
   ({ theme }) => `
	width: 100%;
   padding: 10px;
	border: 1px solid #374DA0;
	border-radius:5px;
	font-size:1rem;
   background: white;
   color: #49454F;
	&:focus {
      outline: none;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    },
     `
);

const AddGroup = () => {
   const [isOpen, setIsOpen] = React.useState(false);
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();

   React.useEffect(() => {
      if (selector.created === true) setIsOpen(true);
   }, []);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     // margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     margin: '2rem auto',
                  }}
               >
                  Create New Group
               </Typography>

               <Formik
                  initialValues={{
                     name: '',
                     desc: '',
                  }}
                  validationSchema={Yup.object().shape({
                     name: Yup.string().required('The field is required'),
                     desc: Yup.string().required('The field is required'),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                     dispatch(
                        createGroup({ ...values, userId: selector?.admin?.id })
                     );
                     setTimeout(() => {
                        setIsOpen(true);
                        setSubmitting(false);
                     }, 400);
                  }}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form noValidate onSubmit={handleSubmit}>
                        <label
                           htmlFor="group_title"
                           style={{
                              fontWeight: '600',
                              display: 'block',
                           }}
                        >
                           Title
                        </label>
                        <TextInput
                           error={Boolean(touched.name && errors.name)}
                           fullWidth
                           margin="normal"
                           name="name"
                           placeholder="Enter group name"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="text"
                           value={values.name}
                           variant="outlined"
                           id="group_title"
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    {values.name !== '' && (
                                       <IconButton
                                          edge="end"
                                          onClick={() => {
                                             setFieldValue('name', '');
                                          }}
                                       >
                                          <HighlightOffIcon />
                                       </IconButton>
                                    )}
                                 </InputAdornment>
                              ),
                           }}
                        />
                        <span style={{ color: 'red' }}>
                           {errors.name && touched.name && errors.name}
                        </span>
                        <label
                           htmlFor="group_desc"
                           style={{
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontWeight: '600',
                              display: 'block',
                              margin: '2rem 0 0.5rem 0',
                           }}
                        >
                           Description
                        </label>
                        <Box sx={{ position: 'relative' }}>
                           <Textarea
                              margin="normal"
                              name="desc"
                              aria-label="about"
                              minRows={1}
                              placeholder="Enter group description"
                              onChange={handleChange}
                              value={values.desc}
                              variant="outlined"
                              id="group_desc"
                           />
                           {values.desc !== '' && (
                              <IconButton
                                 style={{ position: 'absolute', right: '10px' }}
                                 onClick={() => {
                                    setFieldValue('desc', '');
                                 }}
                              >
                                 <HighlightOffIcon />
                              </IconButton>
                           )}
                        </Box>

                        <Box display={'flex'} sx={{ marginTop: '9rem' }}>
                           <FlatButton
                              disabled={
                                 !values.name ||
                                 !values.desc ||
                                 Boolean(
                                    errors.desc && touched.desc && errors.desc
                                 ) ||
                                 Boolean(
                                    errors.name && touched.name && errors.name
                                 )
                                 // ||	selector.loading === 'pending'
                              }
                              type="submit"
                           >
                              Create Group
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
               <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
                  <Success />
               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default AddGroup;
