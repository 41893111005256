import React, { useState, useEffect, forwardRef } from 'react';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import { getAuthSelector } from '../../components/utils/selectors/selectors';
import { createPassword } from '../../components/app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
   Box,
   Button,
   TextField,
   Typography,
   CircularProgress,
   IconButton,
   InputAdornment,
   styled,
   Dialog,
   Slide,
} from '@mui/material';
import { Formik } from 'formik';
import { resetLoading } from '../../components/app/features/auth/AuthSlice';
import VerifyBadge from '../../assets/VerificationBadge.svg';

const TextInput = styled(TextField)(
   ({ theme }) => `
       color: #49454F;
       &.placeholer{
        color: red;
       }
     `
);

const CancelButton = styled(Button)(
   ({ theme }) => `
    border-radius: 10px;
    padding: 3px 16px;
    font-size: 0.8125rem;
    background: transparent;
    border: 1px solid #374DA0;
    color: #374DA0;
    width: 45%;
    margin: 0 auto;
    margin-top: 20px;
  
    &:hover {
    background: #F7F7F8;
    }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
   () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const CreatePassword = () => {
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);
   const navigate = useNavigate();
   const location = useLocation();
   const [showPassword, setShowPassword] = React.useState(false);
   const [userId, setUserId] = useState('');
   const [accessToken, setAccessToken] = useState('');

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   useEffect(() => {
      dispatch(resetLoading());
      let page = window.location.search;
      if (page) {
         // Extract  the UserID 👉️
         const params = new URLSearchParams(location.search);
         const userParams = params.get('userId');
         const userId = userParams.split(',')[0];
         const access = userParams.split('=')[1];
         setUserId(userId);
         setAccessToken(access);
      } else {
         // handle the case where the page doesn't contain query parameters
         const userId = selector?.admin?.id;
         setUserId(userId);
         navigate('/reset-password');
      }
   }, []);

   const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

   const closeConfirmDelete = () => {
      setOpenConfirmDelete(false);
   };

   // Sub

   return (
      <div>
         <Typography
            sx={{
               fontSize: 'clamp(1.5rem, 2vw, 3rem)',
               fontWeight: 600,
               my: 2,
            }}
         >
            Create New Password
         </Typography>
         <Typography fontWeight={400} color="#02222d">
            New password must be different from the old one and must be at least
            8 characters long.
         </Typography>
         <Formik
            // Validate form
            initialValues={{
               password: '',
               confirmPassword: '',
            }}
            validationSchema={Yup.object().shape({
               password: Yup.string().required('Please enter your password'),
               confirmPassword: Yup.string()
                  .label('confirm password')
                  .required('Please confirm your password')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               // dispatch function
               dispatch(
                  createPassword({
                     password: values.password,
                     confirmPassword: values.confirmPassword,
                     userId: userId,
                     accessToken: accessToken,
                  })
               );
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <Box mt={5}>
                     <label
                        htmlFor="password"
                        style={{
                           textAlign: 'left',
                           display: 'block',
                           marginBottom: '5px',
                        }}
                     >
                        Password
                     </label>
                     <TextInput
                        fullWidth
                        margin="normal"
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                        name="password"
                        id="password"
                        placeholder="Enter password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <LockIcon />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                 >
                                    {showPassword ? (
                                       <VisibilityOff />
                                    ) : (
                                       <Visibility />
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />

                     <label
                        htmlFor="confirmpassword"
                        style={{
                           textAlign: 'left',
                           display: 'block',
                           marginTop: '1rem',
                           marginBottom: '5px',
                        }}
                     >
                        Confirm Password
                     </label>
                     <TextInput
                        fullWidth
                        margin="normal"
                        id="confirmpassword"
                        helperText={
                           touched.confirmPassword && errors.confirmPassword
                        }
                        error={Boolean(
                           touched.confirmPassword && errors.confirmPassword
                        )}
                        name="confirmPassword"
                        placeholder="Confirm password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <LockIcon />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                 >
                                    {showPassword ? (
                                       <VisibilityOff />
                                    ) : (
                                       <Visibility />
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Box>

                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '7px',
                        px: '35px',
                        fontSize: '15px',
                        width: '45%',
                        mx: 'auto',
                        mt: '60px',
                     }}
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={!values.password || !values.confirmPassword}
                     variant="contained"
                     size="small"
                     type="submit"
                  >
                     Confirm
                  </Button>
               </form>
            )}
         </Formik>
         <Button
            variant="outlined"
            size="small"
            sx={{
               borderRadius: '11px',
               py: '7px',
               px: '35px',
               fontSize: '15px',
               width: '45%',
               mx: 'auto',
               mt: '1rem',
            }}
            onClick={() => navigate('/login')}
         >
            Cancel
         </Button>
         <DialogWrapper
            open={selector.loading === 'success'}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            onClose={closeConfirmDelete}
         >
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
               p={5}
            >
               <Box
                  width={130}
                  height={130}
                  borderRadius="50%"
                  className="imageWrapper"
               >
                  <img
                     src={VerifyBadge}
                     alt=""
                     width="100%"
                     height="100%"
                     style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                     }}
                  />
               </Box>
               <Box fontWeight={600} mt={3} className="pageHeader">
                  New password saved
               </Box>
               <Typography
                  fontSize={15}
                  mb={3}
                  fontWeight={600}
                  color="#02222d"
               >
                  Your password was reset successfully
               </Typography>
               <Button
                  sx={{
                     borderRadius: '11px',
                     py: '7px',
                     px: '35px',
                     fontSize: '15px',
                     width: '55%',
                     mx: 'auto',
                     mt: '60px',
                     mb: '30px',
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => navigate('/login')}
               >
                  Login
               </Button>
            </Box>
         </DialogWrapper>
      </div>
   );
};

export default CreatePassword;
