import React from 'react';
import {
   Box,
   Typography,
   Card,
   Button,
   TextField,
   InputAdornment,
   IconButton,
   Tooltip,
} from '@mui/material';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import {
   editGroup,
   fetchSingleGroup,
} from '../../../components/app/features/contacts/contactSlice';

import CancelIcon from '@mui/icons-material/Cancel';

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 50%;
text-align: center;
font-size: 1rem;
border-radius:5px;
margin: 1rem auto;
&:hover {background: #374DA0;}
padding: 7px 0;
cursor:pointer;
`
);

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: white;
       color: #49454F;
       font-size: clamp(0.9rem, 1.5vw, 1rem);

							
`
);

const EditGroup = ({ groupId, closeEditDialog, resetAction }) => {
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);

   React.useEffect(() => {
      dispatch(
         fetchSingleGroup({
            userId: selector.admin?.id,
            groupId: groupId,
         })
      );
   }, [groupId]);

   return (
      <>
         <Card
            sx={{
               width: '500px',
               margin: '0 auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <Tooltip title="Close">
                  <IconButton
                     sx={{
                        position: 'absolute',
                        top: 0,
                        cursor: 'pointer',
                        right: 0,
                     }}
                     onClick={() => {
                        closeEditDialog();
                     }}
                  >
                     <CancelIcon sx={{ fontSize: '2rem' }} />
                  </IconButton>
               </Tooltip>

               <Typography
                  sx={{
                     width: 'max-content',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     margin: '2rem auto',
                  }}
               >
                  Edit Group
               </Typography>

               <Formik
                  enableReinitialize={true}
                  initialValues={{
                     name: selector.group ? selector.group.title : '',
                     desc:
                        selector.loading === 'success'
                           ? selector.group.description
                           : '',
                     userId: selector?.admin.id || '',
                     groupId: groupId || '',
                  }}
                  validationSchema={Yup.object().shape({
                     name: Yup.string().required('The field is required'),
                     desc: Yup.string().required(' group description'),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                     dispatch(editGroup(values));
                     resetAction();
                     setTimeout(() => {
                        setSubmitting(false);
                     }, 400);
                  }}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{ mb: 2 }}>
                           <label
                              htmlFor="groupName"
                              style={{
                                 display: 'block',
                                 marginBottom: '5px',
                                 fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              }}
                           >
                              Title
                           </label>
                           <TextInput
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              margin="normal"
                              name="name"
                              id="groupName"
                              placeholder="Enter group name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.name}
                              variant="outlined"
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton
                                          edge="end"
                                          onClick={() => {
                                             setFieldValue('name', '');
                                          }}
                                       >
                                          <HighlightOffIcon />
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                              }}
                           />
                           <span style={{ color: 'red' }}>
                              {errors.name && touched.name && errors.name}
                           </span>
                        </Box>
                        <Box>
                           <label
                              htmlFor="groupDesc"
                              style={{
                                 display: 'block',
                                 marginBottom: '5px',
                                 fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              }}
                           >
                              Description
                           </label>
                           <TextInput
                              fullWidth
                              margin="normal"
                              name="desc"
                              placeholder="Enter group desc"
                              error={Boolean(touched.desc && errors.desc)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              id="groupDesc"
                              value={values.desc}
                              variant="outlined"
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton
                                          edge="end"
                                          onClick={() => {
                                             setFieldValue('desc', '');
                                          }}
                                       >
                                          <HighlightOffIcon />
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                              }}
                           />
                           <span style={{ color: 'red' }}>
                              {errors.phone && touched.phone && errors.phone}
                           </span>
                        </Box>
                        <Box display={'flex'} sx={{ marginTop: '9rem' }}>
                           <FlatButton
                              disabled={
                                 !values.name ||
                                 !values.desc ||
                                 Boolean(
                                    errors.desc && touched.desc && errors.desc
                                 ) ||
                                 Boolean(
                                    errors.name && touched.name && errors.name
                                 )
                              }
                              type="submit"
                           >
                              Save
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
            </Box>
         </Card>
      </>
   );
};

export default EditGroup;
