import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

function NotificationPopup() {
  const [open, setOpen] = useState(false);

  // Check the time and set the popup to open if it's after 8:00 PM
  useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();

    if (hours >= 20 || hours < 8) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Notice</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Please note: In compliance with NCC regulations, all promotional messages sent after 8 PM will be queued by the Telco operators for delivery from 8:00 AM the next day.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationPopup;
