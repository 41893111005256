import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import ParroticaLogo from '../../../assets/BankLogo.svg';
import AcctLogo from '../../../assets/AcctLogo.svg';
import UserLogo from '../../../assets/UserLogo.svg';
import copyLogo from '../../../assets/copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../utils/selectors/selectors';
import { topUpBank } from '../../app/features/transactions/TransactionSlice';
import { useNavigate } from 'react-router-dom';
import { MdAccountBalanceWallet } from 'react-icons/md';

const BankTransfer = () => {
   const dispatch = useDispatch();
   const selector = useSelector(getTransactions);
   const navigate = useNavigate();

   const [isCopied, setIsCopied] = React.useState(false);
   const [isAmountCopied, setIsAmountCopied] = React.useState(false);

   React.useEffect(() => {
      dispatch(topUpBank({ amount: Number(selector.amount) }));
   }, []);

   const handleCopyClick = () => {
      navigator.clipboard
         .writeText(selector.bankDetails.accountNumber)
         .then(() => {
            setIsCopied(true);
            setTimeout(() => {
               setIsCopied(false);
            }, 2000);
         });
   };
   const handleAmountCopyClick = () => {
      navigator.clipboard.writeText(selector.bankDetails.amount).then(() => {
         setIsAmountCopied(true);
         setTimeout(() => {
            setIsAmountCopied(false);
         }, 2000);
      });
   };
   return (
      <div>
         <Typography
            fontWeight={500}
            color="#0F0E0E"
            fontSize={16}
            width="80%"
            margin="auto"
            align="center"
            mb={2}
         >
            Make a transfer to the personalised account below.
         </Typography>
         <Box
            sx={{
               display: 'flex',
               columnGap: '20px',
               '@media (max-width:751px)': {
                  flexDirection: 'column-reverse',
                  rowGap: '20px',
               },
            }}
         >
            <Box
               bgcolor="#ffffff"
               borderRadius={1}
               p={2}
               sx={{
                  flexGrow: 1,
                  flexShrink: 0,
                  // borderTopRightRadius: 0,
                  // borderBottomRightRadius: 0,
               }}
            >
               <Box
                  display="flex"
                  mt={1}
                  pb={1}
                  alignItems="center"
                  borderBottom="1px dashed #A1A1A1"
               >
                  <Box heigh="20px">
                     <img src={ParroticaLogo} alt="Logo" />
                  </Box>
                  <Box ml={2}>
                     <Typography
                        fontWeight={500}
                        color="#A1A1A1"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        Bank
                     </Typography>
                     <Typography
                        fontWeight={500}
                        color="#0F0E0E"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        {selector.bankDetails.bankName
                           ? selector.bankDetails.bankName
                           : 'loading...'}
                     </Typography>
                  </Box>
               </Box>

               <Box
                  display="flex"
                  mt={1}
                  pb={1}
                  alignItems="center"
                  borderBottom="1px dashed #A1A1A1"
                  position={'relative'}
               >
                  <Box>
                     <img src={AcctLogo} alt="Logo" />
                  </Box>
                  <Box ml={2}>
                     <Typography
                        fontWeight={500}
                        color="#A1A1A1"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        Account
                     </Typography>
                     <Typography
                        fontWeight={500}
                        color="#0F0E0E"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        {selector.bankDetails.accountNumber
                           ? selector.bankDetails.accountNumber
                           : 'loading...'}
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        borderRadius: '5px',
                        bgcolor: '#D7DBEC',
                        cursor: 'pointer',
                        position: 'absolute',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        right: '30px',
                     }}
                     onClick={() => handleCopyClick()}
                  >
                     Copy{' '}
                     <img
                        src={copyLogo}
                        style={{ marginLeft: '5px' }}
                        alt="icon"
                     />
                  </Box>
                  {isCopied && (
                     <Typography
                        sx={{
                           position: 'absolute',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           right: '50%',
                           transform: 'translateX(50%)',
                        }}
                     >
                        copied!
                     </Typography>
                  )}
               </Box>
               {/* <Box
                  display="flex"
                  mt={1}
                  pb={1}
                  alignItems="center"
                  borderBottom="1px dashed #A1A1A1"
                  position={'relative'}
               >
                  <Box
                     sx={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        backgroundColor: '#D7DBEC',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <MdAccountBalanceWallet size={30} color={'#374DA0'} />
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        borderRadius: '5px',
                        bgcolor: '#D7DBEC',
                        cursor: 'pointer',
                        position: 'absolute',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        right: 0,
                     }}
                     onClick={() => handleAmountCopyClick()}
                  >
                     Copy{' '}
                     <img
                        src={copyLogo}
                        style={{ marginLeft: '5px' }}
                        alt="icon"
                     />
                  </Box>
                  <Box ml={2}>
                     <Typography
                        fontWeight={500}
                        color="#A1A1A1"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        Amount
                     </Typography>
                     <Typography
                        fontWeight={500}
                        color="#0F0E0E"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        {selector.bankDetails.amount
                           ? selector.bankDetails.amount
                           : 'loading...'}
                     </Typography>
                  </Box>

                  {isAmountCopied && (
                     <Typography
                        sx={{
                           position: 'absolute',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           right: '50%',
                           transform: 'translateX(50%)',
                        }}
                     >
                        copied!
                     </Typography>
                  )}
               </Box> */}
               <Box display="flex" mt={1} pb={1} alignItems="center">
                  <Box heigh="20px">
                     <img src={UserLogo} alt="Logo" />
                  </Box>
                  <Box ml={2}>
                     <Typography
                        fontWeight={500}
                        color="#A1A1A1"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        Name
                     </Typography>
                     <Typography
                        fontWeight={500}
                        color="#0F0E0E"
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        {selector.bankDetails.accountName
                           ? selector.bankDetails.accountName
                           : 'loading...'}
                     </Typography>
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '250px',
                  height: 'auto',
                  // transform: 'scaleY(1.05)',
                  backgroundColor: '#fff',
                  // border: '2px solid #D9D8D9',
                  borderRadius: '12px',
                  border: ' 2px dashed #fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '2rem',
                  flexDirection: 'column',
                  padding: '10px',
                  '@media (max-width:751px)': {
                     width: '100%',
                     rowGap: '1rem',
                     backgroundColor: 'transparent',
                  },
               }}
            >
               <Typography fontWeight={700} color="#0F0E0E" fontSize={40}>
                  ₦{selector.bankDetails.amount}
               </Typography>
               <Typography
                  fontWeight={500}
                  color="#0F0E0E"
                  fontSize={15}
                  // width=""
                  // margin="auto"
                  align="center"
                  // mb={2}
               >
                  Please transfer this{' '}
                  <span style={{ color: '#A9419E', fontWeight: 700 }}>
                     exact
                  </span>{' '}
                  amount to fund your Parrotica account
               </Typography>
            </Box>
         </Box>

         <Button
            sx={{
               borderRadius: '11px',
               py: '10px',
               px: '35px',
               fontSize: '16px',
               width: '50%',
               m: 'auto',
               mt: '40px',
               display: 'flex',
               mb: '20px',
            }}
            variant="contained"
            size="small"
            onClick={() => navigate('/top-up')}
         >
            Confirm
         </Button>
      </div>
   );
};

export default BankTransfer;
