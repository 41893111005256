import { Box, Dialog } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';

import GroupCard from './GroupCard';
import { resetCreated } from '../../../components/app/features/contacts/contactSlice';
import Success from './Success';
import InfiniteScroll from 'react-infinite-scroll-component';

const Groups = ({
   sorted,
   action,
   handleCheckboxClick,
   checkedList,
   loadmore,
}) => {
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();

   return (
      <>
         <Box
            sx={{
               marginTop: '2rem',
               height: '50vh',
               overflowY: 'auto',
            }}
            id="scrollableDiv"
         >
            <InfiniteScroll
               dataLength={selector.groups.length}
               next={loadmore}
               hasMore={selector.hasMore}
               loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
               scrollableTarget="scrollableDiv"
               endMessage={
                  <p style={{ textAlign: 'center' }}>
                     Yay! You have seen it all
                  </p>
               }
            >
               {selector.groups &&
                  selector.groups.length > 0 &&
                  sorted.map((item, index) => (
                     <GroupCard
                        item={item}
                        key={index}
                        checkAction={action}
                        handleCheckboxClick={handleCheckboxClick}
                        checkedList={checkedList}
                     />
                  ))}
            </InfiniteScroll>
         </Box>
         <Dialog
            onClose={() => {
               dispatch(resetCreated());
            }}
            open={selector.created}
         >
            <Success text="You have successfully updated group details" />
         </Dialog>
      </>
   );
};

export default Groups;
