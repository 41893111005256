import { Box, Typography } from '@mui/material';
import React from 'react';
import ThirdSlideImg from '../../../assets/thirdSlide.svg';

const ThirdSlide = () => {
	return (
		<Box>
			<Box sx={{ width: '100%', height: '180px' }}>
				<img
					src={ThirdSlideImg}
					style={{ width: '100%', height: '100%' }}
					alt='icon'
				/>
			</Box>
			<Typography
				sx={{
					textAlign: 'center',
					color: '#0F0E0E',
					fontSize: '1rem',
					mt: '1rem',
				}}>
				Messages are charged per page, per number.
			</Typography>
		</Box>
	);
};

export default ThirdSlide;
