import React, { useState } from 'react';
import { Box, Card, styled, Typography, Tabs, Tab, Paper } from '@mui/material';
import { DashboardWrapper } from '../topUp/TopUpStyle';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import TabPanel from '../../components/layouts/tabPanel/TabPanel';
import BankTransfer from '../../components/layouts/bankTransfer/BankTransfer';
import InternetBanking from '../../components/layouts/internetBanking/InternetBanking';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTransactions } from '../../components/utils/selectors/selectors';

const CardWrapper = styled(Card)(
   ({ theme }) => `
           background: #EFEEF4;
           box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
           padding: 0px 20px 20px 20px;
    
         `
);

const TabsWrapper = styled(Tabs)(
   ({ theme }) => `
    width: 100%;

        @media (max-width: ${theme.breakpoints.values.md}px) {
          .MuiTabs-scrollableX {
            overflow-x: auto !important;
          }
    
          .MuiTabs-indicator {
              box-shadow: none;
          }
        }
        `
);

const SelectPayment = () => {
   const [value, setValue] = useState(0);

   const a11yProps = (index) => {
      return {
         id: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`,
      };
   };

   const selector = useSelector(getTransactions);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const navigate = useNavigate();

   React.useEffect(() => {
      if (Number(selector.amount) === 0) navigate('/top-up/');
   }, []);

   return (
      <DashboardWrapper>
         <CardWrapper
            className="topUpWrapper"
            style={{
               marginTop: '2rem',
            }}
         >
            <Box
               borderRadius={50}
               color="#ffffff"
               display="flex"
               textAlign="center"
               alignItems="center"
               justifyContent={'center'}
               width={40}
               height={40}
               bgcolor="#374DA0"
               mt={4}
               onClick={() => navigate('/top-up')}
            >
               <ArrowBackSharpIcon />
            </Box>
            <Typography
               align="center"
               fontFamily="'Raleway', 'Montserrat', sans-serif"
               fontWeight={600}
               color="#0F0E0E"
               sx={{ fontSize: 'clamp(1.1rem, 2vw, 1.25rem)' }}
            >
               Select payment methods
            </Typography>
            <Box
               display="flex"
               alignItems="center"
               justifyContent={{ xs: 'center', sm: 'center' }}
               pb={1}
               m={3}
               mt={4}
            >
               <TabsWrapper
                  sx={{
                     width: {
                        xs: '100%',
                        sm: '50%',
                     },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
               >
                  <Tab label="Internet Banking" {...a11yProps(0)} />
                  <Tab label="Bank Transfer" {...a11yProps(1)} />
               </TabsWrapper>
            </Box>
            <TabPanel value={value} index={0}>
               <InternetBanking />
            </TabPanel>
            <TabPanel value={value} index={1}>
               <BankTransfer />
            </TabPanel>
         </CardWrapper>
      </DashboardWrapper>
   );
};

export default SelectPayment;
