import React, { useEffect, useState, forwardRef } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
   Button,
   TextField,
   CircularProgress,
   IconButton,
   InputAdornment,
   styled,
   Dialog,
   Typography,
   Box,
   Slide,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NigeriaFlag from '../../../../assets/NigeriaFlag.svg';
import { MuiOtpInput } from 'mui-one-time-password-input';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../../utils/selectors/selectors';
import {
   generateOTP,
   resetLoading,
} from '../../../app/features/auth/AuthSlice';
import { verifyPhone } from '../../../app/features/auth/AuthSlice';
import { Link, useNavigate } from 'react-router-dom';
import { borderRadius, height } from '@mui/system';

const TextInput = styled(TextField)(
   ({ theme }) => `
       color: #49454F;
       margin-top: 40px;
       &.placeholer{
        color: red;
       }
     `
);

const boxSX = {
   '&.Mui-checked': {
      color: '#374DA0',
   },
};

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
   () => `
        .MuiDialog-paper {
          overflow: visible;
        }1    1q
  `
);

const PhoneForgotPassword = () => {
   const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
   const [minutes, setMinutes] = useState(1);
   const [seconds, setSeconds] = useState(30);
   const navigate = useNavigate();

   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);

   const closeConfirmDelete = () => {
      setOpenConfirmDelete(false);
   };

   const [otp, setOtp] = React.useState('');
   const [userOtp, setUserOtp] = useState('');

   const handleChange = (newValue) => {
      setOtp(newValue);
   };

   const handleGetOTP = (type) => {
      setOpenConfirmDelete(true);
      dispatch(generateOTP({ userId: selector?.admin?.id, type }));
   };

   useEffect(() => {
      if (!userOtp) {
         const interval = setInterval(() => {
            if (seconds > 0) {
               setSeconds(seconds - 1);
            }
            if (seconds === 0) {
               if (minutes === 0) {
                  clearInterval(interval);
               } else {
                  setSeconds(59);
                  setMinutes(minutes - 1);
               }
            }
         }, 1000);

         return () => {
            clearInterval(interval);
         };
      } else {
      }
   }, [seconds, otp]);

   const resendOTP = (type) => {
      setMinutes(1);
      setSeconds(30);
      dispatch(generateOTP({ userId: selector?.admin?.id, type }));
   };

   const verifyPhoneOtp = () => {
      dispatch(resetLoading());
      dispatch(
         verifyPhone({
            phoneNumber: otp,
            userId: selector?.admin?.id,
         })
      );
      if (selector.verifyPhoneLoading === 'success') {
         setOpenConfirmDelete(false);
         navigate('/reset-password');
      }
   };

   return (
      <div>
         <Formik
            initialValues={{
               phoneNumber: '',
            }}
            validationSchema={Yup.object().shape({
               phoneNumber: Yup.string()
                  .max(255)
                  .matches(/^(\+234|0)[789]\d{9}$/, 'Invalid phone number')
                  .required('The phone number field is required'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {}}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
               setFieldValue,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <TextInput
                     fullWidth
                     margin="normal"
                     helperText={touched.phoneNumber && errors.phoneNumber}
                     error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                     name="phoneNumber"
                     placeholder="080xxxxxxxx"
                     onBlur={handleBlur}
                     onChange={handleChange}
                     type="text"
                     value={values.phoneNumber}
                     variant="outlined"
                     InputProps={{
                        startAdornment: (
                           <InputAdornment position="start">
                              <img
                                 src={NigeriaFlag}
                                 alt="flag"
                                 className="nigeriaFlag"
                              />
                              <Typography ml={-1}> </Typography>
                           </InputAdornment>
                        ),
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 edge="end"
                                 onClick={() => {
                                    setFieldValue('phoneNumber', '');
                                 }}
                              >
                                 <HighlightOffIcon />
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '7px',
                        px: '35px',
                        fontSize: '15px',
                        width: '45%',
                        mx: 'auto',
                        mt: '100px',
                     }}
                     variant="contained"
                     size="small"
                     type="submit"
                     onClick={() => handleGetOTP('phone')}
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={!values.phoneNumber || errors.phoneNumber}
                  >
                     Get OTP
                  </Button>
                  <Typography
                     sx={{
                        mt: 2,
                     }}
                  >
                     <Link
                        to="/login"
                        style={{
                           textDecorationLine: 'none',
                           fontFamily: "'Raleway', 'Monteserat', sans-serif",
                        }}
                     >
                        Login
                     </Link>
                  </Typography>
               </form>
            )}
         </Formik>

         <DialogWrapper
            open={openConfirmDelete}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={closeConfirmDelete}
         >
            <ArrowBackIcon
               onClick={closeConfirmDelete}
               sx={{
                  border: '2px solid',
                  borderRadius: '50%',
                  marginTop: '20px',
                  ml: '15px',
                  color: 'white',
                  backgroundColor: '#374DA0',
                  height: '30px',
                  width: '30px',
                  cursor: 'pointer',
               }}
            />

            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
               p={5}
            >
               <Typography align="center" px={6} pb={1} variant="h4">
                  Enter your OTP
               </Typography>
               <Typography fontWeight={300} color="#02222d">
                  Enter the 4 digit code sent to your phone number
               </Typography>

               <form>
                  <Box
                     display="flex"
                     alignItems="center"
                     justifyContent="center"
                     flexDirection="column"
                     px={5}
                     pt={12}
                  >
                     <Box width={{ sm: '70%', xs: '100%' }}>
                        <MuiOtpInput
                           length={5}
                           value={otp}
                           onChange={handleChange}
                        />
                     </Box>
                     {!userOtp && (
                        <Typography color="#374DA0" mt={5}>
                           {seconds > 0 || minutes > 0 ? (
                              <p>
                                 {minutes < 10 ? `0${minutes}` : minutes}:
                                 {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                           ) : (
                              <p onClick={() => resendOTP('phone')}>
                                 Resend Otp
                              </p>
                           )}
                        </Typography>
                     )}
                     <Button
                        sx={{
                           borderRadius: '11px',
                           py: '3px',
                           px: '35px',
                           fontSize: '15px',
                           width: '55%',
                           mx: 'auto',
                           mt: '60px',
                        }}
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={verifyPhoneOtp}
                     >
                        Confirm
                     </Button>
                  </Box>
               </form>
               <Typography
                  textAlign="center"
                  fontWeight={300}
                  color="#0F0E0E"
                  pt={5}
               >
                  Didn’t receive the OTP? Click Resend OTP after the countdown
               </Typography>
            </Box>
         </DialogWrapper>
      </div>
   );
};

export default PhoneForgotPassword;
