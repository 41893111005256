import React from 'react';
import {
   Box,
   Typography,
   Card,
   Button,
   TextField,
   InputAdornment,
   IconButton,
   Dialog,
} from '@mui/material';
import * as Yup from 'yup';
import backImg from '../../../assets/back.svg';
import contactImg from '../../../assets/contactImg.svg';
import phoneImg from '../../../assets/phoneImg.svg';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { editContact } from '../../../components/app/features/contacts/contactSlice';

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
padding:7px 0;
font-weight: 600;
width: 60%;
text-align: center;
font-size: clamp(0.9rem, 1.5vw, 1rem);
border-radius:5px;
margin: 1rem auto;
&:hover {background: #374DA0;}
@media(max-width:570px){
   width: 80%;
}
`
);

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: white;
       color: #49454F;
       
     `
);

const EditContact = ({ name, number, id, close }) => {
   // const [isOpen, setIsOpen] = React.useState(false);
   const phoneRegExp = /^(?:(?:\+234)|0)[1-9](?:(?:\d{8})|(?:\d{9}))$/;
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);

   return (
      <Card
         sx={{
            width: '500px',
            margin: '0 auto',
            position: 'relative',
            boxShadow:
               '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
            padding: '20px ',
            background: '#EFEEF4',
            '@media(max-width:570px)': {
               width: '80vw',
            },
         }}
      >
         <Box
            sx={{
               margin: '0 auto',
               marginTop: '1rem',
               border: '1px solid transparent',
               position: 'relative',
            }}
         >
            <img
               src={backImg}
               alt="back"
               style={{
                  position: 'absolute',
                  width: '40px',
                  cursor: 'pointer',
               }}
               onClick={() => window.history.back()}
            />

            <Typography
               sx={{
                  width: 'max-content',
                  fontWeight: 600,
                  fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                  color: '#0F0E0E',
                  margin: '2rem auto',
               }}
            >
               Edit contact
            </Typography>

            <Formik
               initialValues={{
                  name: name,
                  phone: number,
                  userId: selector?.admin?.id,
                  contactId: id,
               }}
               validationSchema={Yup.object().shape({
                  name: Yup.string().required('The field is required'),
                  phone: Yup.string()
                     .matches(phoneRegExp, 'Invalid phone number')
                     .required('Phone number is required'),
               })}
               onSubmit={async (values, { setSubmitting }) => {
                  dispatch(editContact(values));
                  setTimeout(() => {
                     setSubmitting(false);
                  }, 400);
               }}
            >
               {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
               }) => (
                  <form
                     noValidate
                     onSubmit={handleSubmit}
                     style={{ maxWidth: '615px' }}
                  >
                     <label
                        htmlFor="name"
                        style={{ marginBottom: '5px', display: 'block' }}
                     >
                        Name
                     </label>
                     <TextInput
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        margin="normal"
                        name="name"
                        placeholder="Enter your name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.name}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <img src={contactImg} alt="contact" />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => {
                                       setFieldValue('name', '');
                                    }}
                                 >
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                     <span style={{ color: 'red' }}>
                        {errors.name && touched.name && errors.name}
                     </span>

                     <label
                        htmlFor="phone"
                        style={{
                           marginBottom: '5px',
                           marginTop: '1rem',
                           display: 'block',
                        }}
                     >
                        Phone number
                     </label>
                     <TextInput
                        fullWidth
                        margin="normal"
                        name="phone"
                        placeholder="Enter your phone number"
                        error={Boolean(touched.phone && errors.phone)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.phone}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <img src={phoneImg} alt="phone" />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => {
                                       setFieldValue('phone', '');
                                    }}
                                 >
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                     <span style={{ color: 'red' }}>
                        {errors.phone && touched.phone && errors.phone}
                     </span>
                     <Box display={'flex'} sx={{ marginTop: '9rem' }}>
                        <FlatButton
                           disabled={
                              !values.name ||
                              !values.phone ||
                              Boolean(
                                 errors.phone && touched.phone && errors.phone
                              ) ||
                              Boolean(
                                 errors.name && touched.name && errors.name
                              )
                           }
                           type="submit"
                           onClick={() => close(false)}
                        >
                           Save Contact
                        </FlatButton>
                     </Box>
                  </form>
               )}
            </Formik>
         </Box>
      </Card>
   );
};

export default EditContact;
