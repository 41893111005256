import { createSelector } from '@reduxjs/toolkit';

// Auth Selector
export const auth = (state) => state.auth;
export const getAuthSelector = createSelector(auth, (state) => state);

// avatar selector
export const avatar = (state) => state.auth;
export const getAvatarSelector = createSelector(avatar, (state) => state);

// transactions selector
export const transaction = (state) => state.transaction;
export const getTransactions = createSelector(transaction, (state) => state);

// contacts selector
export const contact = (state) => state.contact;
export const contactSelector = createSelector(contact, (state) => state);

// messages selector

export const message = (state) => state.message;
export const messageSelector = createSelector(message, (state) => state);
