import React from 'react';
import { ContactWrapper } from './styles';
import ContactInfo from '../../components/contacts/contactInfo/ContactInfo';

const Contacts = () => {
	return (
		<ContactWrapper>
			<ContactInfo />
		</ContactWrapper>
	);
};

export default Contacts;
