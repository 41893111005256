import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Checkbox,
   Button,
} from '@mui/material';
import { Container, TextInput } from './style';
import { FaSearch } from 'react-icons/fa';
import backImg from '../../../assets/back.svg';
import doc from '../../../assets/Doc.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
   contactSelector,
   messageSelector,
} from '../../utils/selectors/selectors';
import { fetchGroups } from '../../app/features/contacts/contactSlice';
import { addSelectedGroups } from '../../app/features/message/messageSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import noTransIcon from '../../../assets/noTrans.svg';
import { userLSAuth } from '../../app/features/env';

const GroupSelect = () => {
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();
   const [search, setSearch] = React.useState('');
   const [sorted, setSorted] = React.useState([]);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const [page, setPage] = React.useState(1);
   const handleSearch = (event) => {
      setSearch(event.target.value);
   };
   const msgSelector = useSelector(messageSelector);

   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
         setPage(page + 1);
      }
   };

   React.useEffect(() => {
      dispatch(fetchGroups({ page }));
   }, [page]);

   React.useEffect(() => {
      if (selector.groups) {
         setSorted(
            [...selector?.groups].sort(function (a, b) {
               if (a.title.toLowerCase() < b.title.toLowerCase()) {
                  return -1;
               }
               if (a.title.toLowerCase() > b.title.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );
      }

      if (search != '') {
         setSorted(
            sorted.filter((item) => {
               if (item.title.toLowerCase().includes(search.toLowerCase())) {
                  return [item];
               }
            })
         );
      }
   }, [selector.groups, search]);

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         sorted.map((item, id) => collection.push(item.id));
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const groupList = [];
   [...selector?.groups].map((item) => {
      checkedList.map((list) => {
         if (item.id === list) {
            groupList.push(item);
         }
      });
   });

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               minHeight: '80vh',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  position: 'relative',
                  border: '1px solid transparent',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                     top: 0,
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '1rem auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                  }}
               >
                  Select Groups
               </Typography>
               <Typography
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     fontWeight: 600,
                     position: 'relative',
                  }}
               >
                  <Checkbox
                     checked={itemsChecked}
                     onClick={selectItem}
                     {...label}
                  />{' '}
                  select all
               </Typography>
               <FormControl
                  sx={{ width: '100%', position: 'relative', margin: '1rem 0' }}
               >
                  <FaSearch
                     color="#374DA0"
                     style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '20px',
                        transform: 'translateY(-50%)',
                        fontSize: '0.9rem',
                     }}
                  />
                  <TextInput
                     fullWidth
                     placeholder="search groups"
                     InputProps={{
                        style: {
                           color: '#374DA0',
                           borderRadius: '25px',
                           paddingLeft: '35px',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        },
                     }}
                     value={search}
                     onChange={(event) => handleSearch(event)}
                  />
               </FormControl>
               <Box
                  sx={{
                     marginTop: '2rem',
                     height: '42vh',
                     overflowY: 'auto',
                  }}
                  id="scrollableDiv"
               >
                  <InfiniteScroll
                     dataLength={selector.groups.length}
                     next={loadmore}
                     hasMore={selector.hasMore}
                     loader={
                        <h4 style={{ textAlign: 'center' }}>Loading...</h4>
                     }
                     scrollableTarget="scrollableDiv"
                     endMessage={
                        sorted.length > 0 && (
                           <p style={{ textAlign: 'center' }}>
                              Yay! You have seen it all
                           </p>
                        )
                     }
                  >
                     {selector?.groups.length > 0 ? (
                        sorted.map((item) => (
                           <Card
                              key={item.id}
                              sx={{
                                 padding: '20px',
                                 paddingTop: '10px',
                                 position: 'relative',
                                 marginBottom: '10px',
                              }}
                           >
                              <Box
                                 sx={{ display: 'flex', marginBottom: ' 1rem' }}
                              >
                                 <Typography
                                    sx={{
                                       fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                       fontWeight: 600,
                                       cursor: 'pointer',
                                       position: 'relative',
                                       display: 'flex',
                                       alignItems: 'center',
                                    }}
                                 >
                                    <Checkbox
                                       sx={{ left: '-10px' }}
                                       value={item.id}
                                       checked={checkedList.includes(item.id)}
                                       onClick={handleCheckboxClick}
                                       {...label}
                                    />
                                    {item.title}
                                 </Typography>
                              </Box>
                              <Typography
                                 sx={{
                                    fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                    fontWeight: 600,
                                 }}
                              >
                                 {`	${
                                    item?.contacts ? item?.contacts.length : 0
                                 } contacts`}
                              </Typography>
                              <Box sx={{ display: 'flex', marginTop: ' 1rem' }}>
                                 <img
                                    src={doc}
                                    alt="group"
                                    style={{ marginRight: '15px' }}
                                 />
                                 <Typography
                                    sx={{
                                       fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                       fontWeight: 600,
                                    }}
                                 >
                                    {item?.description}
                                 </Typography>
                              </Box>
                           </Card>
                        ))
                     ) : (
                        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                           <Box>
                              <img src={noTransIcon} alt="icon" />
                           </Box>
                           <Typography
                              sx={{
                                 fontWeight: 600,
                                 fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                                 color: '#374da0',
                                 marginTop: '1rem',
                                 marginBottom: '1rem',
                                 // textAlign: 'center',
                              }}
                           >{`Hey ${
                              userLSAuth()?.fullName.split(' ')[0]
                           }  your groups will appear here`}</Typography>
                        </Box>
                     )}
                  </InfiniteScroll>
                  {search !== '' && sorted.length === 0 && (
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontSize: 'clamp(1rem, 2vw, 1.2rem)',
                           fontWeight: '600',
                           marginTop: '2rem',
                        }}
                     >
                        Oops No Search result!
                     </Typography>
                  )}
               </Box>
               <Button
                  variant="contained"
                  sx={{
                     width: '100%',
                     padding: '7px 0',
                     fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     margin: '2rem 0 1rem 0',
                  }}
                  disabled={loading}
                  onClick={() => {
                     setLoading(true)
                     dispatch(addSelectedGroups(groupList));
                     window.history.back();
                  }}
               >
                  {loading ? 'Adding...': 'Add Group'}
               </Button>
            </Box>
         </Card>
      </Container>
   );
};

export default GroupSelect;
