import httpclient from '../app/features/httpClient.js';

const setAuthToken = (token) => {
   if (token) {
      httpclient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
   } else {
      delete httpclient.defaults.headers.common['Authorization'];
   }
};

export default setAuthToken;
