import React from 'react';
import { Typography, Box, FormControlLabel, Radio, Card } from '@mui/material';
import { TypeSpecimenSharp } from '@mui/icons-material';

const InternetBankingLink = ({
   bankingLogo,
   bankingName,
   radioValue,
   getChecked,
}) => {
   const [isChecked, setIsChecked] = React.useState(false);

   const handleCheckChange = (event) => {
      setIsChecked(event.target.value);
      getChecked(event.target.value);
   };

   return (
      <Card>
         <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems="center"
            py={1}
         >
            <Box ml={3}>
               <img src={bankingLogo} alt="Internet Logo" />
            </Box>
            <Typography
               flex={'1'}
               ml={2}
               fontSize={16}
               fontFamily="'Raleway', 'Montserrat', sans-serif"
               fontWeight={500}
               color="#0B0F20"
            >
               {bankingName}
            </Typography>
            <FormControlLabel
               value={radioValue}
               onChange={handleCheckChange}
               control={<Radio />}
            />
         </Box>
      </Card>
   );
};

export default InternetBankingLink;
