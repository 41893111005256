import React from 'react';

const Tag = ({ fillColor }) => {
	return (
		<>
			<svg
				width='16'
				height='16'
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M15.0575 7.685L8.3075 0.935C8.02626 0.65606 7.64611 0.499684 7.25 0.5H2C1.60218 0.5 1.22064 0.658036 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V7.25C0.501675 7.64718 0.660809 8.02749 0.9425 8.3075L7.6925 15.0575C7.97251 15.3392 8.35282 15.4983 8.75 15.5C9.14718 15.4983 9.52749 15.3392 9.8075 15.0575L15.0575 9.8075C15.3392 9.52749 15.4983 9.14718 15.5 8.75C15.5002 8.55207 15.4611 8.35606 15.3852 8.17327C15.3092 7.99049 15.1979 7.82454 15.0575 7.685ZM3.875 5C3.6525 5 3.43499 4.93402 3.24998 4.8104C3.06498 4.68679 2.92078 4.51109 2.83564 4.30552C2.75049 4.09995 2.72821 3.87375 2.77162 3.65552C2.81502 3.4373 2.92217 3.23684 3.0795 3.07951C3.23684 2.92217 3.43729 2.81503 3.65552 2.77162C3.87375 2.72821 4.09995 2.75049 4.30552 2.83564C4.51109 2.92078 4.68679 3.06498 4.8104 3.24998C4.93402 3.43499 5 3.6525 5 3.875C5 4.17337 4.88147 4.45952 4.6705 4.6705C4.45952 4.88147 4.17337 5 3.875 5ZM7.6925 10.5575L4.6925 7.5575L5.75 6.5L8.75 9.5M10.6925 9.8075L6.5675 5.6825L7.625 4.625L11.75 8.75L10.6925 9.8075Z'
					fill={fillColor}
				/>
			</svg>
		</>
	);
};

export default React.memo(Tag);
