import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Menu,
   MenuItem,
   Checkbox,
   Dialog,
   // Dialog,
} from '@mui/material';
import { Container, TextInput } from './style';
import backImg from '../../../assets/back.svg';
import addImg from '../../../assets/FAB2.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import trash from '../../../assets/trash.svg';
import msgIcon from '../../../assets/messageIcon3.svg';
import { FaSearch, FaTimes } from 'react-icons/fa';
import Groups from './Groups';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import {
   contactSummary,
   deleteGroup,
   fetchGroups,
} from '../../../components/app/features/contacts/contactSlice';
import NoGroup from '../../../components/contacts/NoGroup';
import { addSelectedGroups } from '../../../components/app/features/message/messageSlice';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
   box-sizing: border-box;
   white-space: nowrap;
	width: 160px;
	font-weight:700;
	border-radius:0;
	`
);
export const AddImage = styled.img(
   ({ theme }) => `
	position: absolute;
   z-index: 4;
	right: 20px;
	bottom: 2rem;
	width: 60px;
	cursor: pointer;

`
);
const AllGroups = () => {
   const selector = useSelector(contactSelector);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [sorted, setSorted] = React.useState([]);
   const [search, setSearch] = React.useState('');
   const [isDelete, setIsDelete] = React.useState(false);
   const [page, setPage] = React.useState(1);
   const actions = ['Add Group', 'Send Message', 'Delete'];

   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
         setPage(page + 1);
      }
   };

   React.useEffect(() => {
      dispatch(fetchGroups({ page }));
   }, [page]);

   React.useEffect(() => {
      if (selector.groups) {
         setSorted(
            [...selector.groups].sort(function (a, b) {
               if (a.title.toLowerCase() < b.title.toLowerCase()) {
                  return -1;
               }
               if (a.title.toLowerCase() > b.title.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );
      }

      if (search != '') {
         setSorted(
            sorted.filter((item) => {
               if (
                  item.title.toLowerCase().includes(search.toLowerCase()) ||
                  item.description.toLowerCase().includes(search.toLowerCase())
               ) {
                  return [item];
               }
            })
         );
      }
   }, [selector.groups, search, selector.starred === true]);

   const setClose = () => setAnchorEl(false);
   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const groupList = [];
   if (selector.groups) {
      [...selector?.groups].map((item) => {
         checkedList.map((list) => {
            if (item.id === list) {
               groupList.push(item);
            }
         });
      });
   }

   // search functionality
   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   React.useEffect(() => {
      dispatch(contactSummary());
   }, []);
   React.useEffect(() => {
      if (action === 'Add Group') {
         navigate('/contacts/add-group');
      }
   }, [action]);

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         sorted.map((item, id) => collection.push(item.id));
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const handleSendMessage = () => {
      dispatch(addSelectedGroups(groupList));
      navigate('/send-message/message');
   };

   // delete functionality here
   const deleteGroups = () => {
      if (checkedList.length > 0) {
         checkedList.map((item) => dispatch(deleteGroup({ groupId: item })));
      } else {
         setAction('');
      }
      dispatch(fetchGroups());
      setIsDelete(false);
      setAction('');
   };

   return (
      <Container>
         {selector?.summary.groupCount === 0 &&
         selector.loading !== 'pending' ? (
            <NoGroup />
         ) : (
            <Card
               sx={{
                  width: '90%',
                  maxWidth: '615px',
                  margin: '2rem auto',
                  position: 'relative',
                  boxShadow:
                     '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
                  padding: '20px ',
                  background: '#EFEEF4',
                  minHeight: '80vh',
                  borderTopLeftRadius: '25px',
                  borderTopRightRadius: '25px',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
               }}
            >
               <Dialog
                  onClose={() => {
                     setIsDelete(false);
                     setAction('');
                  }}
                  open={isDelete}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: '(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Are you sure you want to delete the selected contacts?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: '(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsDelete(false);
                              setAction('');
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: '(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => {
                              deleteGroups();
                           }}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               {action === '' && (
                  <AddImage
                     src={addImg}
                     alt="add contacts"
                     onClick={() => navigate('/contacts/add-group')}
                  />
               )}
               <Box
                  sx={{
                     margin: '0 auto',
                     marginTop: '1rem',
                     position: 'relative',
                     border: '1px solid transparent',
                  }}
               >
                  <img
                     src={backImg}
                     alt="back"
                     style={{
                        position: 'absolute',
                        width: '40px',
                        cursor: 'pointer',
                        top: 0,
                     }}
                     onClick={() => window.history.back()}
                  />
                  <DropDown
                     id="basic-menu"
                     anchorEl={anchorEl}
                     open={Boolean(anchorEl)}
                     onClose={setClose}
                     MenuListProps={{
                        'aria-labelledby': 'basic-button',
                     }}
                  >
                     {actions.map((action, index) => (
                        <Activity
                           key={index}
                           onClick={(e) => handleChange(e.target.innerText)}
                        >
                           {action}
                        </Activity>
                     ))}
                  </DropDown>

                  <img
                     src={verticalMenu}
                     alt="back"
                     style={{
                        position: 'absolute',
                        width: '40px',
                        right: 0,
                        filter:
                           'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                        cursor: 'pointer',
                     }}
                     onClick={(e) => setAnchorEl(e.currentTarget)}
                  />
                  <Typography
                     sx={{
                        width: 'max-content',
                        margin: '0 auto',
                        fontWeight: 600,
                        fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                        color: '#0F0E0E',
                        marginTop: '2rem',
                     }}
                  >
                     Groups
                  </Typography>
                  <Typography
                     sx={{
                        width: 'max-content',
                        margin: '0 auto',
                        fontWeight: 600,
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: '#0F0E0E',
                     }}
                  >
                     {selector.groups && selector.groups.length > 0
                        ? selector.groups.length
                        : 0}{' '}
                     groups
                  </Typography>
                  {(action === 'Delete' || action === 'Send Message') && (
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           fontWeight: 600,
                           position: 'relative',
                        }}
                     >
                        <Checkbox
                           checked={itemsChecked}
                           onClick={selectItem}
                           {...label}
                        />{' '}
                        Select All
                        {action === 'Delete' && (
                           <img
                              src={trash}
                              alt="delete"
                              style={{
                                 position: 'absolute',
                                 right: '40px',
                                 top: '50%',
                                 transform: 'translateY(-50%)',
                                 cursor: 'pointer',
                              }}
                              onClick={() => setIsDelete(true)}
                           />
                        )}
                        {action === 'Send Message' && (
                           <img
                              src={msgIcon}
                              alt="message"
                              style={{
                                 position: 'absolute',
                                 right: '40px',
                                 top: '50%',
                                 transform: 'translateY(-50%)',
                                 cursor: 'pointer',
                              }}
                              onClick={() => handleSendMessage()}
                           />
                        )}
                        <FaTimes
                           color="#000"
                           style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                           }}
                           onClick={() => setAction('')}
                        />
                     </Typography>
                  )}
                  <FormControl
                     sx={{
                        width: '100%',
                        position: 'relative',
                        marginTop: '2rem',
                     }}
                  >
                     <FaSearch
                        color="#374DA0"
                        style={{
                           zIndex: 2,
                           position: 'absolute',
                           top: '50%',
                           left: '20px',
                           transform: 'translateY(-50%)',
                           fontSize: '0.9rem',
                        }}
                     />
                     <TextInput
                        fullWidth
                        placeholder="search groups"
                        InputProps={{
                           style: {
                              color: '#374DA0',
                              borderRadius: '25px',
                              paddingLeft: '35px',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '1rem',
                           },
                        }}
                        value={search}
                        onChange={(event) => handleSearch(event)}
                     />
                  </FormControl>

                  <Groups
                     sorted={sorted}
                     action={action}
                     handleCheckboxClick={handleCheckboxClick}
                     checkedList={checkedList}
                     loadmore={loadmore}
                  />
               </Box>
            </Card>
         )}{' '}
      </Container>
   );
};

export default AllGroups;
