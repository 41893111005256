import React from 'react';
import { Box, Typography, Card, Checkbox, Button, Dialog } from '@mui/material';
import backImg from '../../../assets/back.svg';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import {
   createContact,
   resetCreated,
} from '../../../components/app/features/contacts/contactSlice';
import Success from './Success';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #EFEEF4;
 font-family:'Raleway','Montserrat',sans-serif;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
font-family: 'Raleway','Montserrat',sans-serif;
color: #fff;
font-weight: 600;
max-width: 300px;
width: 100%;
text-align: center;
font-size: clamp(0.9rem, 1.5vw, 1rem);
border-radius:5px;
margin: 1rem auto;
padding:7px 0;
&:hover {background: #374DA0;}
`
);

const Vcf = () => {
   const [searchParams] = useSearchParams();
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   const navigate = useNavigate();
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [isDisabled, setisDisabled] = React.useState(true);
   const [isOpen, setIsOpen] = React.useState(false);
   const [count, setCount] = React.useState(0);

   React.useEffect(() => {
      if (selector.created === true) {
         setIsOpen(true);
         setCount(
            selector.createdContactsCount ? selector.createdContactsCount : 0
         );
      }
   }, [selector.created]);

   React.useEffect(() => {
      if (selector.vcf.length === 0) {
         navigate('/contacts');
      }
   }, []);

   const contacts =
      selector.vcf &&
      [...selector?.vcf]
         .map((vcf, index) => ({
            ...vcf,
            id: index,
         }))
         .sort((a, b) => a.fullName.localeCompare(b.fullName));
   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];
      if (checked) {
         contacts.map((item) => collection.push(item.id));
      }
      setCheckedList(collection);
      setItemsChecked(checked);
   };

   React.useEffect(() => {
      if (checkedList.length > 0) {
         setisDisabled(false);
      }
      if (checkedList.length === 0) {
         setisDisabled(true);
      }
   }, [checkedList.length]);

   const PayloadData = [];
   contacts.forEach((item) => {
      checkedList.map((element) => {
         if (element === item.id) {
            PayloadData.push({ fullName: item.fullName, number: item.number });
         }
      });
   });

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '2rem',
                  }}
               >
                  Check Contacts to Import
               </Typography>
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 400,
                     fontSize: 'clamp(0.9rem, 2vw, 0.5rem)',
                     color: '#7A88C0',
                     marginTop: '2rem',
                  }}
               >
                  Quick Tip: Click 'Select All' to choose all contacts at once.
               </Typography>
               <Typography
                  sx={{
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     fontWeight: 600,
                     marginLeft: '15px',
                  }}
               >
                  <Checkbox
                     checked={itemsChecked}
                     onClick={selectItem}
                     {...label}
                  />{' '}
                  Select All
               </Typography>

               <Box
                  sx={{
                     marginTop: '2rem',
                     height: '350px',
                     overflowY: 'auto',
                  }}
               >
                  {contacts.map((item) => (
                     <Card
                        sx={{
                           display: 'flex',
                           paddingY: '10px',
                           paddingLeft: '15px',
                           marginBottom: '10px',
                           cursor: 'pointer',
                           '&:hover': {
                              boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.3)',
                           },
                        }}
                        key={item?.id}
                     >
                        <Checkbox
                           value={item?.id}
                           checked={checkedList.includes(item?.id)}
                           onClick={handleCheckboxClick}
                           {...label}
                        />
                        <Box sx={{ paddingLeft: '15px' }} key={item?.id}>
                           <Typography
                              sx={{
                                 fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                 fontWeight: 600,
                              }}
                           >
                              {item.fullName}
                           </Typography>
                           <Typography
                              sx={{
                                 fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                 fontWeight: 600,
                              }}
                           >
                              {item.number}
                           </Typography>
                        </Box>
                     </Card>
                  ))}
               </Box>
               <Box display={'flex'}>
                  <FlatButton
                     disabled={isDisabled ||  selector.loading === 'pending'}
                     onClick={() =>
                        dispatch(
                           createContact({
                              contact: PayloadData,
                              userId: selector.admin?.id,
                              groupId: searchParams.get('groupId'),
                           })
                        )
                     }
                  >
                     { selector.loading === 'pending' ? 'Adding': ' Add selected contacts'} 
                    
                  </FlatButton>
               </Box>
               <Dialog
                  onClose={() => {
                     setIsOpen(false);
                     dispatch(resetCreated());
                  }}
                  open={isOpen}
               >
                  <Success count={count} />
               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default Vcf;
