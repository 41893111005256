import React from 'react';
import {
   Card,
   DialogTitle,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
   Typography,
   Box,
} from '@mui/material';
import { getBaseUrls } from '../../../components/app/features/env';
import { useNavigate, createSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { openVcf } from '../../../components/app/features/contacts/contactSlice';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomFileInput = styled.label`
   width: 100px;
   display: inline-block;
   padding: 6px 12px;
   font-size: 16px;
   font-weight: 500;
   color: #0f0e0e;
   background-color: #ececec;
   border: 1px solid #a1a1a1;
   border-radius: 4px;
   cursor: pointer;
   text-align: center;
`;

const AddContactsGroup = ({
   setDialogState,
   reset,
   vcfText,
   contactText,
   copyPasteText,
   groupId,
}) => {
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   const [value, setValue] = React.useState('CopyPaste');

   const handleInputChange = (event) => {
      event.preventDefault();
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('vcf', file);
      dispatch(openVcf(formData.get('vcf')));
   };

   const handleChange = (event) => {
      setValue(event.target.value);
   };
   const navigate = useNavigate();

   return (
      <Card
         sx={{
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 30px',
         }}
      >
         <DialogTitle
            sx={{
               textAlign: 'center',
               fontWeight: '600',
               fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
            }}
         >
            Choose an option
         </DialogTitle>
         <FormControl
            sx={{
               // border: '1px solid',
               marginBottom: '1rem ',
            }}
         >
            <RadioGroup
               aria-labelledby="demo-radio-buttons-group-label"
               defaultValue="CopyPaste"
               name="radio-buttons-group"
               value={value}
               onChange={handleChange}
            >
               <div className="d-flex">
                  <FormControlLabel
                     value="CopyPaste"
                     control={<Radio />}
                     label="Paste"
                  />
                  <Tooltip
                     title="DND and non-DND contact classification takes a few minutes to complete; initial Non-DND status will update automatically after verification."
                     arrow
                     style={{ padding: '2px', margin: '-5px' }}
                  >
                     <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
               </div>

               <d className="d-flex">
                  <FormControlLabel
                     value="Contact"
                     control={<Radio />}
                     label="Contact List"
                  />
               </d>
               <d className="d-flex">
                  <FormControlLabel
                     value="Import VCF"
                     control={<Radio />}
                     label="Import VCF/CSV"
                  />
                  <Tooltip
                     title="VCF and CSV store contact details like names and emails. Import VCF file directly or download the CSV template, populate, and import. Also note that DND and non-DND classification for all imported contacts takes a few minutes to complete."
                     arrow
                     style={{ padding: '2px', margin: '-5px' }}
                  >
                     <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
               </d>
            </RadioGroup>
         </FormControl>
         <Box
            sx={{
               width: '100%',
               display: 'flex',
               justifyContent: 'space-between',
               columnGap: '15px',
               alignItems: 'center',
            }}
         >
            {value === 'Import VCF' && (
               <a href={`${getBaseUrls().data}/v1/csv-template`}>
                  Download CSV template
               </a>
            )}
            {value === 'Import VCF' && (
               <CustomFileInput>
                  Browse...
                  <input
                     type="file"
                     accept=".csv,.vcf"
                     style={{ display: 'none' }}
                     onChange={handleInputChange}
                  />
               </CustomFileInput>
            )}
            <Typography
               sx={{
                  marginBottom: '1rem',
                  textAlign: 'right',
                  fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                  color: '#374DA0',
                  fontWeight: '600',
                  cursor: 'pointer',
               }}
               onClick={() => {
                  reset();
                  setDialogState(false);
                  if (value === 'Import VCF' && selector.vcf.length > 0) {
                     navigate({
                        pathname: vcfText,
                        search: createSearchParams({
                           groupId,
                        }).toString(),
                     });
                  } else if (value === 'Contact') {
                     navigate(contactText);
                  } else if (value === 'CopyPaste') {
                     navigate({
                        pathname: copyPasteText,
                        search: createSearchParams({
                           groupId,
                        }).toString(),
                     });
                  } else if (
                     value === 'Import VCF' &&
                     selector.vcf.length === 0
                  ) {
                     toast.error('Please select file');
                  }
               }}
            >
               continue
            </Typography>
         </Box>
      </Card>
   );
};

export default AddContactsGroup;
