import styled from '@emotion/styled';
import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import backImg from '../../../assets/back.svg';
import { fetchsenderIDs } from '../../../components/app/features/message/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import msgIcon from '../../../assets/messageIcon3.svg';
import sendMessage from '../../../assets/sendMessage.png';
import { useNavigate } from 'react-router-dom';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #E8E8E8;
 `
);

const SenderIdHistory = () => {
   const [newData, setNewData] = React.useState([]);

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const selector = useSelector(messageSelector);

   React.useEffect(() => {
      dispatch(fetchsenderIDs());
      // eslint-disable-next-line
   }, []);

   React.useEffect(() => {
      const groupedData = {};
      selector?.senderIds.forEach((item) => {
         const createdAt = new Date(item.createdAt);
         const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
         }).format(createdAt);

         if (groupedData.hasOwnProperty(formattedDate)) {
            groupedData[formattedDate].push(item);
         } else {
            groupedData[formattedDate] = [item];
         }
      });
      const newArr = [];

      for (const key in groupedData) {
         if (groupedData.hasOwnProperty(key)) {
            const value = groupedData[key];
            newArr.push({
               date: key,
               data: value,
            });
         }
      }
      setNewData(newArr.sort((a, b) => new Date(b.date) - new Date(a.date)));
   }, [selector?.senderIds]);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '1rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '25px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '3rem',
                  }}
               >
                  Sender ID History
               </Typography>
               <Button
                  sx={{
                     display: 'flex',
                     maxWidth: '300px',
                     width: '90%',
                     background: '#D7DBEC',
                     alignItems: 'center',
                     justifyContent: 'center',
                     // border: '1px solid red',
                     margin: '2rem auto',
                     padding: '10px 0',
                     borderRadius: '8px',
                  }}
                  onClick={() => navigate('/send-message/verify')}
               >
                  <img
                     src={sendMessage}
                     style={{ marginRight: '20px', width: '30px' }}
                     alt="icon"
                  />
                  <Typography
                     sx={{
                        fontWeight: 600,
                        fontSize: '1rem',
                     }}
                  >
                     Verify Sender ID
                  </Typography>
               </Button>
               <Box
                  id="scrollableDiv"
                  sx={{
                     marginTop: '1rem',
                     height: '50vh',
                     overflowY: 'auto',
                  }}
               >
                  {newData.map((item, index) => (
                     <Box key={index}>
                        <Typography
                           sx={{
                              color: '#374DA0',

                              fontSize: '1rem',
                              fontWeight: 700,
                              marginTop: '1rem',
                           }}
                        >
                           {item.date}
                        </Typography>

                        <Box
                           sx={{
                              background: '#EFEEF4',
                              padding: '15px',
                              marginTop: '1rem',
                              borderRadius: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '15px',
                           }}
                        >
                           {[...item?.data].map((elem, indx) => (
                              <Box
                                 key={indx}
                                 sx={{
                                    background: '#fff',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                 }}
                              >
                                 <Box
                                    sx={{
                                       width: 'max-content',
                                       height: '50px',
                                       display: 'flex',
                                       alignItems: 'center',
                                    }}
                                 >
                                    <img
                                       style={{
                                          width: '30px',
                                          marginRight: '10px',
                                       }}
                                       src={msgIcon}
                                       alt="messageIcon"
                                    />
                                    <Box>
                                       <Typography
                                          sx={{
                                             color: '#0F0E0E',

                                             fontSize: '0.95rem',
                                             fontWeight: 600,
                                          }}
                                       >
                                          {elem.senderId}
                                       </Typography>
                                    </Box>
                                 </Box>
                                 <Box
                                    sx={{
                                       width: 'max-content',
                                       height: '50px',
                                       display: 'flex',
                                       flexDirection: 'column',
                                       justifyContent: 'center',
                                       alignItems: 'flex-end',
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          color:
                                             elem.status === null ||
                                             elem.status === 'Pending' ||
                                             elem.status === 'processing'
                                                ? '#FFB017'
                                                : elem.status === 'Approved' ||
                                                  elem.status === 'approved'
                                                ? '#0FA958'
                                                : '#FF1818',

                                          fontSize: '0.95rem',
                                          fontWeight: 600,
                                       }}
                                    >
                                       {elem.status === null
                                          ? 'pending'
                                          : elem.status}
                                    </Typography>
                                 </Box>
                              </Box>
                           ))}
                        </Box>
                     </Box>
                  ))}
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default SenderIdHistory;
