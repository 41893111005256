import { Box, Typography } from '@mui/material';
import React from 'react';
// import info from '../../../assets/info.svg';

const SeventhSlide = () => {
   return (
      <Box>
         <Box
            sx={{
               width: '100%',
               padding: '15px ',
               background: '#F7F7F8',
               borderRadius: '15px',
            }}
         >
            <Typography
               sx={{
                  textAlign: 'center',
                  color: '#0F0E0E',
                  fontSize: '1rem',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: 700,
               }}
            >
               Pricing Info
            </Typography>
            <Box
               sx={{
                  // width: '400px',
                  margin: '0.5rem auto 0 auto',
               }}
            >
               <Typography
                  sx={{
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontSize: '1rem',
                     fontWeight: '600',
                  }}
               >
                  Sample cost for 1 page of SMS:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     columnGap: '10px',
                     marginY: '1rem',
                     overflow: 'auto',
                     // border: '1px solid red',
                  }}
               >
                  <Box>
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontSize: '1rem',
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           // marginTop: '10px',
                           fontWeight: 700,
                        }}
                     >
                        Contacts
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginY: '5px',
                        }}
                     >
                        DND
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                        }}
                     >
                        Non-DND
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginTop: '10px',
                        }}
                     >
                        Total:
                     </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontSize: '1rem',
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           // marginTop: '10px',
                           fontWeight: 700,
                        }}
                     >
                        Count
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginY: '5px',
                        }}
                     >
                        75
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                        }}
                     >
                        45
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginTop: '10px',
                        }}
                     >
                        120
                     </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontSize: '1rem',
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           // marginTop: '10px',
                           fontWeight: 700,
                        }}
                     >
                        Price
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginY: '5px',
                           whiteSpace: 'nowrap',
                        }}
                     >
                        1.5 SMS Unit
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           whiteSpace: 'nowrap',
                        }}
                     >
                        1 SMS Unit
                     </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontSize: '1rem',
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           // marginTop: '10px',
                           fontWeight: 700,
                           textAlign: 'center',
                        }}
                     >
                        Charge
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginY: '5px',
                           whiteSpace: 'nowrap',
                        }}
                     >
                        112.5 SMS Unit
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           whiteSpace: 'nowrap',
                        }}
                     >
                        45 SMS Unit
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '0.85rem',
                           fontWeight: '600',
                           marginTop: '10px',
                           whiteSpace: 'nowrap',
                        }}
                     >
                        157.5 SMS Unit
                     </Typography>
                  </Box>
               </Box>
            </Box>
         </Box>
         {/* <Typography
            sx={{
               display: 'flex',
               justifyContent: 'center',
               marginY: '1rem',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               fontSize: '0.9rem',
               fontWeight: '600',
            }}
         >
            <img src={info} alt="info" style={{ marginRight: '15px' }} />
            SMS Unit - Standard Bulk Sms Unit
         </Typography> */}
         <Typography
            sx={{
               textAlign: 'center',
               fontWeight: '700',
               fontSize: '1rem',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               color: '#374DA0',
               cursor: 'pointer',
               marginTop: '2rem',
            }}
         >
            Cost of SMS to 120 contacts: 157.5 SMS Units/page
         </Typography>
      </Box>
   );
};

export default SeventhSlide;
