import React from 'react';
import {
   Box,
   Typography,
   // FormControl,
   Card,
   Dialog,
   // Select,
   MenuItem,
   Menu,
   Checkbox,
   Tooltip,
   IconButton,
} from '@mui/material';
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { Container } from './style';
import backImg from '../../../assets/back.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import msgIcon from '../../../assets/messageIcon3.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import {
   archiveMessages,
   deleteMessage,
   getDraftedMessages,
} from '../../../components/app/features/message/messageSlice';
import { userLSAuth } from '../../../components/app/features/env';
import InfiniteScroll from 'react-infinite-scroll-component';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	border-radius:0;
}
	`
);

const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:700;
	border-radius:0;
	`
);

const Drafts = () => {
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [isDelete, setIsDelete] = React.useState(false);
   const [isArchive, setIsArchive] = React.useState(false);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   // const [search, setSearch] = React.useState('');
   const [newData, setNewData] = React.useState([]);
   const [page, setPage] = React.useState(1);
   const setClose = () => setAnchorEl(false);
   const dispatch = useDispatch();
   const selector = useSelector(messageSelector);
   const navigate = useNavigate();

   const actions = [
      // 'Select Message History',
      'Archive',
      'Delete',
   ];

   // search functionality
   // const handleSearch = (event) => {
   // 	setSearch(event.target.value);
   // };

   React.useEffect(() => {
      dispatch(getDraftedMessages({ page: page }));
      // eslint-disable-next-line
   }, [selector.deleted === true, page]);

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, parseInt(value, 10)]);
      } else {
         setCheckedList(
            checkedList.filter((item) => item !== parseInt(value, 10))
         );
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         newData.forEach((item) => {
            item.data.map((item) => collection.push(item.id));
         });
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const deleteItem = () => {
      dispatch(deleteMessage({ ids: String(checkedList) }));
      newData.map((item) => {
         item.data = item.data.filter((elem) => !checkedList.includes(elem.id));
         return item;
      });
      // dispatch(getDraftedMessages());
      setAction('');
      setIsDelete(false);
      setCheckedList([]);
   };

   React.useEffect(() => {
      const groupedData = {};
      selector?.draftedMessages.forEach((item) => {
         const createdAt = new Date(item.createdAt);
         const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
         }).format(createdAt);

         if (groupedData.hasOwnProperty(formattedDate)) {
            groupedData[formattedDate].push(item);
         } else {
            groupedData[formattedDate] = [item];
         }
      });
      const newArr = [];

      for (const key in groupedData) {
         if (groupedData.hasOwnProperty(key)) {
            const value = groupedData[key];
            newArr.push({
               date: key,
               data: value,
            });
         }
      }
      newArr.sort((a, b) => new Date(b.date) - new Date(a.date));
      setNewData(newArr);
   }, [selector?.draftedMessages]);

   const resetChecked = () => {
      setAction('');
      setCheckedList([]);
   };
   const archiveMsg = () => {
      dispatch(archiveMessages(checkedList));
      dispatch(getDraftedMessages());
      resetChecked();
      setIsArchive(false);
   };

   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
         setPage(page + 1);
      }
   };

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleChange(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>

               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 1.5vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '1rem',
                  }}
               >
                  Drafts
               </Typography>

               {/* {
                  // action === 'Select Message History' ||
                  action === 'Delete' && (
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           position: 'relative',
                           marginTop: '1rem',
                        }}
                     >
                        <Checkbox
                           checked={itemsChecked}
                           onClick={selectItem}
                           {...label}
                        />{' '}
                        Select All
                        <img
                           src={trash}
                           alt="delete"
                           style={{
                              position: 'absolute',
                              right: '60px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                           }}
                           onClick={() => setIsDelete(true)}
                        />
                        <BiArchiveIn
                           color="#000"
                           size={'22px'}
                           style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                           }}
                           onClick={() => setIsArchive(true)}
                        />
                     </Typography>
                  )
               } */}

               {
                  // action === 'Select Message History' ||
                  (action === 'Delete' || action === 'Archive') && (
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           position: 'relative',
                           marginTop: '1rem',
                           // border: '1px solid red',
                        }}
                     >
                        <Checkbox
                           checked={itemsChecked}
                           onClick={selectItem}
                           {...label}
                        />{' '}
                        Select All
                        {
                           // action === 'Select Message History' ||
                           action === 'Delete' && (
                              <Tooltip title="Delete">
                                 <IconButton
                                    sx={{
                                       position: 'absolute',
                                       right:
                                          action === 'Delete' ? '80px' : '70px',
                                       top: '50%',
                                       transform: 'translateY(-50%)',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                       checkedList.length > 0 &&
                                       setIsDelete(true)
                                    }
                                 >
                                    <DeleteIcon />
                                 </IconButton>
                              </Tooltip>
                           )
                        }
                        {
                           // action === 'Select Message History' ||
                           action === 'Archive' && (
                              <Tooltip title="Archive">
                                 <IconButton
                                    sx={{
                                       position: 'absolute',
                                       right: '80px',
                                       top: '50%',
                                       transform: 'translateY(-50%)',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                       checkedList.length > 0 &&
                                       setIsArchive(true)
                                    }
                                 >
                                    <ArchiveIcon />
                                 </IconButton>
                              </Tooltip>
                           )
                        }
                        <Tooltip title="Cancel">
                           <IconButton
                              sx={{
                                 position: 'absolute',
                                 right: '20px',
                                 top: '50%',
                                 transform: 'translateY(-50%)',
                                 cursor: 'pointer',
                              }}
                              onClick={() => {
                                 resetChecked();
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Tooltip>
                     </Typography>
                  )
               }
               <Box
                  id="scrollableDiv"
                  sx={{
                     marginTop: '1rem',
                     height: '60vh',
                     overflowY: 'auto',
                     // border: '1px solid red',
                  }}
               >
                  {newData.length === 0 && (
                     <Typography
                        sx={{
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           textAlign: 'center',
                           mt: 4,
                        }}
                     >
                        {`Hey ${
                           userLSAuth()?.fullName
                              ? userLSAuth()?.fullName
                              : 'pal'
                        }, your latest Drafts will appear here!`}
                     </Typography>
                  )}

                  {newData.map((item, index) => (
                     <Box key={index}>
                        <Typography
                           sx={{
                              color: '#374DA0',
                              fontWeight: '600',
                              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              marginTop: '1rem',
                           }}
                        >
                           {item.date}
                        </Typography>
                        <Box
                           sx={{
                              background: '#EFEEF4',
                              padding: '15px',
                              marginTop: '1rem',
                              borderRadius: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '15px',
                           }}
                        >
                           {[...item?.data].reverse().map((elem, indx) => (
                              <Box
                                 key={indx}
                                 sx={{
                                    background: '#fff',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                 }}
                              >
                                 <Box
                                    sx={{
                                       width: 'max-content',
                                       height: '50px',
                                       display: 'flex',
                                       alignItems: 'center',
                                    }}
                                 >
                                    {
                                       // action === 'Select Message History' ||
                                       action === 'Archive' ||
                                       action === 'Delete' ? (
                                          <Checkbox
                                             value={elem.id}
                                             checked={checkedList.includes(
                                                elem.id
                                             )}
                                             onClick={handleCheckboxClick}
                                             {...label}
                                          />
                                       ) : (
                                          <img
                                             style={{
                                                width: '30px',
                                                marginRight: '10px',
                                             }}
                                             src={msgIcon}
                                             alt="messageIcon"
                                          />
                                       )
                                    }

                                    <Box>
                                       <Typography
                                          sx={{
                                             color: '#0F0E0E',
                                             fontSize:
                                                'clamp(0.85rem, 1.5vw, 0.9rem)',
                                             fontWeight: 600,
                                             cursor: 'pointer',
                                             ':hover': {
                                                textDecoration: 'underline',
                                             },
                                          }}
                                          onClick={() =>
                                             navigate(
                                                `/send-message/drafts-edit/${elem.id}`
                                             )
                                          }
                                       >
                                          {`${elem.content.slice(0, 9)}...`}
                                       </Typography>
                                       <Typography
                                          sx={{
                                             color: '#5F5E5E',
                                             fontSize:
                                                'clamp(0.8rem, 1.5vw, 0.85rem)',
                                             fontWeight: 600,
                                          }}
                                       >
                                          {`${
                                             new Date(
                                                elem.createdAt
                                             ).getHours() % 12 || 12
                                          }:${new Date(elem.createdAt)
                                             .getMinutes()
                                             .toString()
                                             .padStart(2, '0')} ${
                                             new Date(
                                                elem.createdAt
                                             ).getHours() >= 12
                                                ? 'PM'
                                                : 'AM'
                                          }`}
                                       </Typography>
                                    </Box>
                                 </Box>
                                 <Box
                                    sx={{
                                       width: 'max-content',
                                       height: '50px',
                                       display: 'flex',
                                       flexDirection: 'column',
                                       justifyContent: 'center',
                                       alignItems: 'flex-end',
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          color: '#0F0E0E',
                                          fontSize:
                                             'clamp(0.85rem, 1.5vw, 0.9rem)',
                                          fontWeight: 600,
                                       }}
                                    >
                                       {`${elem?.contactCount} contacts`}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          color:
                                             elem.status === null ||
                                             elem.status === 'pending'
                                                ? '#FFB017'
                                                : elem.status === 'Some sent'
                                                ? '#0FA958'
                                                : '#FF1818',
                                          fontSize:
                                             'clamp(0.8rem, 1.5vw, 0.85rem)',
                                          fontWeight: 600,
                                       }}
                                    >
                                       {elem.status === null
                                          ? 'Pending'
                                          : elem.status}
                                    </Typography>
                                 </Box>
                              </Box>
                           ))}
                        </Box>
                     </Box>
                  ))}
                  <InfiniteScroll
                     dataLength={selector.draftedMessages.length}
                     next={loadmore}
                     hasMore={selector.hasMore}
                     loader={
                        <h4 style={{ textAlign: 'center', margin: '15px 0' }}>
                           Loading...
                        </h4>
                     }
                     scrollableTarget="scrollableDiv"
                     endMessage={
                        <p style={{ textAlign: 'center', margin: '15px 0' }}>
                           Yay! You have seen it all
                        </p>
                     }
                  />
               </Box>
            </Box>
            <Dialog
               onClose={() => {
                  setIsDelete(false);
                  setAction('');
               }}
               open={isDelete}
            >
               <Card sx={{ padding: '20px 30px ' }}>
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        textAlign: 'center',
                        marginBottom: '2rem',
                     }}
                  >
                     Are you sure you want to delete the selected messages?
                  </Typography>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           cursor: 'pointer',
                           marginRight: ' 1rem',
                        }}
                        onClick={() => {
                           setIsDelete(false);
                           setAction('');
                        }}
                     >
                        Cancel
                     </Typography>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: 'red',
                           cursor: 'pointer',
                        }}
                        onClick={() => deleteItem()}
                     >
                        Yes
                     </Typography>
                  </Box>
               </Card>
            </Dialog>
            <Dialog
               onClose={() => {
                  setIsArchive(false);
                  setAction('');
                  setCheckedList([]);
               }}
               open={isArchive}
            >
               <Card sx={{ padding: '20px 30px' }}>
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        textAlign: 'center',
                        marginBottom: '2rem',
                     }}
                  >
                     Archive selected messages?
                  </Typography>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           cursor: 'pointer',
                           marginRight: ' 1rem',
                        }}
                        onClick={() => {
                           setIsArchive(false);
                           resetChecked();
                        }}
                     >
                        Cancel
                     </Typography>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: 'red',
                           cursor: 'pointer',
                        }}
                        onClick={() => archiveMsg()}
                     >
                        Yes
                     </Typography>
                  </Box>
               </Card>
            </Dialog>
         </Card>
      </Container>
   );
};

export default Drafts;
