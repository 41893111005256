import { styled, Box, Button } from '@mui/material';

export const DashboardWrapper = styled(Box)(
   ({ theme }) => `
background: #EFEEF4;  
min-height: 100vh; 
// padding: 78px;  
position: relative;
border: 1px solid transparent;
padding-top: 78px;
.topUpWrapper{
  width: 70%; 
margin:  auto;
position:relative;

}
.profileCard{
background: linear-gradient(90deg, #374DA0 0%, rgba(55, 77, 160, 0.7) 71.67%);
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
border-radius: 12px;
margin: auto;
color: ${theme.sidebar.color};
}

.sadIllustrator{
width: 100%;
height: 100%;
}
// mediaquery
       @media (max-width: 1280px) {
            .topUpWrapper{
              width: 80%;
           
            }
        }
    
       @media (max-width: 866px) {
            .topUpWrapper{
              width: 90%;
            
            }
        }
       @media (max-width: 790px) {
            padding: 78px 0;
        }
`
);

export const TopupButton = styled(Button)(
   ({ theme }) => `
  background: #D7DBEC;
  border-radius: 4px;
  padding: 8px 60px;
  margin-top: 30px;
  font-size: 14px;  
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  margin: auto;
  margin-top: 25px;
  
`
);
