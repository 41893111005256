import { Box, Typography } from '@mui/material';
import React from 'react';
import FourthSlideImg from '../../../assets/dnd.svg';

const FourthSlide = () => {
   return (
      <Box>
         <Box sx={{ width: '100%', height: '180px' }}>
            <img
               src={FourthSlideImg}
               style={{ width: '100%', height: '100%' }}
               alt="icon"
            />
         </Box>
         <Typography
            sx={{
               textAlign: 'center',
               color: '#0F0E0E',
               fontSize: '1rem',
               mt: '1rem',
            }}
         >
            DND numbers are charged at 1.5 SMS Unit/page.
         </Typography>
      </Box>
   );
};

export default FourthSlide;
