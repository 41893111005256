import React, { useState } from 'react';

const DiscountBanner = () => {
   const [showBanner, setShowBanner] = useState(true);

   const handleBannerClose = () => {
      setShowBanner(false);
   };

   return (
      showBanner && (
         <div className="banner">
            <span className="banner-message">
               30% discount on your first purchase. Use promo code <b>PAR24</b>
            </span>
            <button id="close-banner" onClick={handleBannerClose}>
               X
            </button>
         </div>
      )
   );
};

export default DiscountBanner;
