import { Box, Typography } from '@mui/material';
import React from 'react';

const EightSlide = () => {
   return (
      <Box>
         <ol>
            <li>
               <Typography
                  sx={{
                     fontSize: '1rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontWeight: 600,
                     mb: '1rem',
                  }}
               >
                  Recipient numbers are categorised as Do Not Disturb (DND)
                  Non-Do Not Disturb (Non-DND).
               </Typography>
            </li>
            <li>
               <Typography
                  sx={{
                     fontSize: '1rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontWeight: 600,
                     mb: '1rem',
                  }}
               >
                  Messages are charged per page, per number.
               </Typography>
            </li>
            <li>
               <Typography
                  sx={{
                     fontSize: '1rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontWeight: 600,
                     mb: '1rem',
                  }}
               >
                  DND numbers are charged at 1.5 SMS Unit/page.
               </Typography>
            </li>
            <li
               sx={{
                  fontSize: '1rem',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  fontWeight: 600,
                  mb: '1rem',
                  // color: '#ooo',
               }}
            >
               <Typography
                  sx={{
                     fontSize: '1rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontWeight: 600,
                     // color: '#ooo',
                     // mb: '1rem',
                  }}
               >
                  Non-DND numbers are charged at 1 SMS Unit/page.
               </Typography>
            </li>
         </ol>
      </Box>
   );
};

export default EightSlide;
