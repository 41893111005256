import React, { useState } from 'react';
import { Box, Typography, Card, Divider } from '@mui/material';
import { DashboardWrapper, TopupButton } from './TopUpStyle';
import TopIcon from '../../assets/TopupIcon.svg';
import noTransIcon from '../../assets/noTrans.svg';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import WorkHistorySharpIcon from '@mui/icons-material/WorkHistorySharp';
import CurrencyExchangeSharpIcon from '@mui/icons-material/CurrencyExchangeSharp';
import PreferenceLink from '../../components/preferenceLink/PreferenceLink';
import LastTransaction from '../../components/lastTransaction/LastTransaction';
import TopUpForm from '../../components/forms/topUpForm/topUpForm';
import { useNavigate } from 'react-router-dom';
import {
   getAuthSelector,
   getTransactions,
} from '../../components/utils/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import TransactionLoading from '../../components/mui_skeleton/LastTransactionSkeleton';
import { getLastFour } from '../../components/app/features/transactions/TransactionSlice';
import { getUserProfile } from '../../components/app/features/auth/AuthSlice';
import { userLSAuth } from '../../components/app/features/env';

const TopUp = () => {
   const [isShowPwd, setIsShowPwd] = useState(true);
   const [open, setOpen] = useState(false);
   const [balance, setBalance] = React.useState(userLSAuth()?.balance);

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const authselector = useSelector(getAuthSelector);
   const selector = useSelector(getTransactions);

   const currentDate = new Date();
   const today = `${
      currentDate.getMonth() + 1
   }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

   const oneYearAgo = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
   );

   const lastYearString = oneYearAgo.toISOString();

   const time = {
      start: lastYearString,
      end: new Date().toISOString(),
   };

   React.useEffect(() => {
      dispatch(getUserProfile());

      dispatch(
         getLastFour({
            userId: userLSAuth()?.id,
            timeFrame: time,
            page: 1,
         })
      );
   }, []);

   React.useEffect(() => {
      if (authselector.updatedProfile !== null) {
         setBalance(
            authselector.updatedProfile.balance !== null
               ? authselector.updatedProfile.balance
               : 0
         );
      }
   }, [authselector.updated === true]);

   const toggleBalance = () => {
      // inverse the boolean state of isShowPwd
      setIsShowPwd(!isShowPwd);
   };

   const handleCloseForm = () => {
      setOpen(false);
   };

   return (
      <DashboardWrapper>
         {open && <TopUpForm openForm={open} closeForm={handleCloseForm} />}
         <Box
            sx={{
               marginTop: '2rem',
               position: 'relative',
            }}
         >
            <Box className="topUpWrapper">
               <Box className="profileCard" color="white">
                  <Box
                     display="flex"
                     p={3}
                     textAlign="center"
                     alignItems="center"
                  >
                     <Box height={50} width={50} borderRadius={50}>
                        <img
                           src={userLSAuth()?.displayPicture}
                           height="100%"
                           width="100%"
                           style={{ borderRadius: '50%' }}
                           alt="Profile"
                        />
                     </Box>
                     <Typography flex={1} fontSize={25}>
                        Account Balance
                     </Typography>
                  </Box>
                  <Box
                     display="flex"
                     flexDirection="column"
                     textAlign="center"
                     m="auto"
                  >
                     <Typography
                        sx={{
                           fontSize: '1.8rem',
                           fontWeight: 600,
                        }}
                     >
                        {isShowPwd ? balance || userLSAuth().balance : '*****'}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: '1.2rem',
                        }}
                     >
                        SMS Units
                     </Typography>
                  </Box>
                  <Box
                     className="passwordIcon"
                     align="right"
                     px={2}
                     fontSize={22}
                  >
                     {isShowPwd ? (
                        <VscEye onClick={toggleBalance} />
                     ) : (
                        <VscEyeClosed onClick={toggleBalance} />
                     )}
                  </Box>
               </Box>
               <TopupButton onClick={() => setOpen(true)}>
                  <Box width={25} height={25}>
                     <img
                        src={TopIcon}
                        alt="TopIcon"
                        style={{ marginRight: '15px' }}
                     />
                  </Box>
                  <Typography
                     ml={1}
                     color="#0F0E0E"
                     fontWeight={500}
                     fontSize={16}
                  >
                     Top-Up
                  </Typography>
               </TopupButton>

               {selector.lastFourpaymentData.length > 0 ? (
                  <Typography
                     color="#0F0E0E"
                     fontSize={18}
                     fontWeight={600}
                     mt={6}
                     mb={2}
                     sx={{ color: '#0F0E0E' }}
                  >
                     Last transactions
                  </Typography>
               ) : selector.lastFourpaymentData.length === 0 &&
                 selector.loading !== 'pending' ? (
                  <Box>
                     <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                        <img src={noTransIcon} alt="icon" />
                     </Box>
                     <Typography
                        sx={{
                           fontWeight: 600,
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: '#0F0E0E',
                           marginTop: '1rem',
                           marginBottom: '1rem',
                           textAlign: 'center',
                        }}
                     >{`Hey ${
                        userLSAuth()?.fullName
                     }  your latest transactions will appear here`}</Typography>
                  </Box>
               ) : null}

               {selector.lastFourpaymentData.length > 0 ? (
                  <Box
                     display={'flex'}
                     gap={2}
                     sx={{
                        '@media (max-width: 730px )': {
                           flexWrap: 'wrap',
                        },

                        '@media (max-width: 606px )': {
                           justifyContent: 'center',
                        },
                     }}
                  >
                     {selector.lastFourpaymentData.map((item, index) => (
                        <LastTransaction item={item} key={index} />
                     ))}
                  </Box>
               ) : selector.loading === 'pending' ? (
                  <Box
                     display={'flex'}
                     gap={2}
                     sx={{
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: '2rem',
                     }}
                  >
                     <TransactionLoading />
                     <TransactionLoading />
                  </Box>
               ) : null}

               <Typography
                  color="#0F0E0E"
                  fontSize={18}
                  fontWeight={600}
                  my={1}
                  mt={3}
               >
                  Preferences
               </Typography>
               <Card
                  sx={{
                     paddingX: '20px',
                  }}
               >
                  <PreferenceLink
                     iconButton={<WorkHistorySharpIcon />}
                     preferenceText="Payment history"
                     handleClick={() => navigate('/top-up/transactions')}
                  />
                  <Divider />
                  <PreferenceLink
                     iconButton={<CurrencyExchangeSharpIcon />}
                     preferenceText="Pricing information"
                     handleClick={() => navigate('/top-up/pricing-info')}
                  />
               </Card>
            </Box>
         </Box>
      </DashboardWrapper>
   );
};

export default TopUp;
