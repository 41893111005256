import React from 'react';
import Bro from '../../assets/bro.svg';
import Logo from '../../assets/ParroticaLogo.svg';
import './Home.css';
import {
	Box,
	// Typography,
	Card,
	styled,
	// CardMedia,
	Button,
} from '@mui/material';
import { Link } from 'react-router-dom';

const SuspendButton = styled(Button)(
	({ theme }) => `
  border-radius: 10px;
  padding: 3px 16px;
  font-size: 0.8125rem;
  background: transparent;
  border: 1px solid #374DA0;
  color: #374DA0;
  width: 35%;
  margin: 0 auto;
  margin-top: 20px;

  &:hover {
  background: #F7F7F8;
  }
  `
);

const Home = () => {
	return (
		<Box height='100vh' display='flex' className='HomeWrapper'>
			<Box
				height='100%'
				className='pageWrapper'
				sx={{
					backgroundColor: '#374DA0',
				}}>
				<img src={Bro} alt='Onboarding' className='signupCover' />
			</Box>
			<Box
				display='flex'
				flexDirection='column'
				pt={1.5}
				textAlign='center'
				className='pageBox'>
				<Box
					sx={{
						backgroundColor: '#374DA0',
						height: '120px',
						width: '120px',
						borderRadius: '50%',
						marginX: 'auto',
						marginTop: '50px',
					}}>
					<Box
						sx={{
							width: '100px',
							margin: 'auto',
							marginTop: '40%',
							px: '7px',
						}}>
						<img
							src={Logo}
							alt='logo'
							style={{
								width: '100%',
							}}
						/>
					</Box>
				</Box>
				<div className='wrapper'>
					<div className='typing-demo'>The fastest way to text</div>
				</div>
				<p className='pageDescription'>
					Send bulk SMS with ease using groups and a simple pricing system
				</p>
				<Link to='/login' className='LinkLogin'>
					<Button
						sx={{
							borderRadius: '11px',
							py: '3px',
							px: '15px',
							fontSize: '15px',

							width: '35%',
							mx: 'auto',
							mt: '50px',
							fontFamily: "'Raleway', 'Montserrat', sans-serif",
						}}
						// onClick={handleCreateUserOpen}
						variant='contained'
						size='small'>
						Log in
					</Button>
				</Link>

				<Link to='/signup' className='pageLink'>
					<SuspendButton
						sx={{
							fontFamily: "'Raleway', 'Montserrat', sans-serif",
							fontSize: '15px',
						}}>
						Create Account
					</SuspendButton>
				</Link>
				<p className='privacyLink'>
					Learn more about our
					<Link className='privacyPolicy'>Privacy Policy</Link>
				</p>
			</Box>
		</Box>
	);
};

export default Home;
