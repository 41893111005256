import { Box, Card, Dialog, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Container, FlatButton } from './style';
import backImg from '../../../assets/back.svg';
import groupImg from '../../../assets/group.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import message from '../../../assets/message2.svg';
import group from '../../../assets/FAB2.svg';
import phoneImg from '../../../assets/phoneImg.svg';
import contactImg from '../../../assets/contactImg.svg';
import cost from '../../../assets/cost.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
   contactSummary,
   deleteContact,
   getUser,
} from '../../../components/app/features/contacts/contactSlice';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import EditContact from './EditContact';
import { addSelectedContacts } from '../../../components/app/features/message/messageSlice';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;
& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}
	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
   box-sizing: border-box;
   white-space: nowrap;
	width: 160px;
	font-weight:600;
	border-radius:0;
	`
);

const User = () => {
   const actions = ['Send Message', 'Edit', 'Delete'];
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [editOpen, setEditOpen] = React.useState(false);
   const [isDelete, setIsDelete] = React.useState(false);

   const navigate = useNavigate();
   const dispatch = useDispatch();

   const setClose = () => setAnchorEl(false);
   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };
   const params = useParams();
   const selector = useSelector(contactSelector);

   const close = (value) => {
      setEditOpen(value);
      setAction('');
   };

   const handleClick = () => {
      dispatch(addSelectedContacts([selector.user]));
      navigate('/send-message/message');
   };

   React.useEffect(() => {
      dispatch(
         getUser({
            contactId: params.id,
         })
      );
   }, [params.id]);

   React.useEffect(() => {
      dispatch(
         contactSummary({
            userId: selector?.admin?.id,
         })
      );
   }, []);

   // edit/delete contact
   React.useEffect(() => {
      if (action === 'Edit') {
         setEditOpen(true);
      } else if (action === 'Delete') {
         setIsDelete(true);
      } else if (action === 'Send Message') {
         handleClick();
      }
   }, [action]);

   const deleteUser = () => {
      dispatch(
         deleteContact({
            contactId: params.id,
            userId: selector?.admin?.id,
         })
      );
      navigate('/contacts/all-contacts');
   };

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <Dialog
               onClose={() => {
                  setEditOpen(false);
                  setAction('');
               }}
               open={editOpen}
            >
               {
                  <EditContact
                     name={selector.user.fullName && selector.user.fullName}
                     number={selector.user.number && selector.user.number}
                     id={selector.user.id && selector.user.id}
                     close={close}
                  />
               }
            </Dialog>
            <Dialog
               onClose={() => {
                  setIsDelete(false);
                  setAction('');
               }}
               open={isDelete}
            >
               <Card sx={{ padding: '20px 30px ' }}>
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '700',
                        fontSize: '1.1rem',
                        textAlign: 'center',
                        marginBottom: '2rem',
                     }}
                  >
                     Are you sure you want to delete this contact?
                  </Typography>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontWeight: '700',
                           fontSize: '1.1rem',
                           cursor: 'pointer',
                           marginRight: ' 1rem',
                        }}
                        onClick={() => {
                           setIsDelete(false);
                           setAction('');
                        }}
                     >
                        cancel
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontWeight: '700',
                           fontSize: '1.1rem',
                           color: 'red',
                           cursor: 'pointer',
                        }}
                        onClick={() => deleteUser()}
                     >
                        ok
                     </Typography>
                  </Box>
               </Card>
            </Dialog>
            <Box
               sx={{
                  margin: '1rem auto',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleChange(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>
               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     cursor: 'pointer',
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                  }}
               >
                  <Typography
                     sx={{
                        fontWeight: 700,
                        fontSize: 'clamp(1.5rem, 2.5vw, 1.6rem)',
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: ' #D7DBEC',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#374DA0',
                     }}
                  >
                     {selector.user.fullName
                        ? selector.user.fullName[0]?.toUpperCase()
                        : ''}
                  </Typography>
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     marginTop: '2.5rem',
                     justifyContent: 'space-between',
                     '@media screen and (max-width:470px)': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        rowGap: '10px',
                     },
                  }}
               >
                  <FlatButton variant="outlined" onClick={() => handleClick()}>
                     <img
                        src={message}
                        alt="message"
                        style={{
                           width: '30px',
                           marginRight: '15px',
                        }}
                     />
                     Send message
                  </FlatButton>
                  <FlatButton
                     variant="outlined"
                     onClick={() => navigate('/contacts/select-group')}
                  >
                     <img
                        src={group}
                        alt="message"
                        style={{
                           marginRight: '15px',
                           width: '30px',
                        }}
                     />
                     Add to group
                  </FlatButton>
               </Box>
               <Box
                  sx={{
                     width: '100%',
                     marginTop: '2rem',
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: '10px',
                  }}
               >
                  <Box
                     sx={{
                        backgroundColor: '#fff',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        padding: '15px ',
                     }}
                  >
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           marginBottom: '10px',
                        }}
                     >
                        {' '}
                        <img
                           src={contactImg}
                           alt="message"
                           style={{
                              marginRight: '15px',
                           }}
                        />
                        {selector.user.fullName
                           ? selector.user.fullName
                           : 'loading...'}
                     </Typography>
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        {' '}
                        <img
                           src={phoneImg}
                           alt="message"
                           style={{
                              marginRight: '15px',
                           }}
                        />
                        {selector.user.number
                           ? selector.user.number
                           : 'loading...'}
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        backgroundColor: '#fff',
                        padding: '15px ',
                     }}
                  >
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           marginBottom: '10px',
                        }}
                     >
                        {' '}
                        <img
                           src={contactImg}
                           alt="message"
                           style={{
                              marginRight: '15px',
                           }}
                        />
                        {selector.user.isDND && selector.user.isDND === true
                           ? 'DND number'
                           : selector?.user.isDND === false
                           ? 'Non-DND number'
                           : 'loading...'}
                     </Typography>
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        {' '}
                        <img
                           src={cost}
                           alt="message"
                           style={{
                              marginRight: '15px',
                           }}
                        />
                        {selector.user.cost
                           ? `Cost of SMS to number: ${selector.user.cost} SMS Unit/page `
                           : 'loading...'}
                     </Typography>
                  </Box>

                  <Box
                     sx={{
                        backgroundColor: '#fff',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        padding: '15px ',
                     }}
                  >
                     <Typography
                        sx={{
                           fontWeight: 600,
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: '#0B0F20',
                           textAlign: 'center',
                           mb: '0.5rem',
                        }}
                     >
                        Groups
                     </Typography>

                     <Box
                        sx={{
                           display: 'flex',
                           columnGap: '3%',
                           rowGap: '10px',
                           '@media (max-width:640px)': {
                              marginTop: '1rem',
                              flexDirection: 'column',
                           },
                        }}
                     >
                        {selector.user.groups &&
                        selector.user.groups.length > 0 ? (
                           [...selector?.user.groups.slice(0, 3)].map(
                              (group) => (
                                 <Box
                                    sx={{
                                       width: '30%',
                                       padding: '12px',
                                       borderRadius: '10px',
                                       display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'center',
                                       backgroundColor: '#D7DBEC',
                                       cursor: 'pointer',
                                       '@media (max-width:640px)': {
                                          width: '100%',
                                       },
                                       ':hover': {
                                          outline: '1px solid #374da0',
                                       },
                                    }}
                                    key={group.id}
                                    onClick={() =>
                                       navigate(`/contacts/group/${group.id}`)
                                    }
                                 >
                                    <img src={groupImg} alt="group" />
                                    <Typography
                                       sx={{
                                          marginTop: '1rem',
                                          fontSize: '0.85rem',
                                          fontWeight: 600,
                                          textAlign: 'center',
                                          color: '#0B0F20',
                                       }}
                                    >
                                       {group.title.length > 20
                                          ? `${group.title.substring(0, 15)}...`
                                          : group.title}
                                    </Typography>
                                 </Box>
                              )
                           )
                        ) : (
                           <Typography
                              sx={{
                                 mx: 'auto',
                                 mb: 2,
                                 fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                 color: '#0B0F20',
                                 textAlign: 'center',
                              }}
                           >
                              The Groups that this contact belongs to will be
                              displayed here
                           </Typography>
                        )}
                     </Box>
                  </Box>
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default User;
