import React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Card, Typography } from '@mui/material';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';

const ScheduleDate = ({ getTime, closePeriod }) => {
   const currentDate = new Date();
   const currentTime = currentDate.getTime();
   const [date, setDate] = React.useState(dayjs(currentDate));
   const [time, setTime] = React.useState(dayjs(currentTime));

   const combineDateTime = () => {
      const datetime = new Date(
         date.toDate().getFullYear(),
         date.toDate().getMonth(),
         date.toDate().getDate(),
         time.toDate().getHours(),
         time.toDate().getMinutes(),
         time.toDate().getSeconds()
      );

      return datetime.toISOString();
   };

   return (
      <Card sx={{ minHeight: '200px', padding: '1.5rem 2rem' }}>
         <Typography
            sx={{
               fontWeight: 600,
               textAlign: 'center',

               marginBottom: '0.6rem',
               fontSize: '1rem',
            }}
         >
            Schedule Message
         </Typography>
         <Typography
            sx={{
               textAlign: 'center',

               fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
               marginBottom: '2rem',
            }}
         >
            To send, keep phone on and connected to Wi-Fi or data{' '}
         </Typography>
         <Box>
            <Typography
               sx={{
                  fontSize: '1rem',
               }}
            >
               Select Date
            </Typography>
            <LocalizationProvider
               sx={{
                  // width: '100%',
                  '@media (min-width: 1280px)': {
                     flexDirection: 'column',
                  },
               }}
               dateAdapter={AdapterDayjs}
            >
               <DemoContainer
                  sx={{
                     overflow: 'unset',
                     '@media (min-width: 1280px)': {
                        flexDirection: 'column',
                     },
                  }}
                  components={['MobileTimePicker']}
               >
                  <DemoItem>
                     <DatePicker
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        adapterLocale={'en-gb'}
                     />
                  </DemoItem>
               </DemoContainer>
            </LocalizationProvider>
         </Box>
         <Box sx={{ marginTop: '1.5rem' }}>
            <Typography
               sx={{
                  fontSize: '1rem',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
               }}
            >
               Select Time
            </Typography>
            <LocalizationProvider
               sx={{
                  // width: '100%',
                  '@media (min-width: 1280px)': {
                     flexDirection: 'column',
                  },
               }}
               dateAdapter={AdapterDayjs}
            >
               <DemoContainer
                  sx={{
                     overflow: 'unset',
                     '@media (min-width: 1280px)': {
                        flexDirection: 'column',
                     },
                  }}
                  components={['MobileTimePicker']}
               >
                  <DemoItem>
                     <MobileTimePicker
                        value={time}
                        onChange={(newValue) => setTime(newValue)}
                        adapterLocale={'en-gb'}
                     />
                  </DemoItem>
               </DemoContainer>
            </LocalizationProvider>
         </Box>
         <Box
            sx={{
               marginTop: '5rem',
               display: 'flex',
               justifyContent: 'flex-end',
            }}
         >
            <Typography
               sx={{
                  fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  fontWeight: 600,
                  color: 'red',
                  marginRight: '20px',
                  cursor: 'pointer',
               }}
               onClick={() => closePeriod()}
            >
               Cancel
            </Typography>
            <Typography
               sx={{
                  fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  fontWeight: 600,
                  color: '#374d0',
                  cursor: 'pointer',
               }}
               onClick={() => getTime(combineDateTime())}
            >
               Ok
            </Typography>
         </Box>
      </Card>
   );
};

export default ScheduleDate;
