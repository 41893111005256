import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
   getRangedUserTransactions,
   // getUserTransactions,
   setTimeFrame,
} from '../app/features/transactions/TransactionSlice';

const FetchButton = styled(Button)`
   padding: 14px 0;
   font-size: clamp(0.9rem, 1.5vw, 1rem);
   background-color: #374da0;
   color: #fff;
   width: 20%;
   height: max-content;
   align-self: flex-end;
   &:hover {
      background-color: #374da0;
   }
   @media (max-width: 710px) {
      width: 100%;
      margin-top: 2rem;
   }
   @media (max-width: 570px) {
      width: 100%;
   }
`;

export default function DateItem({ createFilter, restartPagination }) {
   const dispatch = useDispatch();
   const currentDate = new Date();
   const oneYearAgo = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
   );
   // const lastYearString = oneYearAgo.toISOString();
   const [selectDate, setSelectedDate] = React.useState(dayjs(oneYearAgo));
   const [selectDateX, setSelectedDateX] = React.useState(dayjs(new Date()));
   const [page] = React.useState(1);

   const frame = {
      start: selectDate.toISOString(),
      end: selectDateX.toISOString(),
   };

   const handleClick = () => {
      createFilter();
      restartPagination();
      const startDateString = frame.start;
      const endDateString = frame.end;

      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      // Compare the date portions of the two dates
      const isSameDay =
         startDate.toISOString().slice(0, 10) ===
         endDate.toISOString().slice(0, 10);

      // Check if start date is later than end date
      const isStartDateLater = startDate > endDate;

      // Check if both dates are invalid (i.e., both in dates yet to come)
      const currentDate = new Date();
      const isBothInvalid = startDate > currentDate && endDate > currentDate;

      if (isSameDay) {
         toast.warning('choose a date range of atleast 24hrs');
      } else if (isStartDateLater) {
         toast.error('choose a valid date');
      } else if (isBothInvalid) {
         toast.error('choose a valid date');
      } else {
         dispatch(setTimeFrame(frame));
         dispatch(
            getRangedUserTransactions({
               timeFrame: frame,
               page: page,
            })
         );
         // toast.info('fetching data...');
      }
   };

   return (
      <>
         <Box
            sx={{
               width: '70%',
               display: 'flex',
               rowGap: '10px',
               columnGap: '10px',
               marginX: 'auto',
               justifyContent: 'space-between',
               flexWrap: 'wrap',
               // border: '1px solid red',
               '@media(max-width: 1280px)': {
                  width: '80%',
               },
               // '@media(max-width:968px)': {
               //    width: '80%',
               // },
               '@media(max-width:866px)': {
                  width: '90%',
               },
            }}
         >
            <Box
               sx={{
                  '& .css-ztwhay-MuiStack-root': { paddingY: '0' },
                  width: '36%',
                  // border: '1px solid red',
                  '@media(max-width:710px)': {
                     width: '45%',
                  },
                  '@media(max-width:570px)': {
                     width: '100%',
                  },
               }}
            >
               <Typography variant="h6">Start Date</Typography>
               <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'en-gb'}
               >
                  <DemoContainer
                     sx={{
                        marginY: '0 !important',
                     }}
                     components={['DatePicker']}
                  >
                     <DatePicker
                        value={selectDate}
                        onChange={(newValue) =>
                           setSelectedDate(dayjs(newValue))
                        }
                        sx={{
                           width: '100%',
                           '& .MuiInputBase-root': {
                              fontSize: '1rem',
                              height: 'unset',
                              borderRadius: '16px',
                              marginY: '0',
                              background: '#fff',
                           },
                        }}
                     />
                  </DemoContainer>
               </LocalizationProvider>
            </Box>
            <Box
               sx={{
                  '& .css-ztwhay-MuiStack-root': { paddingY: '0' },
                  width: '36%',
                  '@media(max-width:710px)': {
                     width: '45%',
                  },
                  '@media(max-width:570px)': {
                     width: '100%',
                  },
               }}
            >
               <Typography variant="h6">End Date</Typography>
               <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'en-gb'}
               >
                  <DemoContainer
                     sx={{
                        marginY: '0 !important',
                     }}
                     components={['DatePicker']}
                  >
                     <DatePicker
                        value={selectDateX}
                        onChange={(newValue) =>
                           setSelectedDateX(dayjs(newValue))
                        }
                        sx={{
                           width: '100%',
                           '& .MuiInputBase-root': {
                              fontSize: '1rem',
                              height: 'unset',
                              borderRadius: '16px',
                              marginY: '0 !important',
                              background: '#fff',
                              // color: '#fff',
                              // borderColor: 'transparent',
                           },
                        }}
                     />
                  </DemoContainer>
               </LocalizationProvider>
            </Box>
            <FetchButton onClick={() => handleClick()}>View</FetchButton>
         </Box>
      </>
   );
}
