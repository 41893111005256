import React from 'react';
import {
   Box,
   // FormControl,
   Typography,
   Button,
   Dialog,
   Card,
} from '@mui/material';
import styled from '@emotion/styled';
import { Container } from './style';
// import { FaSearch } from 'react-icons/fa';
import ViewGroupinfo from '../ViewGroupinfo';
import info from '../../../assets/info.svg';
import addImg from '../../../assets/newAddIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { contactSummary } from '../../app/features/contacts/contactSlice';

import AddContact from '../../../pages/contacts/allContacts/AddContact';

const FlatButton = styled(Button)(
   ({ theme }) => `
background: #D7DBEC;
color: #374DA0;
font-weight: 600;
width: 47%;
text-align: center;
position: relative;
padding:10px 0;
@media(max-width:500px) {
	width: 100%;
}
`
);

const ContactInfo = () => {
   const [isOpen, setIsOpen] = React.useState(false);
   const navigate = useNavigate();
   const handleClose = () => {
      setIsOpen(false);
   };

   // const selector = useSelector(contactSelector);
   const dispatch = useDispatch();

   const [add, setAdd] = React.useState(false);
   const setDialogState = (value) => {
      setAdd(value);
   };

   React.useEffect(() => {
      dispatch(contactSummary());
   }, []);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '0 auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               borderRadius: '25px',
               minHeight: '70vh',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
               }}
            >
               <Typography
                  sx={{
                     textAlign: 'center',
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     fontWeight: '600',
                     margin: '2rem 0',
                  }}
               >
                  Contacts
               </Typography>
               <ViewGroupinfo />
               <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  sx={{
                     marginTop: '3rem',
                     '@media (max-width: 500px)': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        rowGap: '20px',
                     },
                  }}
               >
                  <FlatButton
                     onClick={() => setAdd(true)}
                     sx={{
                        color: '#374DA0',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     }}
                  >
                     <img
                        src={addImg}
                        alt="icon"
                        style={{ marginRight: '10px' }}
                     />
                     Add Contacts
                  </FlatButton>
                  <FlatButton>
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontWeight: '600',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                        onClick={() => navigate('/contacts/classify')}
                     >
                        Classified Contacts
                     </Typography>

                     <img
                        src={info}
                        alt="info"
                        style={{
                           position: 'absolute',
                           top: '50%',
                           right: '15px',
                           transform: 'translateY(-50%)',
                           zIndex: 50,
                        }}
                        onClick={() => setIsOpen(!isOpen)}
                     />
                  </FlatButton>
               </Box>
            </Box>
            <Dialog onClose={handleClose} open={isOpen}>
               <Box
                  sx={{
                     height: 'auto',
                     paddingY: '2rem',
                     paddingX: '5rem',
                  }}
               >
                  <Typography
                     sx={{
                        fontSize: '1.05rem',
                        textAlign: 'center',
                        fontWeight: '600',
                     }}
                  >
                     View your contact list categorised as Do Not Disturb and
                     Non Do Not Disturb.
                  </Typography>
                  <Typography
                     sx={{
                        margin: 'auto',
                        marginTop: '3rem',
                        fontWeight: '600',
                        color: '#374DA0',
                        cursor: 'pointer',
                        fontSize: '1.05rem',
                        width: 'max-content',
                     }}
                     onClick={() => setIsOpen(!isOpen)}
                  >
                     Ok
                  </Typography>
               </Box>
            </Dialog>
            <Dialog onClose={() => setAdd(false)} open={add}>
               <AddContact
                  setDialogState={setDialogState}
                  directText={'/contacts/add-direct'}
                  vcfText={'/contacts/add-vcf'}
                  copyPasteText={'/contacts/add-copypaste'}
               />
            </Dialog>
         </Card>
      </Container>
   );
};

export default ContactInfo;
