import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)(
	({ theme }) => `
background: #E8E8E8;  
min-height: 100vh;  
padding-top:${theme.header.height}; 
padding-bottom:${theme.header.height}; 

`
);

export const Container = styled(Box)(
	({ theme }) => `
// border: 1px solid red;
width: 95%;
max-width: 950px;
min-height: 100vh;
margin: 2rem auto;
`
);
