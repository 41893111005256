import React from 'react';
import {
   Card,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
   Typography,
   Box,
   Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openVcf } from '../../../components/app/features/contacts/contactSlice';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { getBaseUrls } from '../../../components/app/features/env';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomFileInput = styled.label`
   width: 100px;
   display: inline-block;
   padding: 6px 12px;
   font-size: 16px;
   font-weight: 500;
   color: #0f0e0e;
   background-color: #ececec;
   border: 1px solid #a1a1a1;
   border-radius: 4px;
   cursor: pointer;
   text-align: center;
`;

const AddContact = ({ setDialogState, directText, vcfText, copyPasteText }) => {
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   const [value, setValue] = React.useState('CopyPaste');

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   const navigate = useNavigate();

   const handleInputChange = (event) => {
      event.preventDefault();
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('vcf', file);
      dispatch(openVcf(formData.get('vcf')));
   };

   return (
      <Card
         sx={{
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 30px',
         }}
      >
         <Typography
            sx={{
               margin: '1rem 0 0.5rem 0',
               fontSize: '1rem',
               fontWeight: '600',
            }}
         >
            Choose an option
         </Typography>

         <FormControl
            sx={{
               marginBottom: '1rem ',
            }}
         >
            <RadioGroup
               aria-labelledby="demo-radio-buttons-group-label"
               defaultValue="CopyPaste"
               name="radio-buttons-group"
               value={value}
               onChange={handleChange}
            >
               <div className="d-flex">
                  <FormControlLabel
                     value="CopyPaste"
                     control={<Radio />}
                     label="Paste"
                  />
                  <Tooltip
                     title="DND and non-DND contact classification takes a few minutes to complete; initial Non-DND status will update automatically after verification."
                     arrow
                     style={{ padding: '2px', margin: '-5px' }}
                  >
                     <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
               </div>

               <div className="d-flex">
                  <FormControlLabel
                     value="Direct"
                     control={<Radio />}
                     label="Create"
                  />
                  <Tooltip
                     title="DND and non-DND contact classification takes a few minutes to complete; initial Non-DND status will update automatically after verification."
                     arrow
                     style={{ padding: '2px', margin: '-5px' }}
                  >
                     <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
               </div>
               <div className="d-flex">
                  <FormControlLabel
                     value="Import VCF"
                     control={<Radio />}
                     label="Import VCF/CSV"
                  />
                  <Tooltip
                     title="VCF and CSV store contact details like names and emails. Import VCF file directly or download the CSV template, populate, and import. Also note that DND and non-DND classification for all imported contacts takes a few minutes to complete."
                     arrow
                     style={{ padding: '2px', margin: '-5px' }}
                  >
                     <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
               </div>
            </RadioGroup>
         </FormControl>
         <Box
            sx={{
               width: '100%',
               display: 'flex',
               justifyContent: 'space-between',
               columnGap: '15px',
               alignItems: 'center',
            }}
         >
            {value === 'Import VCF' && (
               <a href={`${getBaseUrls().data}/v1/csv-template`}>
                  Download CSV template
               </a>
            )}
            {value === 'Import VCF' && (
               <CustomFileInput>
                  Browse...
                  <input
                     type="file"
                     accept=".csv,.vcf"
                     style={{ display: 'none' }}
                     onChange={handleInputChange}
                  />
               </CustomFileInput>
            )}
            <Typography
               sx={{
                  fontSize: '(0.9rem, 1.5vw, 1rem)',
                  color: '#374DA0',
                  fontWeight: '600',
                  cursor: 'pointer',
               }}
               onClick={() => {
                  if (value === 'Import VCF' && selector.vcf.length > 0) {
                     navigate(vcfText);
                  }
                  if (value === 'Direct') {
                     navigate(directText);
                  }
                  if (value === 'CopyPaste') {
                     navigate(copyPasteText);
                  }
                  if (value === 'Import VCF' && selector.vcf.length === 0) {
                     toast.error('Please select file');
                  }
               }}
            >
               {selector.added === true ||
               value === 'Direct' ||
               value === 'CopyPaste'
                  ? 'Continue'
                  : ''}
            </Typography>
         </Box>
      </Card>
   );
};

export default AddContact;
