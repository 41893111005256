import styled from '@emotion/styled';
import { Box, TextField, Button } from '@mui/material';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
	background:#EFEEF4; 
 min-height: 100vh; 
 `
);
export const TextInput = styled(TextField)(
   ({ theme }) => ` 
 background: #E8E8E8;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: inherit;
color: #374DA0;
font-weight: 600;
width: 47%;
text-align: center;
font-size: 1rem;
position: relative;
border-radius:15px;
@media screen and (max-width:470px){
	width: 90%;
}
`
);
