import styled from '@emotion/styled';
import { Box, Card, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userLSAuth } from '../../../components/app/features/env';
import noTransIcon from '../../../assets/noTrans.svg';

const TextInput = styled(TextField)(
   ({ theme }) => ` 
 background: inherit;
 border-radius: 25px;
 `
);

const DND = ({ data, loadmore }) => {
   const navigate = useNavigate();
   const selector = useSelector(contactSelector);
   const [sorted, setSorted] = React.useState([]);

   // search functionality
   const [search, setSearch] = React.useState('');
   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   React.useEffect(() => {
      if (data.length > 0) {
         setSorted(
            data.sort(function (a, b) {
               if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
                  return -1;
               }
               if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );
      }
      if (search != '') {
         setSorted(
            sorted.filter((item) => {
               if (
                  item.fullName.toLowerCase().includes(search.toLowerCase()) ||
                  item.number.includes(search)
               ) {
                  return [item];
               }
            })
         );
      }
   }, [search, data]);

   return (
      <>
         <FormControl
            sx={{ width: '100%', position: 'relative', marginBottom: '2rem' }}
         >
            <FaSearch
               color="#374DA0"
               style={{
                  zIndex: 2,
                  position: 'absolute',
                  top: '50%',
                  left: '20px',
                  transform: 'translateY(-50%)',
                  fontSize: '0.9rem',
               }}
            />
            <TextInput
               value={search}
               fullWidth
               placeholder="search contacts"
               InputProps={{
                  style: {
                     color: '#374DA0',
                     borderRadius: '25px',
                     paddingLeft: '35px',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontSize: '1rem',
                  },
               }}
               onChange={(event) => handleSearch(event)}
            />
         </FormControl>
         <Box
            sx={{
               marginTop: '2rem',
               height: '42vh',
               overflowY: 'auto',
            }}
            id="scrollableDiv"
         >
            <InfiniteScroll
               dataLength={selector.contacts.length}
               next={loadmore}
               hasMore={selector.hasMore}
               loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
               scrollableTarget="scrollableDiv"
               endMessage={
                  data.length > 5 && (
                     <p style={{ textAlign: 'center' }}>
                        You have a total of {data.length} non-DND contacts
                     </p>
                  )
               }
            >
               {sorted.length > 0 ? (
                  sorted.map((item, index) => (
                     <Card
                        sx={{
                           display: 'flex',
                           paddingY: '10px',
                           paddingLeft: '70px',
                           marginBottom: '10px',
                           paddingRight: '15px',
                           alignItems: 'center',
                           cursor: 'pointer',
                           position: 'relative',
                           '&:hover': {
                              boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
                           },
                        }}
                        key={item.id}
                        onClick={() => navigate(`/contacts/user/${item.id}`)}
                     >
                        <Box
                           sx={{
                              position: 'absolute',
                              left: '15px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              backgroundColor: ' #D7DBEC',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontWeight: '700',
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                                 fontSize: '1.1rem',
                              }}
                           >
                              {item.fullName?.split('')[0]?.toUpperCase()}
                           </Typography>
                        </Box>
                        <Box key={index}>
                           <Typography
                              sx={{
                                 fontSize: '1rem',
                                 fontWeight: 600,
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                              }}
                           >
                              {item.fullName}
                           </Typography>
                           <Typography
                              sx={{
                                 fontSize: '1rem',
                                 fontWeight: 600,
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                              }}
                           >
                              {item.number}
                           </Typography>
                        </Box>
                     </Card>
                  ))
               ) : (
                  <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                     <Box>
                        <img src={noTransIcon} alt="icon" />
                     </Box>
                     <Typography
                        sx={{
                           fontWeight: 600,
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: '#374da0',
                           marginTop: '1rem',
                           marginBottom: '1rem',
                           // textAlign: 'center',
                        }}
                     >{`Hey ${
                        userLSAuth()?.fullName.split(' ')[0]
                     }  your non DND contacts will appear here`}</Typography>
                  </Box>
               )}
            </InfiniteScroll>
         </Box>
      </>
   );
};

export default DND;
