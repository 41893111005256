import { Card, Box, Typography, Button } from '@mui/material';
import successImg from '../../../assets/VerificationBadge.svg';
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { resetCreated } from '../../../components/app/features/contacts/contactSlice';

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 60%;
text-align: center;
font-size: 1rem;
border-radius:5px;
margin: 0 auto;
margin-top: 4rem;

&:hover {background: #374DA0;}
`
);

const Success = ({ text, count }) => {
   const dispatch = useDispatch();
   return (
      <Card
         sx={{
            width: '500px',
            padding: '3rem 0',
            display: 'flex',
            flexDirection: 'column',
         }}
      >
         <Box
            sx={{
               margin: 'auto',
               width: '150px',
               marginTop: '7rem',
               marginBottom: '2rem',
            }}
         >
            <img src={successImg} alt="success" width={'100%'} />
         </Box>
         <Typography
            sx={{
               textAlign: 'center',
               fontSize: 'clamp(1rem, 2vw, 1.2rem)',
               fontWeight: '600',
            }}
         >
            Successful
         </Typography>
         <Typography
            sx={{
               textAlign: 'center',

               marginTop: '1rem',
               fontWeight: '600',
            }}
         >
            {`${count ? count : ''} ${text}`}
         </Typography>
         <FlatButton
            onClick={() => {
               dispatch(resetCreated());
            }}
         >
            Okay
         </FlatButton>
      </Card>
   );
};

export default Success;
