import React, { useState, useEffect, forwardRef } from 'react';
import * as Yup from 'yup';
import {
   Box,
   Button,
   TextField,
   Typography,
   CircularProgress,
   IconButton,
   InputAdornment,
   styled,
   InputLabel,
   Dialog,
   Slide,
} from '@mui/material';
import { Formik } from 'formik';
import {
   generateOTP,
   resetErrorOnLogin,
   resetLoading,
   verifyEmail,
   setLoading,
} from '../../components/app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../components/utils/selectors/selectors';
import { useNavigate } from 'react-router-dom';
import VerifyBadge from '../../assets/VerificationBadge.svg';
import './accountVerification.css';

const TextInput = styled(TextField)(
   ({ theme }) => `
       color: #49454F;
       &.placeholer{
        color: red;
       }
     `
);

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
   () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AccountVerification = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);

   const [minutes, setMinutes] = useState(1);
   const [seconds, setSeconds] = useState(30);
   const [otp, setOtp] = useState(true);
   const [emailOtp, setEmailOtp] = useState(true);
   const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

   const closeConfirmDelete = () => {
      setOpenConfirmDelete(false);
   };

   const handleGetOTP = (type) => {
      if (type === 'phone') {
         setOtp(false);

         dispatch(generateOTP({ userId: selector?.admin?.id, type }));
      } else {
         setEmailOtp(false);
         dispatch(generateOTP({ userId: selector?.admin?.id, type }));
      }
   };

   const navigateToAvatar = () => {
      navigate('/select-avatar');
   };

   const resendOTPMail = (type) => {
      setMinutes(1);
      setSeconds(30);
      dispatch(generateOTP({ userId: selector?.admin?.id, type }));
   };

   useEffect(() => {
      dispatch(resetErrorOnLogin());
      dispatch(resetLoading());
      if (selector.loading == 'idle') {
      }

      return () => {
         setTimeout(() => {
            handleGetOTP('email');
         }, 1000);
      };
   }, []);

   useEffect(() => {
      const interval = setInterval(() => {
         if (seconds > 0) {
            setSeconds(seconds - 1);
         }
         if (seconds === 0) {
            if (minutes === 0) {
               clearInterval(interval);
            } else {
               setSeconds(59);
               setMinutes(minutes - 1);
            }
         }
      }, 1000);

      return () => {
         clearInterval(interval);
      };
   }, [seconds, selector.loading]);

   return (
      <div>
         <Box margin="auto" width="10%" display="flex" gap={0.5} mt={2}>
            <Box className="dot1"></Box>
            <Box className="dot1"></Box>
            <Box className="dot1 dot2"></Box>
         </Box>
         <Box fontWeight={600} className="pageHeader">
            Account Verification
         </Box>
         <Typography fontWeight={600} color="#02222d">
            Click Get OTP to send a One Time Password to{' '}
            <span
               style={{
                  color: '#0046FF',
                  marginRight: '3px',
               }}
            >
               {selector?.admin?.email}
            </span>
         </Typography>
         <Formik
            initialValues={{
               email: '',
               phoneNumber: '',
            }}
            validationSchema={Yup.object().shape({
               email: Yup.string()
                  .max(6)
                  .label('Otp')
                  .required('Please enter the 4 digit email verification code'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               dispatch(
                  verifyEmail({
                     email: values.email,
                     userId: selector?.admin?.id,
                  })
               );
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <Box mt={3}>
                     <InputLabel
                        sx={{ mt: 8 }}
                        align="left"
                        htmlFor="email"
                        margin="dense"
                     >
                        Email verification
                     </InputLabel>
                     <TextInput
                        sx={{
                           my: '10px',
                        }}
                        id="email"
                        fullWidth
                        margin="dense"
                        helperText={touched.email && errors.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.email}
                        variant="outlined"
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => handleGetOTP('email')}
                                 >
                                    {emailOtp && (
                                       <Typography color="#374DA0">
                                          Get OTP
                                       </Typography>
                                    )}
                                    {!emailOtp && (
                                       <Typography color="#374DA0">
                                          {seconds > 0 || minutes > 0 ? (
                                             <p>
                                                {minutes < 10
                                                   ? `0${minutes}`
                                                   : minutes}
                                                :
                                                {seconds < 10
                                                   ? `0${seconds}`
                                                   : seconds}
                                             </p>
                                          ) : (
                                             <p
                                                onClick={() =>
                                                   resendOTPMail('email')
                                                }
                                             >
                                                Resend Otp
                                             </p>
                                          )}
                                       </Typography>
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Box>
                  <Button
                     mt={6}
                     sx={{
                        borderRadius: '11px',
                        py: '7px',
                        px: '35px',
                        fontSize: '15px',
                        width: '45%',
                        mx: 'auto',
                        mt: '50px',
                     }}
                     variant="contained"
                     size="small"
                     type="submit"
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={!values.email || selector.loading === 'pending'}
                  >
                     Verify account
                  </Button>
                  <Typography mt={2}>
                     Didn’t receive the OTP? Click Resend OTP after the
                     countdown
                  </Typography>
               </form>
            )}
         </Formik>

         <DialogWrapper
            open={selector.verifyEmailLoading === 'success'}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            onClose={closeConfirmDelete}
         >
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
               p={5}
            >
               <Box
                  margin="auto"
                  width="10%"
                  display="flex"
                  gap={0.5}
                  mt={-1}
                  mb={4}
               >
                  <Box className="dot1"></Box>
                  <Box className="dot1"></Box>
                  <Box className="dot1 dot2"></Box>
               </Box>
               <Box fontWeight={600} mt={-2} className="pageHeader">
                  Verification Successful
               </Box>
               <Typography
                  fontSize={15}
                  mb={3}
                  fontWeight={600}
                  color="#02222d"
               >
                  Your account has been successfully verified
               </Typography>
               <Box
                  width={130}
                  height={130}
                  borderRadius="50%"
                  className="imageWrapper"
               >
                  <img
                     src={VerifyBadge}
                     alt=""
                     width="100%"
                     height="100%"
                     style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                     }}
                  />
               </Box>
               <Button
                  sx={{
                     borderRadius: '11px',
                     py: '3px',
                     px: '35px',
                     fontSize: '15px',
                     width: '55%',
                     mx: 'auto',
                     mt: '60px',
                     mb: '30px',
                  }}
                  variant="contained"
                  size="small"
                  onClick={navigateToAvatar}
               >
                  Continue
               </Button>
            </Box>
         </DialogWrapper>
      </div>
   );
};

export default AccountVerification;
