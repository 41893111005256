import React from 'react';
import { Container } from './style';
import {
   Box,
   Button,
   Card,
   // FormControl,
   IconButton,
   InputAdornment,
   InputLabel,
   TextField,
   // TextareaAutosize,
   Typography,
} from '@mui/material';
import * as Yup from 'yup';
import backImg from '../../../assets/back.svg';
import costImg from '../../../assets/cost.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
// import { messageSelector } from '../../../components/utils/selectors/selectors';
import { createSenderId } from '../../../components/app/features/message/messageSlice';
import { userLSAuth } from '../../../components/app/features/env';
import { useNavigate } from 'react-router-dom';
import SimpleModal from '../../../components/modal/SimpleModal'

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 70%;
text-align: center;
font-size: 1rem;
border-radius:5px;
margin: 1rem auto;

&:hover {background: #374DA0;}
`
);

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: #F7F7F8;
       color: #49454F;
     `
);

const VerifyId = () => {
   // const selector = useSelector(messageSelector);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [buttonText, setButtonText] = React.useState(false);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '25px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '1rem',
                  }}
               >
                  Sender ID Application
               </Typography>
               <Box sx={{ marginTop: '2rem' }}>
                  {/* <Typography
                     sx={{
                        fontWeight: 600,
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        alignContent: 'flex-start',
                        color: '#374DA0',
                        display: 'flex',
                        // border: '1px solid red',
                     }}
                  >
                     <img
                        src={costImg}
                        style={{
                           marginRight: '10px',
                           alignContent: 'flex-start',
                           // border: '1px solid red',
                           height: 'max-content',
                        }}
                        alt="icon"
                     />
                     Having an approved Sender ID guarantees that messages will
                     not fail to deliver due to violating industry regulations
                     on message sender identification.
                  </Typography> */}
                  <Typography
                     sx={{
                        fontWeight: 600,
                        color: '#374DA0',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        alignContent: 'flex-start',
                        mt: 2,
                        display: 'flex',
                        justifyContent:'center'
                     }}
                  >
                    <SimpleModal />
                  </Typography>
               </Box>
               <Formik
                  initialValues={{
                     senderId: '',
                     company: '',
                     usecase: '',
                     userId: userLSAuth().id,
                  }}
                  validationSchema={Yup.object().shape({
                     senderId: Yup.string().required('Please enter Sender ID').max(11, 'Use case must not be above 11 characters'),
                     company: Yup.string().required(
                        'Please enter Company name'
                     ),
                     usecase: Yup.string()
                        .required('Field cannot be empty')
                        .min(10, 'Use case must be at least 10 characters'),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                     dispatch(createSenderId(values));
                     setButtonText(true);
                     values.senderId = '';
                     values.company = '';
                     values.usecase = '';

                     setTimeout(() => {
                        setSubmitting(false);
                     }, 400);
                  }}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form
                        noValidate
                        onSubmit={handleSubmit}
                        style={{ marginTop: '2.2rem' }}
                     >
                        <InputLabel
                           htmlFor="senderId"
                           sx={{
                              fontWeight: 600,
                              color: '#0e0e0e',
                              fontSize: '0.9rem',
                              lineHeight: 'unset',
                              mb: '10px',
                           }}
                        >
                           Sender ID
                        </InputLabel>
                        <TextInput
                           id="senderId"
                           // error={Boolean(touched.name && errors.name)}
                           fullWidth
                           margin="normal"
                           name="senderId"
                           placeholder="Enter Sender ID"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="text"
                           value={values.senderId}
                           variant="outlined"
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton
                                       edge="end"
                                       onClick={() => {
                                          setFieldValue('senderId', '');
                                       }}
                                    >
                                       <HighlightOffIcon />
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                        <span style={{ color: 'red' }}>
                           {errors.senderId &&
                              touched.senderId &&
                              errors.senderId}
                        </span>
                        <InputLabel
                           htmlFor="companyName"
                           sx={{
                              fontWeight: 600,
                              color: '#0e0e0e',
                              fontSize: '0.9rem',
                              lineHeight: 'unset',
                              mt: 2,
                              mb: '10px',
                           }}
                        >
                           Business Name
                        </InputLabel>
                        <TextInput
                           fullWidth
                           id="companyName"
                           margin="normal"
                           name="company"
                           placeholder="Enter Business Name"
                           error={Boolean(touched.company && errors.company)}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="text"
                           value={values.company}
                           variant="outlined"
                           InputProps={{
                              // startAdornment: (
                              // 	<InputAdornment position='start'>
                              // 		<img src={phoneImg} alt='phone' />
                              // 	</InputAdornment>
                              // ),
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton
                                       edge="end"
                                       onClick={() => {
                                          setFieldValue('company', '');
                                       }}
                                    >
                                       <HighlightOffIcon />
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                        <span style={{ color: 'red' }}>
                           {errors.company && touched.company && errors.company}
                        </span>
                        <InputLabel
                           htmlFor="sampleMsg"
                           sx={{
                              fontWeight: 600,
                              color: '#0e0e0e',
                              fontSize: '0.9rem',
                              lineHeight: 'unset',
                              mt: 2,
                              mb: '10px',
                           }}
                        >
                           Sample Message
                        </InputLabel>
                        <textarea
                           name="usecase"
                           value={values.usecase}
                           onChange={handleChange}
                           placeholder="Enter in a sample of the type of information/messages you will send using this Sender ID.   Example: New goods are available"
                           style={{
                              width: '100%',
                              display: 'block',
                              minHeight: '150px',
                              borderColor: '#374DA0',
                              borderRadius: '6px',
                              background: '#F7F7F8',
                              fontSize: '1rem',
                              padding: '10px',
                           }}
                           id="sampleMsg"
                           className="focus"
                        ></textarea>
                        <span style={{ color: 'red' }}>
                           {errors.usecase && touched.usecase && errors.usecase}
                        </span>
                        <Box display={'flex'} sx={{ marginTop: '4rem' }}>
                           <FlatButton
                              disabled={
                                 !values.senderId ||
                                 !values.company ||
                                 !values.usecase ||
                                 Boolean(errors.senderId && touched.senderId)
                              }
                              type="submit"
                           >
                              Apply
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
               {buttonText === true && (
                  <Typography
                     sx={{
                        fontWeight: 600,
                        fontSize: '0.9rem',
                        color: '#374DA0',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        mb: 2,
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <img
                        src={costImg}
                        style={{ marginRight: '10px' }}
                        alt="icon"
                     />
                     This verification process can take about 48-72hrs to
                     complete.
                  </Typography>
               )}
               {!buttonText && (
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1rem',
                     }}
                  >
                     <Button
                        sx={{
                           width: '70%',
                           fontSize: '1rem',
                           padding: '7px 0',
                           borderRadius: '6px',
                           fontWeight: 600,
                        }}
                        variant="outlined"
                        onClick={() => navigate('/send-message/idHistory')}
                     >
                        View Sender ID History
                     </Button>
                  </Box>
               )}
            </Box>
         </Card>
      </Container>
   );
};

export default VerifyId;
