import React from 'react';
import { MessageWrapper } from './style';
import MessageHome from '../../components/message/messageHome/MessageHome';

const SendMessage = () => {
	return (
		<MessageWrapper>
			<MessageHome />
		</MessageWrapper>
	);
};

export default SendMessage;
