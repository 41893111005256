import React from 'react';
import { Box, Card, styled, Typography, useTheme, Button } from '@mui/material';
import Badge from '../../assets/TransactionBadge.svg';
import { useDispatch, useSelector } from 'react-redux';
import { amountPayable } from '../app/features/transactions/TransactionSlice';
import { useNavigate } from 'react-router-dom';

const TransactionWrapper = styled(Card)(
   ({ theme }) => `
	& > * {
		font-family: 'Raleway', 'Montserrat', sans-serif !important;
	}
    margin-top: 6px;  
    width: 200px;
    padding-bottom: 15px;
    padding-top: 5px;
				@media(max-width: 731px){
					width: 150px;
				}
    `
);

const SuspendButton = styled(Button)(
   ({ theme }) => `
    border-radius: 15px;
    padding: 2px 20px;
    font-size: 0.8125rem;
    background: transparent;
    border: 1px solid #374DA0;
    color: #374DA0;
    margin: 0 auto;
    margin-top: 10px;
    whitespace: nowrap;
    width: 100%;
  font-family: 'Raleway', 'Montserrat', sans-serif;
    &:hover {
    background: #F7F7F8;
    }
    `
);

const LastTransaction = ({ item }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const handlePayment = () => {
      dispatch(amountPayable({ amount: item.amount }));
      navigate('/top-up/payment');
   };

   const date = new Date(item.createdAt);
   return (
      <TransactionWrapper>
         <Box width="80%" margin="auto">
            <img src={Badge} alt="Badge" />
            <Typography
               align="center"
               fontFamily="'Raleway', 'Montserrat', sans-serif"
               fontWeight={500}
               color="#0B0F20"
               fontSize={16}
               mt={-1}
            >
               {`₦${item.amount}`}
            </Typography>
            <Typography
               align="center"
               fontFamily="'Raleway', 'Montserrat', sans-serif"
               fontWeight={900}
               color="#374DA0"
               fontSize={20}
            >
               {`${item.value} units`}
            </Typography>
            <Typography
               align="center"
               fontFamily="'Raleway', 'Montserrat', sans-serif"
               fontWeight={500}
               color="#0B0F20"
               fontSize={16}
            >
               {`${date.getDate()}/${
                  date.getMonth() + 1
               }/${date.getFullYear()} `}
            </Typography>
            <SuspendButton onClick={() => handlePayment()}>
               Buy again
            </SuspendButton>
         </Box>
      </TransactionWrapper>
   );
};

export default LastTransaction;
