import React from 'react';
import { DashboardWrapper } from '../topUp/TopUpStyle';
import { Box, Typography, Select, FormControl, MenuItem } from '@mui/material';

import LastTransaction from '../../components/lastTransaction/LastTransaction';
import DateItem from '../../components/dateItem/DateItem';
import CustomTable from '../../components/transactionTable/Table';
// import DateItemMonth from '../../components/dateItem/DateItemMonth';
import noTransIcon from '../../assets/noTrans.svg';
import backImg from '../../assets/back.svg';
import TransactionLoading from '../../components/mui_skeleton/LastTransactionSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../components/utils/selectors/selectors';
import {
   getLastFour,
   getUserTransactions,
} from '../../components/app/features/transactions/TransactionSlice';

import { userLSAuth } from '../../components/app/features/env';

const TransactionHistory = () => {
   const currentDate = new Date();
   const today = `${
      currentDate.getMonth() + 1
   }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

   const oneYearAgo = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
   );
   const lastYear = `${
      oneYearAgo.getMonth() + 1
   }/${oneYearAgo.getDate()}/${oneYearAgo.getFullYear()}`;

   const [filter, setFilter] = React.useState(false);
   const [range, setRange] = React.useState('Select Range');
   const [startDate, setStartDate] = React.useState(lastYear);
   const [endDate, setEndDate] = React.useState(today);
   const [resetPage, setResetPage] = React.useState(false);
   const options = ['Select Range', 'Custom'];

   const restartPagination = () => {
      setResetPage(true);
      setTimeout(() => {
         setResetPage(false);
      }, 500);
   };

   const handleChange = (event) => {
      setRange(event.target.value);
   };

   const selector = useSelector(getTransactions);
   const dispatch = useDispatch();

   const lastYearString = oneYearAgo.toISOString();

   const time = {
      start: lastYearString,
      end: new Date().toISOString(),
   };

   React.useEffect(() => {
      dispatch(
         getLastFour({
            userId: userLSAuth()?.id,
            timeFrame: time,
            page: 1,
         })
      );
      dispatch(
         getUserTransactions({
            timeFrame: selector.timeFrame ? selector.timeFrame : time,
            page: 1,
         })
      );
   }, []);

   React.useEffect(() => {
      if (selector.timeFrame !== null) {
         // start date
         const inputStartDate = new Date(selector.timeFrame.start);
         const start = `${(inputStartDate.getUTCMonth() + 1)
            .toString()
            .padStart(2, '0')}/${inputStartDate
            .getUTCDate()
            .toString()
            .padStart(2, '0')}/${inputStartDate.getUTCFullYear()}`;
         setStartDate(start);

         // end date
         const inputEndDate = new Date(selector.timeFrame.end);
         const end = `${(inputEndDate.getUTCMonth() + 1)
            .toString()
            .padStart(2, '0')}/${inputEndDate
            .getUTCDate()
            .toString()
            .padStart(2, '0')}/${inputEndDate.getUTCFullYear()}`;
         setEndDate(end);
      }
   }, [selector.timeFrame]);

   const createFilter = () => setFilter(true);

   return (
      <DashboardWrapper>
         <Box
            sx={{
               marginTop: '2rem',
               position: 'relative',
            }}
         >
            <Box className="topUpWrapper" sx={{ paddingBottom: '2rem' }}>
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
            </Box>
            <Box className="topUpWrapper">
               <Box className="profileCard" color="white">
                  <Box
                     display="flex"
                     p={3}
                     align="center"
                     alignItems="center"
                     textAlign="center"
                     sx={{ position: 'relative' }}
                  >
                     <Box height={50} width={50} borderRadius={50}>
                        <img
                           src={userLSAuth().displayPicture}
                           height="100%"
                           width="100%"
                           alt="Profile"
                           style={{ borderRadius: '50%' }}
                        />
                     </Box>
                     <Box
                        sx={{
                           position: 'absolute',
                           left: 0,
                           right: 0,
                           top: 0,
                           bottom: 0,
                        }}
                     >
                        <FormControl
                           fullWidth
                           sx={{
                              height: '100%',
                              '& .MuiOutlinedInput-root, & .MuiOutlinedInput-root.Mui-focused, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-error':
                                 { border: 'none' },
                              '& .MuiSvgIcon-root, .MuiSelect-icon': {
                                 width: '1.6rem',
                                 height: '1.6rem',
                                 top: '50%',
                                 right: '34%',
                                 transform: 'translate(50%, -50%)',
                                 color: '#ffffff',
                                 position: 'absolute',
                              },
                              '& .MuiSelect-select, .MuiInputBase-input, & .MuiOutlinedInput-input ':
                                 {
                                    // padding: '0 !important',

                                    height: '100% !important',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 },
                              '@media (max-width: 650px)': {
                                 '& .MuiSvgIcon-root, .MuiSelect-icon': {
                                    top: '50%',
                                    right: '28%',
                                    transform: 'translate(50%, -50%)',
                                    color: '#ffffff',
                                    position: 'absolute',
                                 },
                              },
                           }}
                        >
                           <Select
                              // labelId='demo-simple-select-label'
                              id="range-select"
                              value={range}
                              label="range"
                              onChange={handleChange}
                              MenuProps={{
                                 PaperProps: {
                                    sx: {
                                       '& .MuiMenuItem-root': {
                                          color: '#0F0E0E !important',
                                       },
                                       '& .MuiMenuItem-root:hover': {
                                          background: '#374DA0 !important',
                                          color: '#ffffff !important',
                                       },
                                       '& .MuiList-root': {
                                          padding: '0px',
                                       },
                                       '& .MuiMenuItem-root.Mui-selected': {
                                          background: '#374DA0 !important',
                                          color: '#ffffff !important',
                                       },
                                       '& .MuiButtonBase-root': {
                                          borderRadius: '0px',
                                       },
                                    },
                                 },
                              }}
                              sx={{
                                 width: '100%',
                                 height: '100%',
                                 fontSize: 'clamp(1.2rem, 2vw, 1.4rem)',
                                 fontWeight: '600',
                                 color: '#fff',
                              }}
                           >
                              {options.map((option) => (
                                 <MenuItem
                                    key={option}
                                    sx={{
                                       '&:hover': {
                                          color: '#000',
                                       },
                                    }}
                                    value={option}
                                 >
                                    {option}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     </Box>
                  </Box>
               </Box>
            </Box>
         </Box>

         <Box>
            {range === 'Custom' ? (
               <Box
                  sx={{
                     // width: '70%',
                     marginTop: '2rem',
                     mx: 'auto',
                     // border: '1px solid red',
                  }}
               >
                  <DateItem
                     createFilter={createFilter}
                     restartPagination={restartPagination}
                  />
               </Box>
            ) : (
               // : range === 'Monthly' ? (
               //    <Box
               //       sx={{
               //          mt: 4,
               //       }}
               //    >
               //       <DateItemMonth />
               //    </Box>
               // )
               <Box sx={{ mx: 'auto' }} className="topUpWrapper">
                  {selector.lastFourpaymentData.length > 0 && (
                     <Typography
                        color="#0F0E0E"
                        fontSize={18}
                        fontWeight={600}
                        mt={6}
                        mb={2}
                        sx={{
                           color: '#0F0E0E',
                           '@media (max-width: 606px )': {
                              textAlign: 'center',
                           },
                        }}
                     >
                        Last transactions
                     </Typography>
                  )}

                  {selector.lastFourpaymentData.length > 0 ||
                  selector.paymentData > 0 ? (
                     <Box
                        display={'flex'}
                        gap={2}
                        sx={{
                           '@media (max-width: 730px )': {
                              flexWrap: 'wrap',
                           },
                           '@media (max-width: 606px )': {
                              justifyContent: 'center',
                           },
                        }}
                     >
                        {selector.lastFourpaymentData.map((item, index) => (
                           <LastTransaction item={item} key={index} />
                        ))}
                     </Box>
                  ) : selector.loading === 'pending' ? (
                     <Box
                        display={'flex'}
                        gap={2}
                        sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
                     >
                        <TransactionLoading />
                        <TransactionLoading />
                     </Box>
                  ) : null}
               </Box>
            )}
         </Box>
         <Box>
            {
               <Typography
                  sx={{
                     width: '90%',
                     marginX: 'auto',
                     marginTop: '2rem',
                  }}
               >
                  {selector.lastFourpaymentData.length > 0 &&
                     `${startDate} - ${endDate}`}
               </Typography>
            }
         </Box>
         <Box sx={{ marginTop: '1rem' }}>
            {selector.paymentData.length > 0 ||
            selector.rangedPaymentData.length > 0 ? (
               <Box
                  sx={{
                     width: '90%',
                     display: 'flex',
                     justifyContent: 'center',
                     margin: '0 auto',
                  }}
               >
                  {<CustomTable filterState={filter} resetPage={resetPage} />}
               </Box>
            ) : (
               <Box sx={{ textAlign: 'center', marginTop: '3rem' }}>
                  <Box>
                     <img src={noTransIcon} alt="icon" />
                  </Box>
                  <Typography
                     sx={{
                        fontWeight: 600,
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: '#374da0',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        // textAlign: 'center',
                     }}
                  >{`Hey ${
                     userLSAuth()?.fullName.split(' ')[0]
                  }  your transactions will appear here`}</Typography>
               </Box>
            )}
         </Box>
      </DashboardWrapper>
   );
};

export default TransactionHistory;
