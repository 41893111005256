import styled from '@emotion/styled';
import { Box, TextField, Button } from '@mui/material';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0;
 min-height: 100vh; 
 background: #FFF;
 `
);
export const TextInput = styled(TextField)(
   ({ theme }) => ` 
 background: #E8E8E8;
	border-radius:25px;
	background-color: inherit;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: inherit;
color: #374DA0;
font-weight: 600;
width: 47%;
text-align: center;
font-size: 1rem;
position: relative;
border-radius:15px;
@media (max-width:460px ){
	width: 100%;
}
`
);

export const AddImage = styled.img(
   ({ theme }) => `
position: fixed;
							zIndex: 4;
							left: 73%;
							transform: translateX(50%);
							bottom: 2rem;
							width: 80px;
							cursor: pointer;
@media (max-width:425px ){
	left: 70%;
}
@media (max-width:380px ){
	left: 67%;
}
`
);
