import React, { useEffect, forwardRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
   Button,
   TextField,
   CircularProgress,
   IconButton,
   InputAdornment,
   styled,
   Dialog,
   Slide,
   Box,
   Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { resetPassword } from '../../../app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../../utils/selectors/selectors';
import { Link } from 'react-router-dom';
import { resetLoading } from '../../../app/features/auth/AuthSlice';
import VerifyBadge from '../../../../assets/VerificationBadge.svg';
import EmailIcon from '@mui/icons-material/Email';

const TextInput = styled(TextField)(
   ({ theme }) => `
       color: #49454F;
       margin-top: 40px;
       &.placeholer{
        color: red;
       }`
);

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
   () => `
        .MuiDialog-paper {
          overflow: visible;
        }1    1q
  `
);

const EmailForgotPassword = () => {
   const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);

   useEffect(() => {
      dispatch(resetLoading());
   }, []);

   const closeConfirmDelete = () => {
      setOpenConfirmDelete(false);
   };

   return (
      <div>
         <Formik
            initialValues={{
               email: '',
            }}
            validationSchema={Yup.object().shape({
               email: Yup.string()
                  .email('The email provided should be a valid email address')
                  .max(255)
                  .required('The email field is required'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               dispatch(resetPassword(values));
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
               setFieldValue,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <TextInput
                     fullWidth
                     margin="normal"
                     helperText={touched.email && errors.email}
                     error={Boolean(touched.email && errors.email)}
                     name="email"
                     placeholder="Enter email address"
                     onBlur={handleBlur}
                     onChange={handleChange}
                     type="text"
                     value={values.email}
                     variant="outlined"
                     InputProps={{
                        startAdornment: (
                           <InputAdornment position="start">
                              <EmailIcon />
                           </InputAdornment>
                        ),
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 edge="end"
                                 onClick={() => {
                                    setFieldValue('email', '');
                                 }}
                              >
                                 <HighlightOffIcon />
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '7px',
                        px: '35px',
                        fontSize: '15px',
                        width: '45%',
                        mx: 'auto',
                        mt: '100px',
                     }}
                     variant="contained"
                     size="small"
                     type="submit"
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={!values.email || errors.email}
                  >
                     Send Link
                  </Button>
                  <Typography
                     sx={{
                        mt: 2,
                     }}
                  >
                     <Link
                        to="/login"
                        style={{
                           textDecorationLine: 'none',
                           fontFamily: "'Raleway', 'Monteserat', sans-serif",
                        }}
                     >
                        Login
                     </Link>
                  </Typography>
               </form>
            )}
         </Formik>

         <DialogWrapper
            open={selector.loading === 'success'}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            onClose={closeConfirmDelete}
         >
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
               p={5}
            >
               <Box
                  width={130}
                  height={130}
                  borderRadius="50%"
                  className="imageWrapper"
               >
                  <img
                     src={VerifyBadge}
                     alt=""
                     width="100%"
                     height="100%"
                     style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                     }}
                  />
               </Box>
               <Box
                  fontFamily="'Raleway', 'Montserrat', sans-serif"
                  fontWeight={600}
                  mt={3}
                  className="pageHeader"
               >
                  Verified
               </Box>
               <Typography
                  fontSize={16}
                  mb={3}
                  fontFamily="'Raleway', 'Montserrat', sans-serif"
                  fontWeight={500}
                  color="#02222d"
               >
                  Click on the link sent to your email address to continue
               </Typography>
            </Box>
         </DialogWrapper>
      </div>
   );
};

export default EmailForgotPassword;
