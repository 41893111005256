import React, { useRef } from 'react';
import {
   Box,
   Card,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableContainer,
   TableRow,
   Typography,
} from '@mui/material';
import ButtonIcon from '../../assets/ButtonIcon.svg';
import Label from '../label/Label';
import TableLoading from '../mui_skeleton/TableCellSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../utils/selectors/selectors';
import {
   getRangedUserTransactions,
   getUserTransactions,
} from '../app/features/transactions/TransactionSlice';
import InfiniteScroll from 'react-infinite-scroll-component';

const CustomTable = ({ filterState, resetPage }) => {
   const [page, setPage] = React.useState(1);
   const [searchPage, setSearchPage] = React.useState(1);
   const [data, setData] = React.useState([]);
   const selector = useSelector(getTransactions);
   const dispatch = useDispatch();
   const containerRef = useRef();

   const heading = ['Top-up', 'Date & Time', 'Volume', 'Status'];

   // get transactions from the last year till date
   const currentDate = new Date();
   const oneYearAgo = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
   );

   const lastYearString = oneYearAgo.toISOString();

   const time = {
      start: lastYearString,
      end: new Date().toISOString(),
   };

   React.useEffect(() => {
      // if filterState is true, means search operation is in progress
      if (filterState) {
         dispatch(
            getRangedUserTransactions({
               userId: selector?.admin?.id,
               timeFrame: selector.timeFrame ? selector.timeFrame : time,
               page: searchPage,
            })
         );
      } else {
         dispatch(
            getUserTransactions({
               userId: selector?.admin?.id,
               timeFrame: selector.timeFrame ? selector.timeFrame : time,
               page: page,
            })
         );
      }
   }, [page, searchPage]);

   React.useEffect(() => {
      // if filterState is true, means search operation is in progress and we are setting the data to be displayed to contain data from the search results
      if (selector.paymentData && !filterState) {
         setData(selector.paymentData);
      } else if (selector.rangedPaymentData && filterState) {
         setData(selector.rangedPaymentData);
      }
   }, [selector.paymentData, selector.rangedPaymentData]);

   React.useEffect(() => {
      if (resetPage) {
         setSearchPage(1);
         setTimeout(() => {
            containerRef.current?.scrollTo(0, 0);
         }, 500);
      }
   }, [resetPage]);

   // loadmore function to get more data if selector.hasMore is true
   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending' && !filterState) {
         setPage(page + 1);
      } else if (
         selector.hasMore &&
         selector.loading !== 'pending' &&
         filterState
      ) {
         setSearchPage(searchPage + 1);
      }
   };

   return (
      <Card
         sx={{
            width: '100%',
         }}
      >
         <TableContainer
            id="scrollableDiv"
            ref={containerRef}
            sx={{
               overflowY: 'auto',
               maxHeight: '45vh',
               height: 'auto',
               '@media(max-width:606px)': {
                  overflow: 'auto',
               },
            }}
         >
            <Table
               sx={{
                  overflowY: 'auto',
                  height: 'auto',
                  maxHeight: '45vh',
                  '@media(max-width:606px)': { overflow: 'auto' },
                  flex: 'unset',
               }}
            >
               <TableHead sx={{ position: 'sticky', top: 0 }}>
                  <TableRow sx={{ background: '#374da0' }}>
                     {heading.map((item, ind) => (
                        <TableCell
                           // align='center'
                           key={ind}
                           width="max-content"
                           sx={{
                              whiteSpace: 'nowrap',
                              color: '#fff',
                              height: 'auto !important',
                           }}
                        >
                           {item}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.length > 0 ? (
                     data.map((item, index) => {
                        const date = new Date(item.createdAt);
                        let p;
                        date.getHours() > 11 ? (p = 'PM') : (p = 'AM');
                        return (
                           <TableRow hover key={index}>
                              <TableCell
                                 sx={{
                                    whiteSpace: 'nowrap',
                                    height: '100px',
                                 }}
                              >
                                 <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{ width: 'max-content' }}
                                 >
                                    <img
                                       style={{
                                          marginRight: '10px',
                                       }}
                                       src={ButtonIcon}
                                    />

                                    <Typography
                                       noWrap
                                       variant="h5"
                                       sx={{ fontWeight: 600 }}
                                    >
                                       {`₦${item.amount}`}
                                    </Typography>
                                 </Box>
                              </TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                 <Typography>
                                    {' '}
                                    {`${date.getDate()}/${
                                       date.getMonth() + 1
                                    }/${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}${p}`}
                                 </Typography>
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                 <Typography>{`${item.value} units`}</Typography>
                              </TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                 <Label
                                    color={
                                       item.status.toLowerCase() === 'success'
                                          ? 'success'
                                          : item.status.toLowerCase() ===
                                            'failed'
                                          ? 'error'
                                          : 'info'
                                    }
                                 >
                                    <Typography>
                                       {item.status.toLowerCase()}
                                    </Typography>
                                 </Label>
                              </TableCell>
                           </TableRow>
                        );
                     })
                  ) : selector.loading === 'pending' ? (
                     <TableRow>
                        <TableCell
                           sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                           <TableLoading />
                        </TableCell>
                        <TableCell align="center">
                           <TableLoading />
                        </TableCell>
                        <TableCell align="center">
                           <TableLoading />
                        </TableCell>
                        <TableCell align="center">
                           <TableLoading />
                        </TableCell>
                     </TableRow>
                  ) : null}

                  {/* <tr>
                     <PaginationXD
                        postsPerPage={postsPerPage}
                        totalPosts={selector.paginate}
                        paginate={paginate}
                     />
                  </tr> */}
               </TableBody>
            </Table>
            <InfiniteScroll
               dataLength={
                  !filterState
                     ? selector.paymentData.length
                     : selector.rangedPaymentData.length
               }
               next={loadmore}
               hasMore={selector.hasMore}
               loader={
                  <h4 style={{ textAlign: 'center', margin: '15px 0' }}>
                     Loading...
                  </h4>
               }
               scrollableTarget="scrollableDiv"
               endMessage={
                  <p style={{ textAlign: 'center', margin: '15px 0' }}>
                     Yay! You have seen it all
                  </p>
               }
            />
         </TableContainer>
      </Card>
   );
};

export default CustomTable;
