import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { transaction } from '../../components/utils/selectors/selectors';
import { getActivities } from '../../components/app/features/transactions/TransactionSlice';
import sentSms from '../../assets/sentSms.svg';
import addedcontacts from '../../assets/addedcontacts.svg';
import topUpIcon from '../../assets/TopupIcon.svg';
import buttonIcon from '../../assets/buttonIcon.png';
import scheduledMessage from '../../assets/scheduledMessage.svg';
import { Typography } from '@mui/material';
// import PaginationXD from '../../components/pagination/PaginationXD';

export default function RecentTable() {
   const dispatch = useDispatch();
   const selector = useSelector(transaction);

   React.useEffect(() => {
      dispatch(getActivities({ page: 1 }));
   }, []);

   return (
      <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="recent activities table">
            <TableHead>
               <TableRow sx={{ background: '#374DA0', color: '#fff' }}>
                  <TableCell
                     sx={{
                        color: '#fff',
                        width: '25%',
                        textTransform: 'none',
                        fontFamily: 'Poppins',
                        fontWeight: '500',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     }}
                  >
                     Action
                  </TableCell>
                  <TableCell
                     sx={{
                        color: '#fff',
                        width: '25%',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        textTransform: 'none',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     }}
                     align="center"
                  >
                     Date
                  </TableCell>
                  <TableCell
                     sx={{
                        color: '#fff',
                        width: '25%',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        textTransform: 'none',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     }}
                     align="center"
                  >
                     Volume
                  </TableCell>
                  <TableCell
                     sx={{
                        color: '#fff',
                        width: '25%',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        textTransform: 'none',
                        fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     }}
                     align="center"
                  >
                     Status
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {selector?.activities.length > 0 &&
                  selector?.activities.map((row, index) => (
                     <TableRow
                        key={index}
                        sx={{
                           '&:last-child td, &:last-child th': { border: 0 },
                        }}
                     >
                        <TableCell
                           sx={{
                              width: '25%',
                              fontWeight: '700',
                              fontSize: 'clamp(0.85rem, 1.5vw, 0.95rem)',
                              // display: 'flex',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontWeight: '700',
                                 fontSize: 'clamp(0.85rem, 1.5vw, 0.95rem)',
                                 display: 'flex',
                                 alignItems: 'center',
                                 whiteSpace: 'nowrap',
                              }}
                           >
                              <img
                                 src={
                                    row.action === 'Account top-up'
                                       ? topUpIcon
                                       : row.action === 'Added Contacts'
                                       ? addedcontacts
                                       : row.action === 'Sender ID'
                                       ? buttonIcon
                                       : row.action === 'Scheduled SMS'
                                       ? scheduledMessage
                                       : sentSms
                                 }
                                 style={{
                                    width:
                                       row.action === 'Scheduled SMS'
                                          ? '25px'
                                          : '30px',
                                    marginRight: '10px',
                                 }}
                                 alt="icon"
                              />
                              {row.action}
                           </Typography>
                        </TableCell>
                        <TableCell
                           align="center"
                           sx={{
                              width: '25%',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontWeight: '700',
                              fontSize: 'clamp(0.85rem, 1.5vw, 0.95rem)',
                           }}
                        >
                           {new Date(row.createdAt).toLocaleDateString(
                              'en-US',
                              {
                                 month: '2-digit',
                                 day: '2-digit',
                                 year: 'numeric',
                              }
                           )}
                        </TableCell>
                        <TableCell
                           align="center"
                           sx={{
                              width: '25%',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontWeight: '700',
                              fontSize: 'clamp(0.85rem, 1.5vw, 0.95rem)',
                           }}
                        >
                           {`${row.value} ${row.unit}`}
                        </TableCell>
                        <TableCell
                           align="center"
                           sx={{
                              width: '25%',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontWeight: '700',
                              fontSize: 'clamp(0.85rem, 1.5vw, 0.95rem)',
                              color:
                                 row.status === 'Success'
                                    ? '#0FA958'
                                    : row.status === 'In Progress'
                                    ? '#0BA9E0'
                                    : '#FF3F3F',
                           }}
                        >
                           {row.status}
                        </TableCell>
                     </TableRow>
                  ))}
               <tr>
                  {/* <PaginationXD
							totalPosts={selector?.paginate && selector?.paginate}
							paginate={paginate}
						/> */}
               </tr>
            </TableBody>
         </Table>
      </TableContainer>
   );
}
