import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Dialog,
   Checkbox,
   MenuItem,
   Menu,
} from '@mui/material';
import trash from '../../../assets/trash.svg';
import { Container, TextInput, FlatButton, AddImage } from './style';
import backImg from '../../../assets/back.svg';
import infoImg from '../../../assets/info.svg';
import addImg from '../../../assets/FAB.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import { FaSearch, FaTimes } from 'react-icons/fa';
import message from '../../../assets/message2.svg';
import { useNavigate, useParams } from 'react-router-dom';
import AddContactsGroup from '../addGroup/AddContactsGroup';
import styled from '@emotion/styled';
import groupImg from '../../../assets/group.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
   deleteContactFromGroup,
   fetchGroups,
   fetchSingleGroup,
} from '../../../components/app/features/contacts/contactSlice';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { addSelectedGroups } from '../../../components/app/features/message/messageSlice';
import EditGroup from './EditGroup';
import GroupPricingInfo from './GroupPricingInfo';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}
	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
   box-sizing: border-box;
   white-space: nowrap;
	width: 160px;
	font-weight:600;
	border-radius:0;
	`
);

const Group = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   const [sorted, setSorted] = React.useState([]);
   const [isOpen, setIsOpen] = React.useState(false);
   const [add, setAdd] = React.useState(false);
   const [isDelete, setIsDelete] = React.useState(false);
   const [deleted, setDeleted] = React.useState(false);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [editOpen, setEditOpen] = React.useState(false);

   const handleClose = () => setIsOpen(false);
   const setDialogState = (value) => {
      setAdd(value);
   };
   const params = useParams();

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         selector.group.contacts.map((item, id) => collection.push(item.id));
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const setClose = () => setAnchorEl(false);
   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const actions = ['Add', 'Send Message', 'Edit', 'Delete'];
   const closeEditDialog = () => {
      setEditOpen(false);
   };

   const deleteUsers = () => {
      dispatch(
         deleteContactFromGroup({
            groupId: params.id,
            users: checkedList,
         })
      );
      setTimeout(() => {
         setDeleted(true);
         setIsDelete(false);
         setAction('');
      }, 500);
   };

   React.useEffect(() => {
      dispatch(
         fetchSingleGroup({
            userId: selector?.admin?.id,
            groupId: params.id,
         })
      );
   }, [params.id, deleted === true]);

   // search functionality
   const [search, setSearch] = React.useState('');
   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   React.useEffect(() => {
      if (selector.group?.contacts) {
         setSorted(
            [...selector.group?.contacts].sort(function (a, b) {
               if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
                  return -1;
               }
               if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );
      }

      if (search != '') {
         setSorted(
            sorted.filter((item) => {
               if (
                  item.fullName.toLowerCase().includes(search.toLowerCase()) ||
                  item.number.includes(search)
               ) {
                  return [item];
               } else {
                  return;
               }
            })
         );
      }
   }, [selector.group, search]);

   React.useEffect(() => {
      const data = [selector?.group];
      if (action === 'Add') {
         setAdd(true);
      } else if (action === 'Send Message') {
         dispatch(addSelectedGroups(data));
         navigate('/send-message/message');
      } else if (action === 'Edit') {
         setEditOpen(true);
      }
   }, [action]);

   const resetAction = () => setAction('');

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               minHeight: '80vh',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <AddImage
               src={addImg}
               alt="add contacts"
               onClick={() => setAdd(true)}
            />
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               {/* dialog */}
               <Dialog onClose={handleClose} open={isOpen}>
                  <GroupPricingInfo groupId={params.id} />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setIsDelete(false);
                     setAction('');
                  }}
                  open={isDelete}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Are you sure you want to delete the selected contacts?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsDelete(false);
                              setAction('');
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => {
                              deleteUsers();
                           }}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>

               {/*  */}
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleChange(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>

               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '2rem',
                  }}
               >
                  <img
                     src={groupImg}
                     alt="group"
                     style={{ marginRight: '0.5rem' }}
                  />
                  {selector?.group ? selector.group.title : 'loading...'}
               </Typography>
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     color: '#0F0E0E',
                  }}
               >
                  {`${selector.group?.contacts ? sorted.length : 0} contacts`}
               </Typography>
               {action === 'Delete' && (
                  <Typography
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 600,
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        position: 'relative',
                     }}
                  >
                     <Checkbox
                        checked={itemsChecked}
                        onClick={selectItem}
                        {...label}
                     />{' '}
                     Select All
                     <img
                        src={trash}
                        alt="delete"
                        style={{
                           position: 'absolute',
                           right: '40px',
                           top: '50%',
                           transform: 'translateY(-50%)',
                           cursor: 'pointer',
                        }}
                        onClick={() => setIsDelete(true)}
                     />
                     <FaTimes
                        color="#000"
                        style={{
                           position: 'absolute',
                           right: '10px',
                           top: '50%',
                           transform: 'translateY(-50%)',
                           cursor: 'pointer',
                        }}
                        onClick={() => setAction('')}
                     />
                  </Typography>
               )}
               <FormControl
                  sx={{
                     width: '100%',
                     position: 'relative',
                     marginTop: '2rem',
                  }}
               >
                  <FaSearch
                     color="#374DA0"
                     style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '20px',
                        transform: 'translateY(-50%)',
                        fontSize: '0.9rem',
                     }}
                  />
                  <TextInput
                     value={search}
                     fullWidth
                     placeholder="search contacts"
                     InputProps={{
                        style: {
                           color: '#374DA0',
                           borderRadius: '25px',
                           paddingLeft: '35px',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        },
                     }}
                     onChange={(event) => handleSearch(event)}
                  />
               </FormControl>
               <Box
                  sx={{
                     marginTop: '2rem',
                     display: 'flex',
                     justifyContent: 'space-between',
                     '@media (max-width:460px )': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        rowGap: '15px',
                     },
                  }}
               >
                  <FlatButton
                     variant="outlined"
                     onClick={() => {
                        dispatch(addSelectedGroups([selector?.group]));
                        navigate('/send-message/message');
                     }}
                  >
                     <img
                        src={message}
                        alt="send message"
                        style={{
                           marginRight: '10px',
                        }}
                     />
                     Send Message
                  </FlatButton>
                  <FlatButton
                     variant="outlined"
                     onClick={() => setIsOpen(true)}
                  >
                     <img
                        src={infoImg}
                        alt="classify"
                        style={{
                           marginRight: '10px',
                        }}
                     />
                     View Pricing Info
                  </FlatButton>
               </Box>
               <Box
                  sx={{
                     marginTop: '2rem',
                     height: '300px',
                     overflowY: 'auto',
                  }}
               >
                  {sorted.length > 0 && action === '' ? (
                     sorted.map((item, index) => (
                        <Card
                           sx={{
                              display: 'flex',
                              paddingY: '10px',
                              paddingLeft: '15px',
                              marginBottom: '10px',
                              alignItems: 'center',
                              cursor: 'pointer',
                              '&:hover': {
                                 boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.3)',
                              },
                           }}
                           key={item.id}
                           onClick={() => navigate(`/contacts/user/${item.id}`)}
                        >
                           <Box
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 borderRadius: '50%',
                                 backgroundColor: ' #D7DBEC',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                              }}
                           >
                              <Typography
                                 sx={{
                                    fontWeight: '700',
                                    fontFamily:
                                       "'Raleway', 'Montserrat', sans-serif",
                                    fontSize: '1.1rem',
                                 }}
                              >
                                 {item.fullName?.split('')[0]?.toUpperCase()}
                              </Typography>
                           </Box>
                           <Box sx={{ paddingLeft: '15px' }} key={index}>
                              <Typography
                                 sx={{
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    fontFamily:
                                       "'Raleway', 'Montserrat', sans-serif",
                                 }}
                              >
                                 {item.fullName}
                              </Typography>
                              <Typography
                                 sx={{
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    fontFamily:
                                       "'Raleway', 'Montserrat', sans-serif",
                                 }}
                              >
                                 {item.number}
                              </Typography>
                           </Box>
                        </Card>
                     ))
                  ) : action === 'Delete' && sorted.length > 0 ? (
                     sorted.map((item, index) => (
                        <Card
                           sx={{
                              display: 'flex',
                              paddingY: '10px',
                              paddingLeft: '15px',
                              marginBottom: '10px',
                              cursor: 'pointer',
                              '&:hover': {
                                 boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
                              },
                           }}
                           key={index}
                        >
                           <Checkbox
                              value={item.id}
                              checked={checkedList.includes(item.id)}
                              onClick={handleCheckboxClick}
                              {...label}
                           />
                           <Box sx={{ paddingLeft: '10px' }}>
                              <Typography
                                 sx={{
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    fontFamily:
                                       "'Raleway', 'Montserrat', sans-serif",
                                 }}
                              >
                                 {item.fullName}
                              </Typography>
                              <Typography
                                 sx={{
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    fontFamily:
                                       "'Raleway', 'Montserrat', sans-serif",
                                 }}
                              >
                                 {item.number}
                              </Typography>
                           </Box>
                        </Card>
                     ))
                  ) : selector.loading !== 'pending' && sorted.length === 0 ? (
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontSize: '1.1rem',
                           fontWeight: '600',
                           marginTop: '2rem',
                        }}
                     >
                        No Contacts
                     </Typography>
                  ) : (
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontSize: '1.1rem',
                           fontWeight: '600',
                           marginTop: '2rem',
                        }}
                     >
                        Loading...
                     </Typography>
                  )}
               </Box>

               <Dialog
                  onClose={() => {
                     setAdd(false);
                     setAction('');
                  }}
                  open={add}
               >
                  <AddContactsGroup
                     setDialogState={setDialogState}
                     groupId={params.id}
                     vcfText={`/contacts/add-vcf`}
                     contactText={`/contacts/all-contacts-group/${params.id}`}
                     copyPasteText={`/contacts/add-copypaste`}
                     reset={resetAction}
                  />
               </Dialog>
               <Dialog
                  onClose={() => {
                     dispatch(fetchGroups());
                     setAction('');
                     setEditOpen(false);
                  }}
                  open={editOpen || action === 'Edit'}
               >
                  <EditGroup
                     groupId={selector.group && selector?.group.id}
                     closeEditDialog={closeEditDialog}
                     resetAction={resetAction}
                  />
               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default Group;
