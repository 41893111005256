import React from 'react';

import { Box, Card, Typography } from '@mui/material';
import backImg from '../../assets/back.svg';
import Accordion from './AcCordion';
import styled from '@emotion/styled';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #E8E8E8;
 `
);

const Faq = () => {
   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 700,
                     fontSize: '1.25rem',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     marginTop: '1rem',
                  }}
               >
                  Frequently Asked Questions
               </Typography>
               <Typography
                  sx={{
                     width: 'max-content',
                     fontWeight: 700,
                     fontSize: '0.9rem',
                     color: '#374DA0',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     margin: '1rem auto',
                  }}
               >
                  Lorem ipsum dolor sit amet consectetur. Est rhoncus.
               </Typography>
               <Box
                  sx={{
                     background: '#fff',
                     borderTopRightRadius: '10px',
                     borderTopLeftRadius: '10px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={1}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '10px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={2}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '10px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={3}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     marginY: '10px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={4}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
               <Box
                  sx={{
                     background: '#fff',
                     borderBottomRightRadius: '10px',
                     borderBottomLeftRadius: '10px',
                     padding: '1rem',
                  }}
               >
                  <Accordion
                     num={5}
                     rules={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit.								Aspernatur perferendis a, impedit placeat quae itaque! Officiis	deleniti id iste voluptas, illum ad harum libero eaque ducimus.	Soluta nisi reprehenderit similique!.'
                     }
                     required={'required'}
                  />
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default Faq;
