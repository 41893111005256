import { Box, Typography } from '@mui/material';
import React from 'react';
import firstSlideImg from '../../../assets/firstSlideImg.svg';

const FirstSlide = () => {
	return (
		<Box>
			<Box sx={{ width: '100%', height: '210px' }}>
				<img
					src={firstSlideImg}
					style={{ width: '100%', height: '100%' }}
					alt='icon'
				/>
			</Box>
			<Typography
				sx={{ textAlign: 'center', color: '#0F0E0E', fontSize: '1rem' }}>
				The next set of cards will help you learn about Parrotica’s simple
				pricing system with an example.
			</Typography>
		</Box>
	);
};

export default FirstSlide;
