import { Box } from '@mui/material';
import React from 'react';

import contactImg from '../../../assets/contactImg.svg';
import { FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { removeSelectedContacts } from '../../../components/app/features/message/messageSlice';

const ContactContent = ({ item }) => {
   const dispatch = useDispatch();
   // const selector = useSelector(messageSelector)

   const handleTimesClick = () => {
      dispatch(removeSelectedContacts(item.number));
   };

   return (
      <Box sx={{ position: 'relative', marginRight: '10px', margin:"5px" }}>
         <FaTimes
            color="#374DA0"
            style={{
               position: 'absolute',
               right: '5px',
               zIndex: 7,
               bottom: '50%',
               transform: 'translateY(50%)',
               cursor: 'pointer',
            }}
            onClick={() => handleTimesClick()}
         />
         <Box
            sx={{
               border: '2px solid #374DA0',
               padding: '5px 10px',
               paddingRight: '30px',
               borderRadius: '3px',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               display: 'flex',
               alignItems: 'center',
               fontWeight: 600,
               cursor: 'pointer',
               width: 'max-content',
            }}
         >
            <img
               src={contactImg}
               style={{ marginRight: '10px', width: '15px' }}
               alt="grpIcon"
            />
            {item && `${item?.number} `}
         </Box>
      </Box>
   );
};

export default ContactContent;
