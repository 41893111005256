import styled from '@emotion/styled';
import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import sadImg from '../../assets/noTrans.svg';
import AddImg from '../../assets/FAB.svg';
import backImg from '../../assets/back.svg';
import AddContact from '../../pages/contacts/allContacts/AddContact';

const Container = styled(Box)(
   ({ theme }) => `

height: calc(100vh - ${theme.header.height}); 
				width: 100%; 
			display: flex;
			justify-content: center;
			align-items: center;
 
`
);
const Icon = styled.figure(
   ({ theme }) => `
	width: 70%; 
	height:30vh;
	margin: 6rem auto 3rem auto; 
`
);
const AddIcon = styled.figure(
   ({ theme }) => `

width: 80px;
position: absolute;
right: 1rem;
bottom: 1rem;
`
);

const NoContact = () => {
   const [add, setAdd] = React.useState(false);
   const setDialogState = (value) => {
      setAdd(value);
   };
   return (
      <Container>
         <Box
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '85vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               borderRadius: '32px 32px 0 0',
            }}
         >
            <img
               src={backImg}
               alt="back"
               style={{
                  position: 'absolute',
                  width: '40px',
                  cursor: 'pointer',
               }}
               onClick={() => window.history.back()}
            />
            <Typography
               // variant='h4'
               sx={{
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginTop: '1.5rem',
                  // color: '#374DA0',
                  fontWeight: '700',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
               }}
            >
               Contacts
            </Typography>
            <Icon>
               <img
                  src={sadImg}
                  alt="No contact"
                  style={{ width: '100%', height: '100%' }}
               />
            </Icon>
            <Box
               sx={{
                  margin: '2rem auto 0 auto',
                  width: '70%',
                  textAlign: 'center',
               }}
            >
               <Typography
                  sx={{
                     fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                     textAlign: 'center',
                     marginTop: '2rem',
                     // color: '#374DA0',
                     fontWeight: '600',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  }}
               >
                  You do not have any saved contacts yet! Click on the Plus
                  button to add new contacts
               </Typography>
            </Box>
            <AddIcon onClick={() => setAdd(true)}>
               <img
                  src={AddImg}
                  alt="add contacts"
                  style={{
                     width: '100%',
                     height: '100%',
                     cursor: 'pointer',
                  }}
               />
            </AddIcon>
            <Dialog onClose={() => setAdd(false)} open={add}>
               <AddContact
                  directText={'/contacts/add-direct'}
                  vcfText={'/contacts/add-vcf'}
                  copyPasteText={'/contacts/add-copypaste'}
                  setDialogState={setDialogState}
               />
            </Dialog>
         </Box>
      </Container>
   );
};

export default NoContact;
