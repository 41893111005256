import styled from '@emotion/styled';
import {
   Card,
   Dialog,
   MenuItem,
   Box,
   Typography,
   Menu,
   Checkbox,
} from '@mui/material';
import React from 'react';
import EditGroup from './EditGroup';
import verticalMenu from '../../../assets/verticalMenu.svg';
import starFav from '../../../assets/starFav.svg';
import star from '../../../assets/star.svg';
import groupImg from '../../../assets/group.svg';
import doc from '../../../assets/Doc.svg';
import {
   deleteGroup,
   fetchGroups,
   starGroup,
} from '../../../components/app/features/contacts/contactSlice';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { useNavigate } from 'react-router-dom';
import AddContactsGroup from '../addGroup/AddContactsGroup';
import { addSelectedGroups } from '../../../components/app/features/message/messageSlice';
import { resetCreated } from '../../../components/app/features/auth/AuthSlice';
import Success from './Success';

const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:700;
	border-radius:0;
	`
);

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}

	`
);

const GroupCard = ({ item, checkAction, handleCheckboxClick, checkedList }) => {
   const actions = ['Add Contact', 'Send Message', 'Edit', 'Delete'];
   const [isOpen, setIsOpen] = React.useState(false);
   const [groupId, setGroupId] = React.useState(null);
   const [isDelete, setIsDelete] = React.useState(false);
   const [deleted, setDeleted] = React.useState(false);
   const [add, setAdd] = React.useState(false);
   const closeEditDialog = () => setIsOpen(false);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   const [anchorEl, setAnchorEl] = React.useState(null);
   const setClose = () => {
      setAnchorEl(false);
   };
   const [action, setAction] = React.useState('');
   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const deleteTeam = () => {
      dispatch(deleteGroup({ groupId: item.id }));
      setTimeout(() => {
         setDeleted(true);
         setIsDelete(false);
         dispatch(fetchGroups());
         setAction('');
      }, 500);
   };

   React.useEffect(() => {
      const data = [item];
      if (action === 'Add Contact') {
         setAdd(true);
      } else if (action === 'Edit') {
         setIsOpen(true);
      } else if (action === 'Delete') {
         setIsDelete(true);
      } else if (action === 'Send Message') {
         dispatch(addSelectedGroups(data));
         navigate('/send-message/message');
      }
   }, [action]);

   const setDialogState = (value) => setAdd(value);
   const resetAction = () => {
      setAction('');
   };

   return (
      <>
         <Card
            key={item.id}
            sx={{
               padding: '20px',
               position: 'relative',
               marginBottom: '10px',
               ':hover': {
                  border: '1px solid #374DA0',
               },
            }}
         >
            <DropDown
               id="basic-menu"
               anchorEl={anchorEl}
               open={Boolean(anchorEl)}
               onClose={setClose}
               MenuListProps={{
                  'aria-labelledby': 'basic-button',
               }}
            >
               {actions.map((action, index) => (
                  <Activity
                     key={index}
                     onClick={(e) => {
                        handleChange(e.target.innerText);
                        setGroupId(item.id);
                     }}
                  >
                     {action}
                  </Activity>
               ))}
            </DropDown>
            <Dialog
               onClose={() => {
                  setIsDelete(false);
                  setAction('');
               }}
               open={isDelete}
            >
               <Card sx={{ padding: '20px 30px' }}>
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        textAlign: 'center',
                        marginBottom: '2rem',
                     }}
                  >
                     Are you sure you want to delete the selected contact group?
                  </Typography>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           cursor: 'pointer',
                           marginRight: ' 1rem',
                        }}
                        onClick={() => {
                           setIsDelete(false);
                           setAction('');
                        }}
                     >
                        Cancel
                     </Typography>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: 'red',
                           cursor: 'pointer',
                        }}
                        onClick={() => {
                           deleteTeam();
                        }}
                     >
                        Yes
                     </Typography>
                  </Box>
               </Card>
            </Dialog>
            <img
               src={verticalMenu}
               alt="vertical_menu"
               style={{
                  position: 'absolute',
                  right: '15px',
                  top: '20px',
                  cursor: 'pointer',
               }}
               onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <img
               src={item?.isStarred === true ? star : starFav}
               alt="star"
               style={{
                  position: 'absolute',
                  right: '45px',
                  top: '20px',
                  cursor: 'pointer',
               }}
               onClick={() => {
                  dispatch(
                     starGroup({
                        groupId: item.id,
                        starred: item?.isStarred === true ? false : true,
                     })
                  );
               }}
            />
            <Box
               sx={{
                  display: 'flex',
                  marginBottom: ' 1rem',
                  paddingRight: '45px',
                  alignItems: 'center',
               }}
            >
               {checkAction === '' || checkAction === 'Add Group' ? (
                  <img
                     src={groupImg}
                     alt="group"
                     style={{ marginRight: '15px' }}
                  />
               ) : (
                  <Checkbox
                     value={item.id}
                     checked={checkedList.includes(item.id)}
                     onClick={handleCheckboxClick}
                     {...label}
                  />
               )}
               <Typography
                  sx={{
                     fontWeight: '600',
                     fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',

                     cursor: 'pointer',
                     '&:hover': {
                        textDecoration: 'underline',
                     },
                  }}
                  onClick={() => navigate(`/contacts/group/${item.id}`)}
               >
                  {item.title}
               </Typography>
            </Box>
            <Typography
               sx={{
                  fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  fontWeight: '600',
                  color: '#6B6B6B',
               }}
            >
               {`	${item.contacts ? item.contacts.length : 0} contacts`}
            </Typography>
            <Box sx={{ display: 'flex', marginTop: ' 1rem' }}>
               <img src={doc} alt="group" style={{ marginRight: '15px' }} />
               <Typography
                  sx={{
                     fontWeight: '600',
                     fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  }}
               >
                  {item.description}
               </Typography>
            </Box>
         </Card>
         <Dialog
            onClose={() => {
               dispatch(fetchGroups());
               setAction('');
               setIsOpen(false);
            }}
            open={isOpen}
         >
            <EditGroup
               groupId={groupId}
               closeEditDialog={closeEditDialog}
               resetAction={() => setIsOpen(false)}
            />
         </Dialog>
         <Dialog
            onClose={() => {
               setAction('');
               setAdd(false);
            }}
            open={add}
         >
            <AddContactsGroup
               setDialogState={setDialogState}
               groupId={item.id}
               vcfText={`/contacts/add-vcf`}
               contactText={`/contacts/all-contacts-group/${item.id}`}
               copyPasteText={`/contacts/add-copypaste`}
               reset={resetAction}
            />
         </Dialog>
      </>
   );
};

export default GroupCard;
