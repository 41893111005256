import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/AuthSlice.js';
import transactionReducer from '../features/transactions/TransactionSlice.js';
import contactReducer from '../features/contacts/contactSlice.js';
import messageReducer from '../features/message/messageSlice.js';

const store = configureStore({
	reducer: {
		auth: authReducer,
		transaction: transactionReducer,
		contact: contactReducer,
		message: messageReducer,
	},
});

export default store;
