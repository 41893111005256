import axios from 'axios';
import { getBaseUrls } from './env.js';
import './iosWorkaround.js';

let stopRefreshing = false;

document.addEventListener(
   'visibilitychange',
   (event) => {
      if (document.visibilityState == 'visible') {
         if (localStorage.getItem('stayLoggedIn') == 'true') {
            initialiseRefreshToken(10);
         } else {
            stopRefreshing = false;
         }
      } else {
         if (localStorage.getItem('stayLoggedIn') == 'false') {
            stopRefreshing = true;
         }
      }
   },
   false
);

const httpclient = axios.create({});

httpclient.interceptors.response.use(
   (response) => response,
   (error) => {
      if (
         error.response &&
         error.response.status === 401 &&
         !location.pathname.includes('login') &&
         !location.pathname.includes('/account-verification')
      ) {
         localStorage.clear();
         sessionStorage.clear();
         location.replace('/login');
      } else {
         return Promise.reject(error);
      }
   }
);

// getNewAccessToken
const getNewAccessToken = async () => {
   const response = await httpclient.post(
      `${getBaseUrls().auth}/api/v1/auth/token`,
      JSON.stringify({}),
      {
         withCredentials: true,
      }
   );

   localStorage.setItem('accessToken', response.data.data.accessToken);
   initialiseRefreshToken(response.data.data.expiresIn);
};

export const initialiseRefreshToken = (interval) => {
   if (stopRefreshing) {
      console.log('App in stale mood');
      return;
   }

   setTimeout(async () => {
      await getNewAccessToken();
   }, interval);
};

if (
   !location.pathname.includes('login') &&
   !location.pathname.includes('signup') &&
   !location.pathname.includes('forgotpassword') &&
   !location.pathname.includes('account-verification') &&
   !location.pathname.includes('reset-password') &&
   location.pathname != '/'
) {
   initialiseRefreshToken(500);
}

export default httpclient;
