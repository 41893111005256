import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function TransactionLoading() {
	return (
		<Stack spacing={1}>
			{/* <Skeleton variant='rectangular' width={210} height={60} /> */}
			<Skeleton variant='rounded' width={200} height={120} />
		</Stack>
	);
}
