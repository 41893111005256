import React from 'react';
import { Box, styled, Typography } from '@mui/material';
// import DashBoardLink from '../../dashbaordLink/DashboardLink';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SettingsIcon from '../../../assets/Settings.svg';
import LogoutIcon from '../../../assets/LogoutIcon.svg';
import DashboardCustomizeSharpIcon from '@mui/icons-material/DashboardCustomizeSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import PhoneBook from '../../../assets/Phonebook.svg';
import PhoneImg from '../../../assets/contactUsWhite.svg';
import Message from '../../../assets/Message.svg';

const SidebarWrapper = styled(Box)(
   ({ theme }) => `
          width: ${theme.sidebar.minWidth};
          padding-top:  ${theme.header.height};
          min-width: ${theme.sidebar.minWidth};
          background: ${theme.sidebar.background};
          position: fixed;
          top: 0;
          z-index: 7;
          bottom:0;
          left:0;
         transition: width 0.1s ease-in-out;
         transition: transform 0.1s ease-in-out;
         display:flex;
         flex-direction: column;
         justify-content: space-between;
         row-gap:2rem;
         @media (max-width: ${theme.breakpoints.values.myCustomQueryI}px) {
           display: none;
        }
								
  `
);

const boxSX = {
   py: '12px',
   pl: '22px',
   mt: '10px',
   color: '#ffffff',
   cursor: 'pointer',
   borderLeft: '4px solid #7A88C0',
   '&:hover': {
      color: '#ffffff',
      backgroundColor: '#2E4085',
      borderLeft: '5px solid white',
   },
};
const activeLink = {
   py: '12px',
   pl: '22px',
   mt: '17px',
   cursor: 'pointer',
   color: '#ffffff',
   backgroundColor: '#2E4085',
   borderLeft: '4px solid white',
};

const Sidebar = ({ isOpen, getLogOutState }) => {
   const location = useLocation();

   return (
      <SidebarWrapper
         sx={{
            width: isOpen ? '250px' : '100px',
         }}
      >
         <Box>
            <Link
               to="/dashboard"
               style={{
                  textDecoration: 'none',
               }}
            >
               <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  gap={1}
                  sx={location.pathname == '/dashboard' ? activeLink : boxSX}
               >
                  <DashboardCustomizeSharpIcon
                     sx={{
                        fontSize: '35px',
                        marginLeft: '-7px',
                     }}
                  />
                  <Typography variant="h5" fontSize="16px" fontWeight={500}>
                     {isOpen === true ? 'Dashboard' : ''}
                  </Typography>
               </Box>
            </Link>
            <Link
               to="/send-message"
               style={{
                  textDecoration: 'none',
               }}
            >
               <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  gap={1.3}
                  sx={
                     location.pathname.includes('send-message')
                        ? activeLink
                        : boxSX
                  }
               >
                  <img
                     src={Message}
                     alt="message"
                     style={{
                        width: '30px',
                        marginLeft: '-7px',
                     }}
                  />
                  <Typography variant="h5" fontSize="16px" fontWeight={500}>
                     {isOpen ? 'Send Message' : ''}
                  </Typography>
               </Box>
            </Link>
            <Link
               to="/top-up"
               style={{
                  textDecoration: 'none',
               }}
            >
               <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  gap={1}
                  sx={location.pathname.includes('top-up') ? activeLink : boxSX}
               >
                  <AddCircleSharpIcon
                     sx={{
                        fontSize: '38px',
                        ml: '-7px',
                     }}
                  />
                  <Typography variant="h5" fontSize="16px" fontWeight={500}>
                     {isOpen ? 'Top-Up' : ''}
                  </Typography>
               </Box>
            </Link>
            <Link
               to="/contacts"
               style={{
                  textDecoration: 'none',
               }}
            >
               <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  gap={1.4}
                  sx={
                     location.pathname.includes('contacts') ? activeLink : boxSX
                  }
               >
                  <img src={PhoneBook} style={{ width: '30px' }} alt="Phone" />
                  <Typography variant="h5" fontSize="16px" fontWeight={500}>
                     {isOpen ? 'Contacts' : ''}
                  </Typography>
               </Box>
            </Link>
         </Box>
         {/* <DashBoardLink isOpen={isOpen} /> */}
         <Box>
            <Link
               to="/dashboard/contact"
               style={{
                  textDecoration: 'none',
               }}
            >
               <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  gap={1.4}
                  sx={
                     location.pathname.includes('dashboard/contact')
                        ? activeLink
                        : boxSX
                  }
               >
                  <img
                     src={PhoneImg}
                     alt="Phone"
                     style={{
                        marginLeft: '-7px',
                        width: '30px',
                     }}
                  />
                  <Typography variant="h5" fontSize="16px" fontWeight={500}>
                     {isOpen ? 'Contact Support' : ''}
                  </Typography>
               </Box>
            </Link>
            <Link
               to="/settings"
               style={{
                  textDecoration: 'none',
               }}
            >
               <Box>
                  <Box
                     display="flex"
                     textAlign="center"
                     alignItems="center"
                     gap={1.3}
                     sx={location.pathname == '/settings' ? activeLink : boxSX}
                  >
                     <img
                        src={SettingsIcon}
                        alt="message"
                        style={{
                           marginLeft: '-7px',
                           width: '30px',
                        }}
                     />
                     <Typography variant="h5" fontSize="16px" fontWeight={500}>
                        {isOpen ? 'Settings' : ''}
                     </Typography>
                  </Box>
               </Box>
            </Link>

            <Box
               display="flex"
               textAlign="center"
               alignItems="center"
               gap={1.3}
               sx={boxSX}
               onClick={() => {
                  getLogOutState(true);
               }}
            >
               <img
                  src={LogoutIcon}
                  alt="message"
                  style={{
                     marginLeft: '-7px',
                     width: '30px',
                  }}
               />
               <Typography variant="h5" fontSize="16px" fontWeight={500}>
                  {isOpen ? 'Log out' : ''}
               </Typography>
            </Box>
         </Box>
      </SidebarWrapper>
   );
};

export default Sidebar;
