import { Box, Typography } from '@mui/material';
import React from 'react';
import Control from './Control';
import FirstSlide from './FirstSlide';
import SecondSlide from './SecondSlide';
import ThirdSlide from './ThirdSlide';
import FourthSlide from './FourthSlide';
import FifthSlide from './FifthSlide';
import SixthSlide from './SixthSlide';
import SeventhSlide from './SeventhSlide';
import EightSlide from './EightSlide';

const Index = () => {
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const slides = [
		<FirstSlide />,
		<SecondSlide />,
		<ThirdSlide />,
		<FourthSlide />,
		<FifthSlide />,
		<SixthSlide />,
		<SeventhSlide />,
		<EightSlide />,
	];

	const nextSlide = () => {
		setCurrentSlide((prevSlide) =>
			prevSlide === slides.length - 1 ? 0 : prevSlide + 1
		);
	};

	const prevSlide = () => {
		setCurrentSlide((prevSlide) =>
			prevSlide === 0 ? slides.length - 1 : prevSlide - 1
		);
	};

	const slideNum = currentSlide + 1;
	return (
		<Box
			sx={{
				minHeight: '65vh',
				// border: '1px solid red',
				mt: '3rem',
				position: 'relative',
			}}>
			<Box
				sx={{
					margin: '0 auto',
					mb: '3rem',
					width: '80%',
					// border: '1px solid red',
				}}>
				{slides[currentSlide]}
			</Box>
			<Box
				sx={{
					position: 'absolute',
					left: 0,
					right: 0,
					// border: '1px solid red',
					bottom: 0,
					minHeight: '50px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}>
				<Typography
					sx={{
						fontFamily: "'Raleway', 'Montserrat', sans-serif",
						fontSize: '1.1rem',
						color: '#374DA0',
						fontWeight: '600',
						mb: '5px',
					}}>
					{`${slideNum}/8`}
				</Typography>
				<Control
					nextSlide={nextSlide}
					prevSlide={prevSlide}
					currentSlide={currentSlide}
				/>
			</Box>
		</Box>
	);
};

export default Index;
