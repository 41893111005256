import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Divider } from '@mui/material';
import './modal.css';

const SenderIDModal = () => {
  const [open, setOpen] = useState(true); 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Button to open the modal */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleOpen} 
        sx={{
          backgroundColor: '#374DA0', 
          textTransform: 'none', 
          fontWeight: 'bold', 
          padding: '12px 24px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#0056b3',
          }
        }}
      >
        View Sender ID Guidelines
      </Button>

      {/* Modal */}
      <Modal
        open={open}
        onClose={(e) => e.stopPropagation()} 
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          className="modal-box" 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '70%', md: '50%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          {/* Modal header */}
          <Box sx={{ bgcolor: '#f5f5f5', padding: '20px' }}> {/* Slightly smaller padding */}
            <Typography 
              id="modal-title" 
              variant="h5" 
              component="h2" 
              sx={{ fontWeight: 'bold', color: '#333', fontSize: '20px' }} 
            >
              Sender ID Guidelines
            </Typography>
          </Box>

          <Divider />

          {/* Scrollable content area */}
          <Box 
            className="modal-content" 
            sx={{
              padding: '20px',
              maxHeight: '65vh', 
              overflowY: 'auto', 
            }}
          >
            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              1. Unique Sender ID
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Ensure your sender ID is unique.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              2. Unique Business Name
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Your business name should also be unique.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              3. No Misrepresentation
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Avoid forging or misrepresenting other businesses’ sender IDs.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              4. Processing Time
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Typically, sender ID approval takes 48-72 hours.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              5. Character Limit
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Your sender ID must not exceed 11 characters.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              6. Sample Messages
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Provide precise sample messages with your application.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              7. Special Characters
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Do not include special characters in your sender ID.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              8. Generic Names
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Avoid generic names (e.g., Blessing, Dami, Ayo, Victor, etc.).
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              9. Start with Figures
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Sender IDs should not begin with figures.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              10. Rejections
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
              Any of the above reasons can lead to rejection.
            </Typography>

            <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic', color: '#555', fontSize: '14px' }}>
              <strong>Note:</strong> If you do not get your sender ID approved within 5 days, please reapply.
            </Typography>
          </Box>

          <Divider />

          {/* Okay button */}
          <Box sx={{ textAlign: 'center', padding: '20px' }}> 
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleClose}
              sx={{
                textTransform: 'none', 
                fontWeight: 'bold', 
                padding: '12px 24px', 
                fontSize: '16px',  
                backgroundColor: '#007bff',
                '&:hover': {
                  backgroundColor: '#0056b3',
                }
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SenderIDModal;
