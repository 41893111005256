import React, { useState, useRef } from 'react';
import { Box, styled, Tabs, Tab } from '@mui/material';
import TabPanel from '../layouts/tabPanel/TabPanel';
import EmailLogin from '../layouts/forms/emailLogin/EmailLogin';
import PhoneNoLogin from '../layouts/forms/phoneNoLogin/PhoneNoLogin';
import Recaptcha from 'react-google-invisible-recaptcha';
import { env } from '../app/features/env';
// import { getAuthSelector } from '../utils/selectors/selectors';

const TabsWrapper = styled(Tabs)(
   ({ theme }) => `
    // border: 2px solid black;
    width: 100%;
        @media (max-width: ${theme.breakpoints.values.md}px) {
          .MuiTabs-scrollableX {
            overflow-x: auto !important;
          }
          .MuiTabs-indicator {
              box-shadow: none;
          }
        }
        `
);

const LoginTab = () => {
   const a11yProps = (index) => {
      return {
         id: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`,
      };
   };
   const recaptchaRef = useRef(null);
   const [value, setValue] = useState(0);
   const handleChange = (event, newValue) => {
      setValue(newValue);
   };
   return (
      <div>
         <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            pb={1}
            m={3}
            mt={4}
         >
            <TabsWrapper
               value={value}
               onChange={handleChange}
               aria-label="scrollable auto tabs example"
            >
               <Tab label="Email Address" {...a11yProps(0)} />
               <Tab label="Phone Number" {...a11yProps(1)} />
            </TabsWrapper>
         </Box>
         <TabPanel value={value} index={0}>
            <EmailLogin recaptchaRef={recaptchaRef} />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <PhoneNoLogin recaptchaRef={recaptchaRef} />
         </TabPanel>
         <Recaptcha
            ref={recaptchaRef}
            sitekey={env.REACT_APP_RECAPTCHA_SITE_KEY}
         />
      </div>
   );
};

export default LoginTab;
