import styled from '@emotion/styled';
import { Box, TextField, Button } from '@mui/material';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #EFEEF4;
 `
);
export const TextInput = styled(TextField)(
   ({ theme }) => ` 
 background: inherit;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: inherit;
color: #374DA0;
font-weight: 600;
width: 47%;
text-align: center;
font-size: clamp(0.9rem, 1.5vw, 1rem);
position: relative;
border-radius:15px;
@media (max-width:475px ){
	width: 100%;
}
`
);
export const AddImage = styled.img(
   ({ theme }) => `
							position: absolute;
							z-index: 4;
							right: 20px;
							bottom: 5vh;
							width: 80px;
							cursor: pointer;

`
);
