export const getBaseUrls = () => {
   if (
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('dev')
   ) {
      return {
         auth: 'https://2cqs4a4sihklw2ti6ysrusa7wa0nztzv.lambda-url.eu-west-1.on.aws',
         data: 'https://yu6mwcifjkdlwduqgvo22wqzti0dugax.lambda-url.eu-west-1.on.aws',
         external: 'https://nx2ovuzhkoqffvmhbjcx6cfkea0ohtrt.lambda-url.eu-west-1.on.aws',
         app: 'https://app-dev.parrotica.com',
      };
   } else {
      return {
         auth: 'https://2cqs4a4sihklw2ti6ysrusa7wa0nztzv.lambda-url.eu-west-1.on.aws',
         data: 'https://yu6mwcifjkdlwduqgvo22wqzti0dugax.lambda-url.eu-west-1.on.aws',
         external: 'https://nx2ovuzhkoqffvmhbjcx6cfkea0ohtrt.lambda-url.eu-west-1.on.aws',
         app: 'https://app.parrotica.com',
      };
   }
};

export const userLSAuth = () => {
   if (localStorage.getItem('userAuth')) {
      const authData = localStorage.getItem('userAuth');
      const userAuth = authData == 'undefined' ? {} : JSON.parse(authData);
      return userAuth;
   } else {
      return null;
   }
};

export const env = {
   REACT_APP_RECAPTCHA_SITE_KEY: '6LcB5k8qAAAAAPu7WruxHKoXYan90lnOEM6_wUij',
   REACT_APP_RECAPTCHA_SECRET_KEY: '6LcB5k8qAAAAALnsWW18WBIlCmL5qT9EWJ3RjpRS',
 };
 