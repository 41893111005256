import { Card, Box, Typography, Button } from '@mui/material';
import successImg from '../../../assets/VerificationBadge.svg';
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
// import { messageSelector } from '../../../components/utils/selectors/selectors';
import { resetCreated } from '../../../components/app/features/message/messageSlice';
import { useNavigate } from 'react-router-dom';

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 50%;
text-align: center;
font-size: 0.85rem;
border-radius:5px;
margin: 0 auto;
margin-top: 3rem;
&:hover {background: #374DA0;}
`
);

const Success = (props) => {
   // const selector = useSelector(messageSelector);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { msgType = 'Message Sent!' } = props;
   return (
      <Card
         sx={{
            width: '500px',
            padding: '2rem 0',
            display: 'flex',
            flexDirection: 'column',
         }}
      >
         <Box
            sx={{
               margin: 'auto',
               width: '150px',
               marginTop: '5rem',
               marginBottom: '2rem',
            }}
         >
            <img src={successImg} alt="success" width={'100%'} />
         </Box>
         <Typography
            sx={{
               textAlign: 'center',
               fontSize: 'clamp(1rem, 2vw, 1.2rem)',
               fontWeight: '600',
            }}
         >
            Successful
         </Typography>
         <Typography
            sx={{
               textAlign: 'center',
               marginTop: '1rem',
               fontWeight: '600',
               fontSize: '1rem',
            }}
         >
            {msgType}
         </Typography>
         <FlatButton
            onClick={() => {
               dispatch(resetCreated());
               if (msgType === 'Message Scheduled Successfully!') {
                  navigate('/send-message/schedule');
               } else if (msgType === 'Message Sent!') {
                  navigate('/send-message/history');
               }
            }}
         >
            Okay
         </FlatButton>
         {/* <Button
            variant="outlined"
            sx={{
               fontWeight: 600,
               width: '50%',
               textAlign: 'center',
               fontSize: '0.85rem',
               borderRadius: '5px',
               margin: '0 auto',
               marginTop: '1rem',
            }}
         >
            View Delivery Report
         </Button> */}
      </Card>
   );
};

export default Success;
