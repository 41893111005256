import { Card, Box, Typography, Button } from '@mui/material';
import successImg from '../../assets/VerificationBadge.svg';
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../components/utils/selectors/selectors';
import { falsifyCreated } from '../../components/app/features/message/messageSlice';

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 50%;
text-align: center;
font-size: 0.85rem;
border-radius:5px;
margin: 0 auto;
margin-top: 3rem;

&:hover {background: #374DA0;}
`
);

const Success = () => {
   const selector = useSelector(messageSelector);
   const dispatch = useDispatch();
   return (
      <Card
         sx={{
            width: '500px',
            padding: '2rem 0',
            display: 'flex',
            flexDirection: 'column',
         }}
      >
         <Box
            sx={{
               margin: 'auto',
               width: '150px',
               marginTop: '5rem',
               marginBottom: '2rem',
            }}
         >
            <img src={successImg} alt="success" width={'100%'} />
         </Box>
         <Typography
            sx={{
               textAlign: 'center',
               fontSize: 'clamp(1rem, 2vw, 1.2rem)',
               fontWeight: '700',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
            }}
         >
            Successful
         </Typography>
         <Typography
            sx={{
               textAlign: 'center',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
               marginTop: '1rem',
               fontWeight: '700',
               fontSize: '1rem',
            }}
         >
            Feature Sent!
         </Typography>
         <FlatButton
            onClick={() => {
               dispatch(falsifyCreated());
               window.history.back();
            }}
         >
            Okay
         </FlatButton>
      </Card>
   );
};

export default Success;
