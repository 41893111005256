import React from 'react';
import {
   Box,
   Typography,
   // Container,
   // Badge,
   // Tooltip,
   // useTheme,
} from '@mui/material';
import DashboardCustomizeSharpIcon from '@mui/icons-material/DashboardCustomizeSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import PhoneBook from '../../assets/Phonebook.svg';
import Message from '../../assets/Message.svg';
import { useLocation, Link } from 'react-router-dom';

const boxSX = {
   py: '12px',
   pl: '22px',
   mt: '17px',
   color: '#ffffff',
   cursor: 'pointer',
   borderLeft: '4px solid #7A88C0',
   '&:hover': {
      color: '#ffffff',
      backgroundColor: '#2E4085',
      borderLeft: '5px solid white',
   },
};

const activeLink = {
   py: '12px',
   pl: '22px',
   mt: '17px',
   cursor: 'pointer',
   color: '#ffffff',
   backgroundColor: '#2E4085',
   borderLeft: '4px solid white',
};

const DashBoardLink = ({ isOpen }) => {
   // const theme = useTheme();
   const location = useLocation();

   return (
      <Box>
         <Link
            to="/dashboard"
            style={{
               textDecoration: 'none',
            }}
         >
            <Box
               display="flex"
               textAlign="center"
               alignItems="center"
               gap={1}
               sx={location.pathname == '/dashboard' ? activeLink : boxSX}
            >
               <DashboardCustomizeSharpIcon
                  sx={{
                     fontSize: '40px',
                     marginLeft: '-7px',
                  }}
               />
               <Typography variant="h5" fontSize="16px" fontWeight={500}>
                  Dashboard
               </Typography>
            </Box>
         </Link>
         <Link
            to="/send-message"
            style={{
               textDecoration: 'none',
            }}
         >
            <Box
               display="flex"
               textAlign="center"
               alignItems="center"
               gap={1.3}
               sx={
                  location.pathname.includes('send-message')
                     ? activeLink
                     : boxSX
               }
            >
               <img
                  src={Message}
                  alt="message"
                  style={{
                     marginLeft: '-5px',
                  }}
               />
               <Typography variant="h5" fontSize="16px" fontWeight={500}>
                  Send Message
               </Typography>
            </Box>
         </Link>
         <Link
            to="/top-up"
            style={{
               textDecoration: 'none',
            }}
         >
            <Box
               display="flex"
               textAlign="center"
               alignItems="center"
               gap={1}
               sx={location.pathname.includes('top-up') ? activeLink : boxSX}
            >
               <AddCircleSharpIcon
                  sx={{
                     fontSize: '40px',
                     ml: '-7px',
                  }}
               />
               <Typography variant="h5" fontSize="16px" fontWeight={500}>
                  Top-Up
               </Typography>
            </Box>
         </Link>
         <Link
            to="/contacts"
            style={{
               textDecoration: 'none',
            }}
         >
            <Box
               display="flex"
               textAlign="center"
               alignItems="center"
               gap={1.4}
               sx={location.pathname.includes('contacts') ? activeLink : boxSX}
            >
               <img src={PhoneBook} alt="Phone" />
               <Typography variant="h5" fontSize="16px" fontWeight={500}>
                  Contacts
               </Typography>
            </Box>
         </Link>
      </Box>
   );
};

export default DashBoardLink;
