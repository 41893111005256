import React from 'react';
import {
   Card,
   // DialogTitle,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
   Typography,
   Box,
   Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MessageContact = ({ groupsText, contactText, pasteText }) => {
   const [value, setValue] = React.useState('');
   const handleChange = (event) => {
      setValue(event.target.value);
   };
   const navigate = useNavigate();

   return (
      <Card
         sx={{
            width: '280px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
         }}
      >
         <Typography
            sx={{
               margin: '1rem 0 0.5rem 0',
               fontSize: '1rem',
               fontWeight: '700',
               fontFamily: "'Raleway', 'Montserrat', sans-serif",
            }}
         >
            Select Recipients from
         </Typography>
         <FormControl
            sx={{
               // border: '1px solid',
               marginBottom: '1rem ',
            }}
         >
            <RadioGroup
               aria-labelledby="demo-radio-buttons-group-label"
               defaultValue="value"
               name="radio-buttons-group"
               value={value}
               onChange={handleChange}
            >
               <FormControlLabel
                  value="Groups"
                  control={<Radio />}
                  label="Groups"
               />
               <FormControlLabel
                  value="Contacts"
                  control={<Radio />}
                  label="Contacts"
               />
               <div className="d-flex">
                  <FormControlLabel
                     value="Paste"
                     control={<Radio />}
                     label="Paste"
                  />

                  <Tooltip
                     title="Direct Paste contacts cannot classify numbers into DND and non-DND. Therefore, messages may not reach contacts on DND."
                     arrow
                     style={{ padding: '2px', margin: '-5px' }}
                  >
                     <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
               </div>
            </RadioGroup>
         </FormControl>
         <Box sx={{ width: '100%', paddingRight: '2rem' }}>
            <Typography
               sx={{
                  marginBottom: '1rem',
                  textAlign: 'right',
                  fontSize: '1rem',
                  color: '#374DA0',
                  fontWeight: '600',
                  cursor: 'pointer',
               }}
               onClick={() => {
                  if (value === 'Groups') {
                     navigate(groupsText);
                  } else if (value === 'Contacts') {
                     navigate(contactText);
                  } else if (value === 'Paste') {
                     navigate(pasteText);
                  }
               }}
            >
               Continue
            </Typography>
         </Box>
      </Card>
   );
};

export default MessageContact;
