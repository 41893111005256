import React, { useState } from 'react';
import { getBaseUrls, userLSAuth } from '../../components/app/features/env';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   TextField,
   Button,
   IconButton,
   Typography,
   Box,
   CircularProgress,
   Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Sendicon from '../../assets/send.png';
import success from '../../assets/success.png';

const TransferDialog = ({ open, onClose }) => {
   const [recipientName, setRecipientName] = useState('');
   const [loading, setLoading] = useState(false);
   const [fetchError, setFetchError] = useState('');
   const [transferLoading, setTransferLoading] = useState(false);
   const [successOpen, setSuccessOpen] = useState(false);
   const [transferredUnits, setTransferredUnits] = useState(0);
   const [updatedBalance, setUpdatedBalance] = useState(0);
   const [recipientEmail, setRecipientEmail] = useState('');
   const [errormessage, seterrormessage] = React.useState('');
   
   // Validation schema for Formik
   const validationSchema = Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      units: Yup.number()
         .min(1, 'Units must be at least 1')
         .required('Required'),
   });

   const fetchRecipientName = async (email) => {
      try {
         setLoading(true);
         setFetchError('');
         const token = localStorage.getItem('accessToken');
         const response = await fetch(
            `${getBaseUrls().external}/api/v1/misc/confirm-user/`,
            {
               method: 'POST',
               headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({ email }),
            }
         );

         if (!response.ok) {
            throw new Error('Recipient not found');
         }

         const data = await response.json();
         setRecipientName(data?.data?.fullName || '');
      } catch (error) {
         setFetchError('No user found with this email');
         setRecipientName('');
      } finally {
         setLoading(false);
      }
   };

   // Handle form submission for transfer
   const handleTransfer = async (values) => {
      const token = localStorage.getItem('accessToken');
      setTransferLoading(true);
      try {
         const response = await fetch(
            `${getBaseUrls().external}/api/v1/misc/transfer-unit/`,
            {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
               },
               body: JSON.stringify({
                  userId: userLSAuth().id,
                  receiptientEmail: values.email,
                  units: values.units,
               }),
            }
         );

         if (!response.ok) {
            // Extract the response error message
            const errorData = await response.json();
            throw new Error(
               `${errorData.message || 'Transfer failed'} (Code: ${
                  errorData.code || response.status
               })`
            );
         }

         const data = await response.json();

         // Update state with values from the API response
         setTransferredUnits(data.data.unitTransferred);
         setUpdatedBalance(data.data.balanceAfterTransfer);
         setRecipientEmail(values.email);
         setSuccessOpen(true);
         onClose();
      } catch (error) {
         // Log detailed error information
         seterrormessage(`Transfer failed:${error.message}`);
         console.error('Transfer failed:', error.message);
      } finally {
         setTransferLoading(false);
      }
   };

   const [openn, setOpenn] = useState(true);

   return (
      <>
         <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle
               sx={{
                  fontSize: '1.5rem',
                  fontWeight: ' Semi Bold',
                  paddingBottom: '8px',
                  marginBottom: '16px',
                  color: '#333333',
                  borderBottom: '1px solid #E3E3E3',
               }}
            >
               Transfer SMS Units
               <IconButton
                  aria-label="close"
                  onClick={() => setOpenn(false)}
                  sx={{
                     position: 'absolute',
                     right: 8,
                     top: 8,
                     color: (theme) => theme.palette.grey[500],
                  }}
               >
                  <Button
                     onClick={onClose}
                     variant="text"
                     style={{ all: 'unset', cursor: 'pointer' }}
                  >
                     <CloseIcon />
                  </Button>
               </IconButton>
               <Typography
                  sx={{
                     fontWeight: 'normal',
                     color: '#333333CC',
                     fontSize: '1rem',
                  }}
                  variant="subtitle1"
                  color="textSecondary"
               >
                  Your balance: {userLSAuth()?.balance ?? '0'}
               </Typography>
            </DialogTitle>
            <DialogContent>
               <Formik
                  initialValues={{ email: '', units: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleTransfer}
               >
                  {({ errors, touched, values, handleChange }) => (
                     <Form>
                        <Box mb={2}>
                           <Typography
                              sx={{
                                 color: '#333333',
                                 fontWeight: 'Medium',
                                 fontSize: '1rem',
                              }}
                              marginBottom={1}
                              variant="body2"
                           >
                              Recipient's Email
                           </Typography>
                           <Field
                              name="email"
                              as={TextField}
                              label=""
                              variant="outlined"
                              fullWidth
                              error={touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              onChange={(e) => {
                                 handleChange(e);
                                 fetchRecipientName(e.target.value);
                              }}
                           />
                        </Box>
                        <Box mb={2}>
                           <Typography
                              sx={{
                                 color: '#333333',
                                 fontWeight: 'Medium',
                                 fontSize: '1rem',
                              }}
                              variant="body2"
                           >
                              Recipient's Name
                           </Typography>

                           <TextField
                              label=""
                              variant="outlined"
                              fullWidth
                              value={recipientName} // Only set recipientName as the value
                              InputProps={{
                                 readOnly: true,
                                 sx: {
                                    '& .MuiOutlinedInput-root': {
                                       borderRadius: 0, // Ensures the input container has no rounded corners
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                       borderTop: 'none',
                                       borderLeft: 'none',
                                       borderRight: 'none',
                                       borderRadius: 0, // Removes rounding on the outline
                                    },
                                 },
                              }}
                              error={!!fetchError}
                              helperText={
                                 loading ? (
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                       }}
                                    >
                                       <CircularProgress
                                          size={20}
                                          sx={{
                                             color: (theme) =>
                                                theme.palette.primary.main,
                                          }}
                                       />
                                    </Box>
                                 ) : (
                                    fetchError
                                 )
                              }
                           />
                        </Box>
                        <Box mb={2}>
                           <Typography
                              mb={1}
                              color="#333333"
                              variant="body2"
                              sx={{ fontWeight: 500, fontSize: '1rem' }}
                           >
                              Amount of units to transfer
                           </Typography>

                           <Field
                              name="units"
                              as={TextField}
                              label=""
                              type="number"
                              variant="outlined"
                              fullWidth
                              inputProps={{ min: 1 }}
                              error={touched.units && !!errors.units}
                              helperText={touched.units && errors.units}
                           />
                        </Box>
                        {errormessage && (
                           <Typography
                              sx={{
                                 color: 'red',
                              }}
                           >
                              {errormessage}
                           </Typography>
                        )}
                        <DialogActions>
                           <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              endIcon={
                                 transferLoading ? (
                                    <CircularProgress size={20} />
                                 ) : (
                                    <img
                                       src={Sendicon}
                                       alt="Send Icon"
                                       style={{ width: 20, height: 20 }}
                                    />
                                 )
                              }
                              fullWidth
                              disabled={
                                 loading ||
                                 transferLoading ||
                                 !recipientName ||
                                 !!errors.units
                              }
                              sx={{
                                 fontWeight: 700, // Bold font weight
                              }}
                           >
                              {transferLoading
                                 ? 'Transferring...'
                                 : 'Make Transfer'}
                           </Button>
                        </DialogActions>
                     </Form>
                  )}
               </Formik>
            </DialogContent>
         </Dialog>

         {/* Success Dialog */}
         <Dialog
            open={successOpen}
            onClose={() => setSuccessOpen(false)}
            fullWidth
            maxWidth="xs"
            
         >
           <DialogTitle sx={{padding:'1.5rem'}}>
  <Box
    display="flex"
    alignItems="center"
    gap={1} // Add some space between the image and the text
  >
    <img
      src={success}
      alt="Success Icon"
      style={{ width: '35px', height: '35px' }} // Adjust size as needed
    />
    <Typography
      component="span"
      sx={{
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#333333',
      }}
    >
      Success!
    </Typography>
  </Box>
  <IconButton
    aria-label="close"
    onClick={() => setSuccessOpen(false)}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>

            <DialogContent sx={{padding:'1.5rem'}}>
               <Typography  sx={{
        fontSize: '1rem',
        fontWeight: 500,
        color: '#333333',
        marginTop: 1.5,
      }}>
                  You have successfully transferred {transferredUnits} SMS units
                  to {recipientEmail}.
               </Typography>
               <Typography  sx={{
        fontSize: '1rem',
        fontWeight: 500,
        color: '#333333',
        
      }}>
                  Your updated balance is {updatedBalance}.
               </Typography>
            </DialogContent>
            <DialogActions sx={{marginX:'1.5rem',marginBottom:'0.5rem'}}>
               <Button
                  onClick={() => setSuccessOpen(false)}
                  color="primary"
                  variant="contained"
               >
                  Ok
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
};

export default TransferDialog;
