import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import star from '../../assets/star.svg';
// import cost from '../../assets/cost.svg';
import groupImg from '../../assets/group.svg';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { contactSelector } from '../utils/selectors/selectors';

const ViewButton = styled(Button)(
   ({ theme }) => `
background: inherit;
padding: 0;
width: 100%;
text-align: center;
font-size: 1rem;
font-weight: 600;
&:hover{
 background: inherit;
}
`
);

const ViewGroupinfo = () => {
   const selector = useSelector(contactSelector);

   const navigate = useNavigate();
   return (
      <Box
         sx={{
            width: '100%',
            marginTop: '2rem',
         }}
      >
         <Box
            sx={{
               backgroundColor: '#fff',
               borderTopLeftRadius: '16px',
               borderTopRightRadius: '16px',
               padding: '25px 15px ',
               display: 'flex',
               flexDirection: 'column',
               rowGap: '15px',
            }}
         >
            <Typography
               sx={{
                  fontWeight: 600,
                  fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  color: '#0B0F20',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <img
                  src={star}
                  alt="star"
                  style={{ marginRight: '10px', width: '15px' }}
               />
               Favorite Groups
            </Typography>
            <Box
               sx={{
                  display: 'flex',
                  columnGap: '2%',
                  rowGap: '10px',
                  '@media (max-width:600px)': {
                     flexDirection: 'column',
                  },
               }}
            >
               {selector.summary.starredGroups &&
               selector.summary.starredGroups.length > 0 ? (
                  [...selector.summary.starredGroups.slice(0, 3)].map(
                     (group, index) => (
                        <Box
                           sx={{
                              width: '32%',
                              padding: '10px',
                              borderRadius: '8px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              backgroundColor: '#D7DBEC',
                              cursor: 'pointer',
                              ':hover': {
                                 outline: '1px solid #374da0',
                              },
                              '@media (max-width:600px)': {
                                 width: '100%',
                              },
                           }}
                           key={group.id}
                           onClick={() =>
                              navigate(`/contacts/group/${group.id}`)
                           }
                        >
                           <img
                              src={groupImg}
                              alt="group"
                              style={{ width: '25px' }}
                           />
                           <Typography
                              sx={{
                                 marginTop: '0.5rem',
                                 fontSize: 'clamp(0.85rem, 1.5vw, 0.9rem)',
                                 fontWeight: 600,
                                 textAlign: 'center',
                                 color: '#0B0F20',
                                 '@media (max-width:500px)': {
                                    fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                                 },
                              }}
                           >
                              {group.title.length > 20
                                 ? `${group.title.substring(0, 15)}...`
                                 : group.title}
                           </Typography>
                        </Box>
                     )
                  )
               ) : selector.summary.starredGroups &&
                 selector.summary.starredGroups.length === 0 ? (
                  <Typography
                     sx={{
                        fontSize: 'clamp(0.85rem, 1.5vw, 0.9rem)',
                        textAlign: 'center',
                        fontWeight: 600,
                        margin: '1rem auto',
                     }}
                  >
                     Your favourite groups will be displayed here
                  </Typography>
               ) : (
                  'loading...'
               )}
            </Box>
         </Box>
         <Box
            sx={{
               marginTop: '10px',
               display: 'flex',
               columnGap: '10px',
               '@media (max-width:450px)': {
                  flexDirection: 'column',
                  rowGap: '10px',
               },
            }}
         >
            <Box
               sx={{
                  width: '50%',
                  backgroundColor: '#fff',
                  borderBottomLeftRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  rowGap: '10px',
                  padding: '15px',
                  '@media (max-width:450px)': {
                     width: '100%',
                     borderBottomLeftRadius: '0',
                  },
               }}
               onClick={() => navigate('/contacts/all-contacts')}
            >
               <Typography
                  sx={{
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     fontWeight: 600,
                     color: '#374DA0',
                     textAlign: 'center',
                  }}
               >
                  {selector?.summary?.contactCount === 0
                     ? 0
                     : selector?.summary?.contactCount}
               </Typography>
               <Typography
                  sx={{
                     fontSize: '1rem',
                     fontWeight: 600,
                     color: '#0B0F20',
                     textAlign: 'center',
                  }}
               >
                  Contacts
               </Typography>
               <ViewButton>View Contacts</ViewButton>
            </Box>
            <Box
               sx={{
                  width: '50%',
                  backgroundColor: '#fff',
                  borderBottomRightRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  rowGap: '10px',
                  padding: '15px',
                  '@media (max-width:450px)': {
                     width: '100%',
                     borderBottomLeftRadius: '16px',
                  },
               }}
               onClick={() => navigate('/contacts/all-groups')}
            >
               <Typography
                  sx={{
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     fontWeight: 600,
                     color: '#374DA0',
                     textAlign: 'center',
                  }}
               >
                  {selector?.summary?.groupCount === 0
                     ? 0
                     : selector?.summary?.groupCount}
               </Typography>
               <Typography
                  sx={{
                     fontSize: '1rem',
                     fontWeight: 600,
                     color: '#0B0F20',
                     textAlign: 'center',
                  }}
               >
                  Groups
               </Typography>
               <ViewButton>View Groups</ViewButton>
            </Box>
         </Box>
      </Box>
   );
};

export default ViewGroupinfo;
