import React from 'react';
import ForgotPasswordTab from './ForgotPasswordTab';
import { Typography, Box } from '@mui/material';

const ForgotPassword = () => {
   return (
      <div>
         <Box
            fontFamily="'Raleway', 'Montserrat', sans-serif"
            fontWeight={600}
            className="pageHeader"
            mb={2}
         >
            Forgot Password?
         </Box>
         <Typography
            fontFamily="'Raleway', 'Montserrat', sans-serif"
            fontWeight={400}
            color="#02222d"
         >
            Enter your registered email or phone number and we’ll send you a
            link to verify your account.
         </Typography>
         <ForgotPasswordTab />
      </div>
   );
};

export default ForgotPassword;
