import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function TableLoading() {
	return (
		<Stack spacing={1}>
			<Skeleton variant='rectangular' width={180} height={30} />
			{/* <Skeleton variant='rounded' width={120} height={120} /> */}
		</Stack>
	);
}
