import React from 'react';
import { Box, Typography, Card, Tab } from '@mui/material';
import { Container, TabsWrapper } from './style';
import backImg from '../../../assets/back.svg';
import TabPanel from '../../../components/layouts/tabPanel/TabPanel';
import DND from './DND';
import NODND from './NODND';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { fetchContacts } from '../../../components/app/features/contacts/contactSlice';

const Classify = () => {
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);

   const a11yProps = (index) => {
      return {
         id: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`,
      };
   };

   const [page, setPage] = React.useState(1);
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
         setPage(page + 1);
      }
   };

   React.useEffect(() => {
      dispatch(fetchContacts({ page }));
   }, [page]);

   const dndData = [];
   const NonDndData = [];
   let NonDndCount = 0;
   let DndCount = 0;

   if (selector.totalContacts > 0) {
      selector?.contacts.filter((item, index) => {
         if (item.isDND === false) {
            NonDndCount += 1;
            NonDndData.push(item);
         } else {
            DndCount += 1;
            dndData.push(item);
         }
      });
   }
   //
   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <img
               src={backImg}
               alt="back"
               style={{
                  position: 'absolute',
                  width: '40px',
                  cursor: 'pointer',
               }}
               onClick={() => window.history.back()}
            />
            <Typography
               sx={{
                  width: 'max-content',
                  margin: '0 auto',
                  marginTop: '2rem',
                  fontWeight: 600,
                  fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                  color: '#0F0E0E',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
               }}
            >
               Contacts
            </Typography>
            <Typography
               sx={{
                  width: 'max-content',
                  margin: '0 auto',
                  fontWeight: 500,
                  fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                  color: '#0F0E0E',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif",
               }}
            >
               {value === 0 ? `Non-DND` : value === 1 ? `DND` : 0}
            </Typography>
            <Box
               display="flex"
               alignItems="center"
               flexDirection={{ xs: 'column', sm: 'row' }}
               justifyContent={{ xs: 'center', sm: 'space-between' }}
               pb={1}
               m={3}
               mt={4}
            >
               <TabsWrapper
                  value={value}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
               >
                  <Tab label="Non-DND" {...a11yProps(1)} />
                  <Tab label="DND" {...a11yProps(0)} />
               </TabsWrapper>
            </Box>
            <TabPanel value={value} index={0}>
               <NODND loadmore={loadmore} data={NonDndData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
               <DND loadmore={loadmore} data={dndData} />
            </TabPanel>
         </Card>
      </Container>
   );
};

export default Classify;
