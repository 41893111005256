import { Box, Typography } from '@mui/material';
import React from 'react';
import secondSlideImg from '../../../assets/secondSlideImg.svg';

const SecondSlide = () => {
	return (
		<Box>
			<Box sx={{ width: '100%', height: '210px' }}>
				<img
					src={secondSlideImg}
					style={{ width: '100%', height: '100%' }}
					alt='icon'
				/>
			</Box>
			<Typography
				sx={{ textAlign: 'center', color: '#0F0E0E', fontSize: '1rem' }}>
				Recipient numbers are categorised as Do Not Disturb (DND) and Non-Do Not
				Disturb (Non-DND).
			</Typography>
		</Box>
	);
};

export default SecondSlide;
