import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Checkbox,
   Button,
   CircularProgress,
} from '@mui/material';
import { Container, TextInput } from './style';
import { FaSearch,FaTimes } from 'react-icons/fa';
import backImg from '../../../assets/back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../utils/selectors/selectors';
import { fetchContacts } from '../../app/features/contacts/contactSlice';
import { addSelectedContacts } from '../../app/features/message/messageSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userLSAuth } from '../../app/features/env';
import noTransIcon from '../../../assets/noTrans.svg';

const ContactSelect = () => {
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();
   const [search, setSearch] = React.useState('');
   const [sorted, setSorted] = React.useState([]);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [page, setPage] = React.useState(1);
   const [loading, setLoading] = React.useState(false);

   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
         setPage(page + 1);
      }
   };
   // get contacts
   React.useEffect(() => {
      dispatch(
         fetchContacts({
           page,
           take: 50000,
         })
      );
   }, []);

   React.useEffect(() => {
      if (sorted.length === 0 || selector.contacts.length > 0) {
         setSorted(
            [...selector.contacts].sort(function (a, b) {
               if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
                  return -1;
               }
               if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );

         if (search != '') {
            setSorted(
               sorted.filter((item) => {
                  if (
                     item.fullName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                     item.number.includes(search)
                  ) {
                     return [item];
                  }
               })
            );
         }
      }
   }, [selector.contacts, search]);

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         sorted.map((item, id) => collection.push(item.id));
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const contactList = [];
   [...selector?.contacts].map((item) => {
      checkedList.map((list) => {
         if (item.id === list) {
            contactList.push(item);
         }
      });
   });

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               minHeight: '80vh',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  position: 'relative',
                  border: '1px solid transparent',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                     top: 0,
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '1rem auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                  }}
               >
                  Select Contacts
               </Typography>
               <Typography
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     fontWeight: 600,
                     position: 'relative',
                  }}
               >
                  <Checkbox
                  disabled={selector.loading == 'pending'}
                     checked={itemsChecked}
                     onClick={selectItem}
                     {...label}
                  />{' '}
                  Select All
               </Typography>
               <FormControl
                  sx={{ width: '100%', position: 'relative', margin: '1rem 0' }}
               >
                  <FaSearch
                     color="#374DA0"
                     style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '20px',
                        transform: 'translateY(-50%)',
                        fontSize: '0.9rem',
                     }}
                  />
                                     {search !== '' && (
                                            <FaTimes
   color="#374DA0"
   style={{
      zIndex: 2,
      position: 'absolute',
      top: '50%',
      right: '20px',
      transform: 'translateY(-50%)',
      fontSize: '0.9rem',
      cursor: 'pointer',
   }}
   onClick={() => setSearch('')}
/>  
)}
                  <TextInput
                     fullWidth
                     placeholder="search contacts"
                     InputProps={{
                        style: {
                           color: '#374DA0',
                           borderRadius: '25px',
                           paddingLeft: '35px',
                           fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           fontSize: '1rem',
                        },
                     }}
                     value={search}
                     onChange={(event) => handleSearch(event)}
                  />
               </FormControl>
               <Box
                  sx={{
                     marginTop: '2rem',
                     height: '42vh',
                     overflowY: 'auto',
                  }}
                  id="scrollableDiv"
               >
                  {/* <InfiniteScroll
                     dataLength={selector.contacts.length}
                     next={loadmore}
                     hasMore={selector.hasMore}
                     loader={
                        <h4 style={{ textAlign: 'center' }}>Loading...</h4>
                     }
                     scrollableTarget="scrollableDiv"
                     endMessage={
                        sorted.length > 0 && (
                           <p style={{ textAlign: 'center' }}>
                              Yay! You have seen it all
                           </p>
                        )
                     }
                  > */}
                     {selector.contacts.length > 0 ? (
                        sorted.map((item, index) => (
                           <Card
                              sx={{
                                 display: 'flex',
                                 paddingY: '10px',
                                 paddingLeft: '15px',
                                 marginBottom: '10px',
                                 cursor: 'pointer',
                                 '&:hover': {
                                    boxShadow:
                                       '0px 0px 1px 0px rgba(0,0,0,0.75)',
                                 },
                              }}
                              key={index}
                           >
                              <Checkbox
                                 value={item.id}
                                 checked={checkedList.includes(item.id)}
                                 onClick={handleCheckboxClick}
                                 {...label}
                              />
                              <Box sx={{ paddingLeft: '10px' }}>
                                 <Typography
                                    sx={{
                                       fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                       fontWeight: 600,
                                    }}
                                 >
                                    {item.fullName}
                                 </Typography>
                                 <Typography
                                    sx={{
                                       fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                       fontWeight: 600,
                                    }}
                                 >
                                    {item.number}
                                 </Typography>
                              </Box>
                           </Card>
                        ))
                     ) : (
                        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                           <Box>
                              <img src={noTransIcon} alt="icon" />
                           </Box>
                           <Typography
                              sx={{
                                 fontWeight: 600,
                                 fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                                 color: '#374da0',
                                 marginTop: '1rem',
                                 marginBottom: '1rem',
                                 // textAlign: 'center',
                              }}
                           >{`Hey ${
                              userLSAuth()?.fullName.split(' ')[0]
                           }  your contacts will appear here`}</Typography>
                        </Box>
                     )}
                     {search !== '' && sorted.length === 0 && (
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontSize: 'clamp(1rem, 2vw, 1.2rem)',
                              fontWeight: '600',
                              marginTop: '2rem',
                           }}
                        >
                           Oops No Search result!
                        </Typography>
                     )}
                  {/* </InfiniteScroll> */}
               </Box>
               <Button
                  variant="contained"
                  sx={{
                     width: '100%',
                     padding: '7px',
                     fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     margin: '2rem 0 1rem 0',
                  }}
                  onClick={() => {
                     setLoading(true);
                     dispatch(addSelectedContacts(contactList));
                     window.history.back();
                  }}
                  disabled={selector.loading == 'pending' || loading} 
                  startIcon={
                     selector.loading == 'pending' && (
                        <CircularProgress size="1rem" color="primary" />
                     )
                  }
               >
                  {selector.loading == 'pending' || loading ? 'Loading...' : 'Add contact'}
                 
               </Button>
            </Box>
         </Card>
      </Container>
   );
};

export default ContactSelect;
