import React, { useEffect, useState, forwardRef } from 'react';
import './AvatarSelection.css';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import VerifyBadge from '../../assets/VerificationBadge.svg';
import { getAvatarSelector } from '../../components/utils/selectors/selectors';
import { Box, Typography, Button, styled, Dialog, Slide } from '@mui/material';
import { resetLoading } from '../../components/app/features/auth/AuthSlice';
import {
   selectAvatar,
   UploadAvatar,
} from '../../components/app/features/auth/AuthSlice';
import { useNavigate } from 'react-router-dom';

const SuspendButton = styled(Button)(
   ({ theme }) => `
    border-radius: 10px;
    padding: 3px 10px;
    font-size: 0.8125rem;
    background: transparent;
    border: 1px solid #374DA0;
    width: 30%;
    margin: auto;
    color: #374DA0;
    &:hover {
        background: #F7F7F8;
    }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
   () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSelection = () => {
   const [file, setFile] = useState(null);
   const [avatarlayout, setAvatarLayout] = useState(true);
   const [addImage, setAddImage] = useState(false);
   const [allAvatar, setAllAvatar] = useState([]);
   const [success, setSuccess] = useState(false);
   const navigate = useNavigate();

   const dispatch = useDispatch();
   const selector = useSelector(getAvatarSelector);

   const avatar = selector;

   useEffect(() => {
      dispatch(selectAvatar());
      if (avatar?.avatar.length > 0) {
         setAllAvatar(avatar?.avatar);
      }
   }, [avatar?.avatar.length > 0]);

   useEffect(() => {
      if (avatar.avatarUploadeded === 'success') {
         setSuccess(true);
      }
      if (avatar.avatarUploadeded === 'false') {
         setSuccess(false);
      }
   }, [avatar.avatarUploadeded]);

   const chanagePageLayout = () => {
      setAvatarLayout(false);
      setAddImage(true);
   };

   // Handle upload avatar using formData
   const handleImageChange = (event) => {
      dispatch(resetLoading());
      const formData = new FormData();
      formData.append('photo', event.target.files[0]);
      setFile(URL.createObjectURL(event.target.files[0]));
      dispatch(UploadAvatar({ formData }));
   };

   // Add default photo
   // const handlePhotoChange = (url) => {
   // 	const formData = new FormData();
   // 	formData.append('defaultPhoto', url);

   // };

   const handleSubmitAvatar = () => {
      navigate('/dashboard');
   };

   const handleSkip = (url) => {
      const formData = new FormData();
      formData.append('defaultPhoto', url);
      dispatch(UploadAvatar({ formData }));
   };

   const handleAvatarSelect = (url) => {
      const formData = new FormData();
      formData.append('defaultPhoto', url);
      dispatch(UploadAvatar({ formData }));
   };

   return (
      <div>
         <Box margin="auto" width="10%" display="flex" gap={0.5} mt={2}>
            <Box className="dot1 "></Box>
            <Box className="dot1"></Box>
            <Box className="dot1"></Box>
         </Box>
         {avatarlayout && (
            <>
               <Box
                  fontFamily="'Raleway', 'Montserrat', sans-serif"
                  fontWeight={600}
                  className="pageHeader"
               >
                  Select your Avatar
               </Box>
               <Typography
                  fontFamily="'Raleway', 'Montserrat', sans-serif"
                  fontWeight={600}
                  color="#02222d"
               >
                  Choose your preferred avatar or add an image
               </Typography>

               <div className="avatarWrapper">
                  {allAvatar.map((item, index) => (
                     <Box key={item.id + index}>
                        <div
                           className="avatarImage"
                           onClick={(e) => handleAvatarSelect(item.Url)}
                        >
                           <img src={item.Url} className="avatarUrl" />
                        </div>
                     </Box>
                  ))}
               </div>
            </>
         )}
         {addImage && (
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
               p={5}
            >
               <Typography
                  align="center"
                  sx={{
                     py: 4,
                     px: 6,
                  }}
                  variant="h6"
               >
                  Select an image of your choice
               </Typography>
               <Box
                  width={150}
                  height={150}
                  borderRadius="50%"
                  className="imageWrapper"
               >
                  <img
                     src={file}
                     alt=""
                     width="100%"
                     height="100%"
                     style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                     }}
                  />
               </Box>
            </Box>
         )}
         <form>
            <Box
               display="flex"
               flexDirection="column"
               justifyContent="center"
               mt={4}
            >
               {!file && (
                  <>
                     <SuspendButton
                        sx={{
                           py: '7px',
                           display: 'flex',
                           columnGap: '15px',
                           alignItems: 'center',
                        }}
                     >
                        <AddIcon />
                        <label htmlFor="fileInput">
                           <input
                              type="file"
                              id="fileInput"
                              onChange={handleImageChange}
                              onClick={chanagePageLayout}
                              accept="image"
                              style={{ display: 'none' }}
                           />
                           <i className="fas fa-file-upload"></i>
                           Add image
                        </label>
                     </SuspendButton>
                  </>
               )}

               {addImage && (
                  <>
                     <Button
                        sx={{
                           borderRadius: '11px',
                           py: '3px',
                           px: '35px',
                           fontSize: '15px',
                           width: '55%',
                           mx: 'auto',
                           mt: '30px',
                        }}
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={handleSubmitAvatar}
                     >
                        Next
                     </Button>
                  </>
               )}
               <Typography
                  color="primary"
                  mt={2}
                  sx={{
                     cursor: 'pointer',
                  }}
                  onClick={() => handleSkip(allAvatar[0].Url)}
               >
                  Continue
               </Typography>
            </Box>
         </form>

         <DialogWrapper
            open={success}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setSuccess(false)}
         >
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
               p={5}
            >
               <Box
                  width={150}
                  height={150}
                  borderRadius="50%"
                  className="imageWrapper"
               >
                  <img
                     src={VerifyBadge}
                     alt=""
                     width="100%"
                     height="100%"
                     style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                     }}
                  />
               </Box>
               <Typography
                  align="center"
                  sx={{
                     py: 4,
                     px: 6,
                  }}
                  variant="h4"
               >
                  Account created successfully!
               </Typography>
               <Button
                  onClick={handleSubmitAvatar}
                  sx={{
                     borderRadius: '11px',
                     py: '7px',
                     px: '35px',
                     fontSize: '15px',
                     width: '55%',
                     mx: 'auto',
                     mt: '60px',
                     mb: '30px',
                  }}
                  variant="contained"
                  size="small"
                  type="submit"
               >
                  Go to Dashboard
               </Button>
            </Box>
         </DialogWrapper>
      </div>
   );
};

export default AvatarSelection;
