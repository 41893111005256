import React from 'react';
import { styled, Box, Typography } from '@mui/material';
import RecentPaginatedTable from '../firstPage/RecentPaginatedTable';
import { useDispatch, useSelector } from 'react-redux';
import { transaction } from '../../components/utils/selectors/selectors';
import { userLSAuth } from '../../components/app/features/env';
import noTransIcon from '../../assets/noTrans.svg';
import { getPaginatedActivities } from '../../components/app/features/transactions/TransactionSlice';

export const Wrapper = styled(Box)(
   ({ theme }) => `
background: #EFEEF4;  
min-height: 100vh;  
padding-top:${theme.header.height}; 
padding-bottom: 2rem;
`
);
export const Container = styled(Box)(
   ({ theme }) => `
 width: 95%;
max-width: 950px;
min-height: 100vh;
margin: 2rem auto 0 auto;


 `
);

const Activity = () => {
   const selector = useSelector(transaction);
   const dispatch = useDispatch();
   const [page, setPage] = React.useState(1);
   const [data, setData] = React.useState([]);

   React.useEffect(() => {
      dispatch(getPaginatedActivities({ page: page }));
   }, [page]);

   React.useEffect(() => {
      if (selector.paginatedActivities) setData(selector.paginatedActivities);
      // setData(filteredData);
   }, [selector.paginatedActivities]);

   const updatePage = (page) => setPage(page);

   return (
      <Wrapper>
         <Container>
            <Typography
               sx={{
                  // border: '1px solid red',
                  color: '#fff',
                  width: '90%',
                  maxWidth: '615px',
                  margin: '0 auto',
                  borderRadius: '15px',
                  minHeight: '105px',
                  background:
                     'linear-gradient(90deg, #374DA0 0%, rgba(55, 77, 160, 0.7) 71.67%)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.2rem',
                  fontWeight: '600',
                  wordSpacing: '0.5rem',
                  mb: 5,
               }}
            >
               {' '}
               Activity History
            </Typography>

            <Box>
               {selector.paginatedActivities.length > 0 ? (
                  <RecentPaginatedTable updatePage={updatePage} data={data} />
               ) : (
                  <Box sx={{ marginTop: '20vh' }}>
                     <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                        <img
                           src={noTransIcon}
                           style={{
                              width: '180px',
                           }}
                           alt="icon"
                        />
                     </Box>
                     <Typography
                        sx={{
                           fontWeight: 500,
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           color: '#0F0E0E',
                           marginTop: '1rem',
                           marginBottom: '1rem',
                           textAlign: 'center',
                        }}
                     >{`Hey ${
                        userLSAuth()?.fullName.split(' ')[0]
                     },  your Activity History will appear here`}</Typography>
                  </Box>
               )}
            </Box>
         </Container>
      </Wrapper>
   );
};

export default Activity;
