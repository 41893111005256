import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const PreferenceLink = ({ preferenceText, iconButton, handleClick }) => {
	return (
		<Box
			sx={{
				cursor: 'pointer',
			}}
			onClick={handleClick}>
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems='center'
				py={1}>
				<Box
					borderRadius={50}
					color='#374DA0'
					display='flex'
					textAlign='center'
					alignItems='center'
					justifyContent={'center'}
					width={38}
					height={38}
					bgcolor='rgba(55, 77, 160, 0.2)'>
					{iconButton}
				</Box>
				<Typography
					flex={'1'}
					ml={3}
					fontSize={16}
					fontFamily="'Raleway', 'Montserrat', sans-serif"
					fontWeight={500}
					color='#0B0F20'>
					{preferenceText}
				</Typography>
				<ArrowForwardIosSharpIcon />
			</Box>
		</Box>
	);
};

export default PreferenceLink;
