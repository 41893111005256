import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Checkbox,
   Button,
} from '@mui/material';
import { Container, TextInput } from './style';
import { FaSearch } from 'react-icons/fa';
import backImg from '../../../assets/back.svg';
import doc from '../../../assets/Doc.svg';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import {
   addMultipleUsersToGroup,
   fetchGroups,
} from '../../../components/app/features/contacts/contactSlice';

const SelectGroup = () => {
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();

   const [search, setSearch] = React.useState('');
   const [sorted, setSorted] = React.useState([]);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [user, setUser] = React.useState(selector.user || {});
   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   React.useEffect(() => {
      if (selector.user.length !== 0) {
         dispatch(fetchGroups({ userId: selector?.admin?.id }));
         setUser(selector.user);
      } else {
         window.history.back();
      }
   }, [selector.user.length]);

   React.useEffect(() => {
      if (selector.groups) {
         setSorted(
            [...selector?.groups].sort(function (a, b) {
               if (a.title.toLowerCase() < b.title.toLowerCase()) {
                  return -1;
               }
               if (a.title.toLowerCase() > b.title.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );
      }

      if (search != '') {
         setSorted(
            sorted.filter((item) => {
               if (item.title.toLowerCase().includes(search.toLowerCase())) {
                  return [item];
               }
            })
         );
      }
   }, [selector.groups, search]);

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         sorted.map((item, id) => collection.push(item.id));
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const groupList = [];
   [...selector?.groups].map((item) => {
      checkedList.map((list) => {
         if (item.id === list) {
            groupList.push(item);
         }
      });
   });

   const addContact = () => {
      const data = [
         {
            fullName: user.fullName,
            number: user.number,
         },
      ];
      checkedList.map((list) => {
         dispatch(addMultipleUsersToGroup({ groupId: list, contacts: data }));
      });
      window.history.back();
   };

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '2rem auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
               minHeight: '80vh',
               borderTopLeftRadius: '25px',
               borderTopRightRadius: '25px',
               borderBottomLeftRadius: 0,
               borderBottomRightRadius: 0,
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  position: 'relative',
                  border: '1px solid transparent',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                     top: 0,
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '1rem auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                  }}
               >
                  Select Groups
               </Typography>
               <Typography
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     fontWeight: 600,
                     position: 'relative',
                  }}
               >
                  <Checkbox
                     checked={itemsChecked}
                     onClick={selectItem}
                     {...label}
                  />{' '}
                  select all
               </Typography>
               <FormControl
                  sx={{ width: '100%', position: 'relative', margin: '1rem 0' }}
               >
                  <FaSearch
                     color="#374DA0"
                     style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '20px',
                        transform: 'translateY(-50%)',
                        fontSize: '0.9rem',
                     }}
                  />
                  <TextInput
                     fullWidth
                     placeholder="search groups"
                     InputProps={{
                        style: {
                           color: '#374DA0',
                           borderRadius: '25px',
                           paddingLeft: '35px',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        },
                     }}
                     value={search}
                     onChange={(event) => handleSearch(event)}
                  />
               </FormControl>
               <Box
                  sx={{
                     marginTop: '2rem',
                     height: '42vh',
                     overflowY: 'auto',
                  }}
               >
                  {selector?.groups.length > 0 &&
                     sorted.map((item) => (
                        <Card
                           key={item.id}
                           sx={{
                              padding: '20px',
                              paddingTop: '10px',
                              position: 'relative',
                              marginBottom: '10px',
                           }}
                        >
                           <Box sx={{ display: 'flex', marginBottom: ' 1rem' }}>
                              <Typography
                                 sx={{
                                    fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Checkbox
                                    sx={{ left: '-10px' }}
                                    value={item.id}
                                    checked={checkedList.includes(item.id)}
                                    onClick={handleCheckboxClick}
                                    {...label}
                                 />
                                 {item.title}
                              </Typography>
                           </Box>
                           <Typography
                              sx={{
                                 fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                 fontWeight: 600,
                              }}
                           >
                              {`	${
                                 item?.contacts ? item?.contacts.length : 0
                              } contacts`}
                           </Typography>
                           <Box sx={{ display: 'flex', marginTop: ' 1rem' }}>
                              <img
                                 src={doc}
                                 alt="group"
                                 style={{ marginRight: '15px' }}
                              />
                              <Typography
                                 sx={{
                                    fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                    fontWeight: 600,
                                 }}
                              >
                                 {item?.description}
                              </Typography>
                           </Box>
                        </Card>
                     ))}
                  {selector?.groups.length === 0 &&
                     selector.loading !== 'pending' && (
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontWeight: 600,
                              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           }}
                        >
                           No Groups To Select From
                        </Typography>
                     )}
               </Box>
               <Button
                  variant="contained"
                  sx={{
                     width: '100%',
                     padding: '7px 0',
                     fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                     margin: '2rem 0 1rem 0',
                  }}
                  onClick={() => {
                     addContact();
                  }}
               >
                  Add Contact to groups
               </Button>
            </Box>
         </Card>
      </Container>
   );
};

export default SelectGroup;
